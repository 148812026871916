import React from 'react'
import { ArchiveChatSvg, BlockChatSvg, ClearChatSvg, CopyLinkSvg, DeleteChatSvg, MuteChatSvg, PinToChatSvg, ReportChatSvg } from '../../../svg';

export default function ChatFunctions() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
      <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Chat Functions</h3>
      <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Chat/chat7.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
      <div className='mt-[10px]'>
        <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Engage your channel members by initiating discussions on relevant topics. Share updates, ask questions, or spark debates to foster interaction. Use the “New Post” button to create engaging content and encourage participation. Keep the dialogue flowing to build an active and vibrant community!</p>
        <div className='mt-[22px] flex flex-col gap-[22px]'>
          <div className='flex gap-[8px] items-start'>
            <PinToChatSvg size="24" />
            <p className='text-[#000000] text-[16px] font-semibold'><span className='text-[#00A4FF] font-bold'>Pin To chat</span> : Pin to Chat keeps important messages at the top for quick access.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <ArchiveChatSvg size="24" />
            <p className='text-[#000000] text-[16px] font-semibold'><span className='text-[#FFA800] font-bold'>Archive chat</span> : Archive Chat hides the chat from your main list without deleting it.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <MuteChatSvg size="24" />
            <p className='text-[#000000] text-[16px] font-semibold'><span className='text-[#FF3B30] font-bold'>Mute the chat</span> : Mute Chat silences notifications for the conversation.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <CopyLinkSvg size="24" />
            <p className='text-[#000000] text-[16px] font-semibold'><span className='text-[#6CBFFF] font-bold'>Copy Link</span> : Copy Link saves the message link to your clipboard.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <ClearChatSvg size="24" />
            <p className='text-[#000000] text-[16px] font-semibold'><span className='text-[#FFCC00] font-bold'>Clear chat</span> : Clear Chat deletes all messages in the conversation.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <BlockChatSvg size="24" />
            <p className='text-[#000000] text-[16px] font-semibold'><span className='text-[#00C7BE] font-bold'>Block this chat</span> : Block Chat prevents further messages or interactions from that conversation.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <ReportChatSvg size="24" />
            <p className='text-[#000000] text-[16px] font-semibold'><span className='text-[#7D7BFF] font-bold'>Report the chat</span> : Report Chat flags inappropriate content.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <DeleteChatSvg size="24" />
            <p className='text-[#000000] text-[16px] font-semibold'><span className='text-[#FF4949] font-bold'>Delete Message</span> : Delete Message removes the message permanently.</p>
          </div>
        </div>
      </div>
    </div>
  )
}
