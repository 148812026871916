import React from 'react'

export default function PendingInviteManagement() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Pending Invite Management</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/People/people5.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Pending invites in Workfast allow you to keep track of team members, guests, or customers who have been invited to join your workspace but haven't yet accepted the invitation. Effectively managing these pending invites ensures that your collaboration environment remains up-to-date and secure.</p>
            <div className='mt-[22px] flex flex-col gap-[15px]'>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>1</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Resend Invite to People to remind them again</p>
                </div>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>2</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Revoke Invite to People to delete the invite</p>
                </div>
            </div>
        </div>
    </div>
  )
}
