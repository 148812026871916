import React from 'react'

export default function PublicChannel() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Public Channel</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Channel/channel4.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>A public channel is open for everyone to join, view, and participate in. It's perfect for sharing knowledge, updates, or fostering community-wide discussions. Set your channel to "Public" in the settings to maximize visibility and engagement.</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Anyone in the organization can view the channel list and  join the group.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
