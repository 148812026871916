import React from 'react'

export default function AcceptedInviteDetails() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Accepted Invite Details</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/People/people6.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>When team members, guests, or customers accept their invites to join your Workfast workspace, their status changes to “Accepted.” View the invited date, joined date, and email ID for accepted invites.</p>
        </div>
    </div>
  )
}
