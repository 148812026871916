import React from 'react'

export default function CreateNewProject() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Create a New Project</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Project/project2.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>By clicking creating a new project. Go to the “New Project” option, Customize it to fit your needs. Add tasks, set milestones, and assign team members to get started quickly. Begin your project with ease and keep your team aligned and productive!</p>
            <div className='mt-[22px] flex flex-col gap-[15px]'>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>1</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Enter the Project Title</p>
                </div>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>2</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Enter Project Description</p>
                </div>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>3</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Enter Project Due Date</p>
                </div>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>4</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Click Next to create a project</p>
                </div>
            </div>
        </div>
    </div>
  )
}
