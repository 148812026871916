import React from 'react'

export default function StartConversations() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Start Conversations</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Channel/channel7.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Engage your channel members by initiating discussions on relevant topics. Share updates, ask questions, or spark debates to foster interaction. Use the “New Post” button to create engaging content and encourage participation. Keep the messages flowing to build an active and vibrant community!</p>
        </div>
    </div>
  )
}
