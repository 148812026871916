import React from 'react'

export default function CustomizeProjectAttributes() {
    return (
        <div className='flex flex-col gap-[11px] justify-center'>
            <h3 className='text-[#000000] text-[18px] font-bold'>Customize Project Attributes</h3>
            <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Project/project3.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
            <div className='mt-[6px]'>
                <p className='text-[#000000] text-[14px] font-medium'>Customizing its attributes. Add custom fields, labels, statuses, and more to align with your team's workflow. This flexibility helps you capture the right data and streamline your processes.</p>
                <div className='mt-[11px] flex flex-col gap-[7px]'>
                    <div className='flex gap-[4px] items-start'>
                        <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
                        <p className='text-[#000000] text-[14px] font-normal'>Select or add a project status such as "<span className='font-semibold'>Open</span>," "<span className='font-semibold'>In Progress</span>," or "<span className='font-semibold'>Completed</span>," or choose any custom status that suits your workflow, as this feature is fully customizable.</p>
                    </div>
                    <div className='flex gap-[4px] items-start'>
                        <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>2</div>
                        <p className='text-[#000000] text-[14px] font-normal'>Select or add tags such as "<span className='font-semibold'>Android</span>," "<span className='font-semibold'>iOS</span>," or any other custom label that fits your needs, as tags are fully customizable to better organize and categorize your projects.</p>
                    </div>
                    <div className='flex gap-[4px] items-start'>
                        <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>3</div>
                        <p className='text-[#000000] text-[14px] font-normal'>Select or add priority levels such as "<span className='font-semibold'>High</span>," "<span className='font-semibold'>Medium</span>," or "<span className='font-semibold'>Low</span>," or create custom priorities based on your needs, as this feature is fully customizable to help you prioritize tasks effectively.</p>
                    </div>
                    <div className='flex gap-[4px] items-start'>
                        <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>4</div>
                        <p className='text-[#000000] text-[14px] font-normal'>Click Next to proceed to next screen</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
