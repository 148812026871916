import React from 'react'
import { AssignTaskSvg, AttachmentSvg, CollaboratorSvg, CreateToTaskSvg, HistoryLogSvg, RecurringTaskSvg, SubTaskSvg, TagsSvg, TaskCommentsSvg } from '../../../svg';


export default function Task() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
      <h3 className='text-[#000000] text-[18px] font-bold'>Task</h3>
      <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Task/task7.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
      <div className='mt-[6px]'>
        <div className='flex gap-[8px] items-start'>
          <div className='flex shrink-0'><CreateToTaskSvg size="20" /></div>
          <p className='text-[#000000] text-[14px] font-medium'><span className='text-[#FACC15] font-bold'>Create a New Task:</span> Add tasks with descriptions, due dates, and priorities.</p>
        </div>
        <div className='flex gap-[8px] items-start mt-[11px]'>
          <div className='flex shrink-0'><AssignTaskSvg size="20" /></div>
          <p className='text-[#000000] text-[14px] font-medium'><span className='text-[#FDB700] font-bold'>Assign Task:</span> Delegate tasks to team members.</p>
        </div>
        <div className='flex gap-[8px] items-start mt-[11px]'>
          <div className='flex shrink-0'><CollaboratorSvg size="20" /></div>
          <p className='text-[#000000] text-[14px] font-medium'><span className='text-[#01B0EF] font-bold'>Collaborator:</span> Add a collaborator who can view and assist in task completion.</p>
        </div>
        <div className='flex gap-[8px] items-start mt-[11px]'>
          <div className='flex shrink-0'><AttachmentSvg size="20" /></div>
          <p className='text-[#000000] text-[14px] font-medium'><span className='text-[#34C759] font-bold'>Attachments:</span> Attach images, videos, documents, and PDFs to tasks.</p>
        </div>
        <div className='flex gap-[8px] items-start mt-[11px]'>
          <div className='flex shrink-0'><TagsSvg size="20" /></div>
          <p className='text-[#000000] text-[14px] font-medium'><span className='text-[#FF473D] font-bold'>Tags:</span> Organize tasks using tags like "Android," "iOS," "Web Module."</p>
        </div>
        <div className='flex gap-[8px] items-start mt-[11px]'>
          <div className='flex shrink-0'><RecurringTaskSvg size="20" /></div>
          <p className='text-[#000000] text-[14px] font-medium'><span className='text-[#B961FF] font-bold'>Recurring Tasks:</span> Schedule tasks to recur daily, weekly, or monthly.</p>
        </div>
        <div className='flex gap-[8px] items-start mt-[11px]'>
          <div className='flex shrink-0'><SubTaskSvg size="20" /></div>
          <p className='text-[#000000] text-[14px] font-medium'><span className='text-[#FFDD09] font-bold'> Sub-tasks:</span> Break down tasks into smaller sub-tasks with individual priorities, due dates, and assignees.</p>
        </div>
        <div className='flex gap-[8px] items-start mt-[11px]'>
          <div className='flex shrink-0'><TaskCommentsSvg size="20" /></div>
          <p className='text-[#000000] text-[14px] font-medium'><span className='text-[#32ADE6] font-bold'>Task Comments:</span> Comment on tasks and react to comments.</p>
        </div>
        <div className='flex gap-[8px] items-start mt-[11px]'>
          <div className='flex shrink-0'><HistoryLogSvg size="20" /></div>
          <p className='text-[#000000] text-[14px] font-medium'><span className='text-[#FF9500] font-bold'>History Log:</span> Track the activity log for tasks.</p>
        </div>
      </div>
    </div>
  )
}
