import React from 'react'

export default function BottomUpApproach () {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[18px] xl:gap-[22px]'>
            <h2 className='text-[#000000] text-[21px] md:text-[24px] lg:text-[30px] font-semibold relative z-[1]'>Bottom Up approach in Attaining Team Goals or Company Goals :</h2>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Companies have short or long term goals. Usually most existing project management tools have task scheduling and task tracking. on other hand projects are categorised into multiple executable tasks. once the project is complete, new project is taken. </p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Based on the tasks and project completion the long term goals are measured and accommodated. Workfast.ai give a revision from old school of thought in goal management. When Goals are set quarterly, half-early or annually, the are grouped into projects and projects are grouped into tasks.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>When new new projects are added it is accommodated. This helps company to easily estimate, “what should we do to attain the goal?“, “How many projects required to attain the goal?”, “Do we require new work force or can we manage with the present manpower?“ etc.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Hence Resource planning and mobilisation, Project Roadmap,Timeline and duration can be mapped easily. By virtue, the goals can be achieved only if Team is working in coordination as per planned.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>This will invariably enhances the productivity. Once each task is completed the automatic update of project is shown and its further updated in Goals. In reality Leaders work with goals. It is easy to view, analyse and track Tasks, Projects, Sub- Goals and Goals.</p>
        </div>
    )
}
