import React from 'react'
import DirectMessaging from './direct-messaging';
import ProfileViewing from './profile-viewing';
import GroupChat from './group-chat';
import EditGroupInfo from './edit-group-info';
import AddParticipants from './add-participants';
import ChangeGroupIcon from './change-group-icon';
import ChatFunctions from './chat-functions';
import SendMedia from './send-media';
import MessageReactions from './message-reactions';
import RecordVoiceMessages from './record-voice-messages';
import ReplyMessages from './reply-messages';

export default function Content() {

    return (
        <div className='flex flex-col gap-[16px] relative w-full px-[20px] mt-[12px]'>
            <div className='text-[#000000] text-[15px] font-normal'>
                <p>In Workfast.ai, the chat feature is designed to facilitate real-time communication between team members. It allows you to exchange messages, share updates, and quickly resolve queries. Chats can be organized by channels or direct messages, giving you the flexibility to communicate with a single person or with multiple members at once.</p>
            </div>
            <div id='direct-messaging'><DirectMessaging /></div>
            <div id='profile-viewing'><ProfileViewing /></div>
            <div id='group-chat'><GroupChat /></div>
            <div id='edit-group-info'><EditGroupInfo /></div>
            <div id='add-participants'><AddParticipants /></div>
            <div id='change-group-icon'><ChangeGroupIcon /></div>
            <div id='chat-functions'><ChatFunctions /></div>
            <div id='send-media'><SendMedia /></div>
            <div id='record-voice-messages'><RecordVoiceMessages /></div>
            <div id='message-reactions'><MessageReactions /></div>
            <div id='reply-messages'><ReplyMessages /></div>
        </div>
    )
}
