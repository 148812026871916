import React from 'react';
import dashboard_banner from "../../../../../images/home/dashboard-banner.webp";
import report_banner from "../../../../../images/home/report-banner.webp";
import channel_banner from "../../../../../images/home/chennal-banner.webp";
import project_banner from "../../../../../images/home/project-banner.webp";
import task_banner from "../../../../../images/home/task-banner.webp";
import alert_banner from "../../../../../images/home/alert-banner.webp";
import people_banner from "../../../../../images/home/people-banner.webp";
import chat_banner from "../../../../../images/home/chat-banner.webp";
import { AlertImgSvg, ChannelImgSvg, ChatImgSvg, DashboardImgSvg, PeopleImgSvg, ProjectImgSvg, ReportImgSvg, TaskImgSvg } from '../svg';

export default function Mobile() {
    const [iconsIndex, setIconIndex] = React.useState(4);

    const icons = [
        { id: 1, name: "Task", banner: task_banner },
        { id: 2, name: "Alert", banner: alert_banner },
        { id: 3, name: "People", banner: people_banner },
        { id: 4, name: "Chat", banner: chat_banner },
        { id: 5, name: "CXO Dashboard", banner: dashboard_banner },
        { id: 6, name: "Report", banner: report_banner },
        { id: 7, name: "Channel", banner: channel_banner },
        { id: 8, name: "Project", banner: project_banner },
    ];

    const totalCards = icons.length;

    const updateCarousel = (index) => {
        return [...Array(9)].map((_, i) => (index + i - 4 + totalCards) % totalCards);
    };

    const cardPositions = updateCarousel(iconsIndex);


    return (
        <div className='mt-[20px] flex flex-col justify-center items-center gap-[16px] relative z-[15] w-full px-[20px]  mx-auto overflow-hidden'>
            <div className='text-center'>
                <h3 className='text-[#180833] font-bold text-[32px]'>All-In-One platform for your team to Work Faster and Smarter</h3>
                <h4 className='mt-[16px] text-[#1D272C] text-[14px] font-normal'>Effective Team Communication, Efficient Task Management, Data Centric Report Generation</h4>
            </div>
            <div className='flex gap-[20px] items-center justify-center w-full overflow-hidden h-[90px]'>
                {cardPositions.map((pos, i) => (
                    <div
                        key={i}
                        className={`w-[96px] xl:w-[106px] shrink-0 flex flex-col gap-[8px] items-center justify-center transition-all duration-300 ${i === 4 ? "scale-[1.3]" : ` cursor-pointer`}`}
                        onClick={() => setIconIndex(pos)}
                    >
                        <div className='rounded-[12px] size-[40px]'>
                            {icons[pos]?.id === 1 ?
                                <TaskImgSvg size="40"/>
                                : icons[pos]?.id === 2 ?
                                    <AlertImgSvg size="40" />
                                    : icons[pos]?.id === 3 ?
                                        <PeopleImgSvg size="40"/>
                                        : icons[pos]?.id === 4 ?
                                            <ChatImgSvg size="40"/>
                                            : icons[pos]?.id === 5 ?
                                                <DashboardImgSvg size="40"/>
                                                : icons[pos]?.id === 6 ?
                                                    <ReportImgSvg size="40"/>
                                                    : icons[pos]?.id === 7 ?
                                                        <ChannelImgSvg size="40"/>
                                                        : icons[pos]?.id === 8 &&
                                                        <ProjectImgSvg size="40" />
                            }
                        </div>
                        <h6 className='text-[#7B73AE] text-[10px] font-semibold'>{icons[pos]?.name}</h6>
                    </div>
                ))}
            </div>
            <img
                src={icons[iconsIndex]?.banner}
                alt={icons[iconsIndex]?.name}
                loading='lazy'
                width={300}
                height={300}
            />
        </div>
    );
}
