import React from 'react'

export default function CustomizableAxis() {
    return (
        <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
            <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Customizable Axis</h3>
            <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Report/report3.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
            <div className='mt-[10px]'>
                <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Tailor your report visuals with customizable axes to display the most relevant data. Adjust the X and Y axes to highlight key metrics, trends, or comparisons that matter to your project. This feature helps you create meaningful charts that drive insights.</p>
                <div className='mt-[22px] flex flex-col gap-[15px]'>
                    <div className='flex gap-[8px] items-center'>
                        <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>1</div>
                        <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Select the Project</p>
                    </div>
                    <div className='flex gap-[8px] items-center'>
                        <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>2</div>
                        <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Choose your preferred chart style as <span className='font-semibold'>Bar, Donut or Radial</span></p>
                    </div>
                    <div className='flex gap-[8px] items-start'>
                        <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>3</div>
                        <div>
                            <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal mt-[2px]'>Choose your X axis as <span className='font-semibold'>Task Status or Assignee</span></p>
                            <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal mt-[8px]'>Choose your Y axis as <span className='font-semibold'>Task Count</span></p>
                        </div>
                    </div>
                    <div className='flex gap-[8px] items-center'>
                        <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>4</div>
                        <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Save to generate the Report</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
