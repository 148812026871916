import React from 'react'

export default function CustomizingYourReport() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Customizing Your Report</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Report/report1.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Tailor your reports to fit your specific needs with the “Report” feature. Choose the project or tasks. Create personalized reports to gain relevant insights and make strategic decisions. Start customizing your reports for maximum impact!</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold mt-[3px] shrink-0'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Project Report</p>
                </div>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold mt-[3px] shrink-0'>2</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Task - Todoist Report</p>
                </div>
            </div>
        </div>
    </div>
  )
}
