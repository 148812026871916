import React from 'react'

export default function ReplyChannel() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Reply to Channel</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Channel/channel12.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Engage the entire channel by replying to messages that are meant for everyone. Simply use the “Also send to” option to ensure your response is seen by all members. This feature helps keep everyone in the loop and fosters collective discussions.</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>By clicking the "Also send to" checkbox, the message will be sent to the channel as well</p>
                </div>
            </div>
        </div>
    </div>
  )
}
