import React from 'react'
import { FaPlus } from 'react-icons/fa6';

export default function CreateBlankProject() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Create a Blank Project</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Project/project1.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Start fresh by creating a blank project tailored to your unique needs. Click on “New Project,”  and build your project from the ground up. Add tasks, set deadlines, assign team members, and customize workflows to suit your goals. Begin with a blank slate and create a project that works for you!</p>
            <div className='mt-[22px] flex flex-col gap-[15px]'>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>1</div>
                    <div className='text-[#000000] text-[14px] lg:text-[16px] font-normal flex gap-[8px] items-center flex-wrap'><p>Click </p> <div className='bg-[#FFDD09] size-[25px] flex justify-center items-center rounded-[30px]'><FaPlus className='text-[10px] text-[#473D00]'/></div> <p> or Create a Project Button to create a project</p></div>
                </div>
            </div>
        </div>
    </div>
  )
}
