import React from 'react'
import { TbCircleCheckFilled, TbCircleMinus } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { NotionIconSvg, WorkfastSvg } from '../../../svg';



export default function Comparison() {
    const navigate = useNavigate();
    const list = [
        { title: 'Document collaboration with Access control', notion: 'YES', workfast: 'YES' },
        { title: 'Document Invites', notion: 'YES', workfast: 'YES' },
        { title: 'Document folder management', notion: 'NO', workfast: 'YES' },
        { title: 'Doc Hierarchy', notion: 'NO', workfast: 'YES' },
        { title: 'Task and Document integration', notion: 'NO', workfast: 'YES' },
        { title: 'Archive, Edit, Star, Pin docs', notion: 'YES', workfast: 'YES' },
        { title: 'Sticky Notes', notion: 'NO', workfast: 'YES' },
        { title: 'Comment within Documents', notion: 'YES', workfast: 'YES' },
        { title: 'Project, Task, Document Dashboard', notion: 'NO', workfast: 'YES' },
        { title: 'Share One task to multiple Task List', notion: 'NO', workfast: 'YES' },
        { title: 'Email within app', notion: 'NO', workfast: 'YES' },
        { title: '100+ tools integration', notion: 'NO', workfast: 'YES' },
        { title: 'Huge file storage in freemium', notion: 'NO', workfast: 'YES' },
        { title: 'Advanced automation', notion: 'NO', workfast: 'YES' },
        { title: 'Recurring Tasks', notion: 'NO', workfast: 'YES' },
        { title: 'Duplication of Documents and projects and tasks', notion: 'NO', workfast: 'YES' },
        { title: 'Remainder and tracking', notion: 'NO', workfast: 'YES' },
        { title: 'Reporting and Analytics', notion: 'NO', workfast: 'YES' },
        { title: 'Rich mobile experience as desktop', notion: 'NO', workfast: 'YES' },
        { title: 'Complete Task management', notion: 'NO', workfast: 'YES' },
        { title: 'Complete Project management', notion: 'NO', workfast: 'YES' },
        { title: 'Direct Messages and Group Chats', notion: 'NO', workfast: 'YES' },
        { title: 'Real time chat', notion: 'NO', workfast: 'YES' },
        { title: 'Public and Private Channel', notion: 'NO', workfast: 'YES' },
        { title: 'Task Tracing in 7+ views', notion: 'NO', workfast: 'YES' },
        { title: 'CRM', notion: 'NO', workfast: 'YES' },
        { title: 'Customise Workflow', notion: 'NO', workfast: 'YES' },
        { title: 'Enterprise Grade Privacy setting', notion: 'NO', workfast: 'YES' },
        { title: 'calendar and zoom integration', notion: 'NO', workfast: 'YES' },
        { title: 'Gannt chart', notion: 'NO', workfast: 'YES' },
        { title: 'kanban view', notion: 'NO', workfast: 'YES' },
        { title: 'Goals Tracking', notion: 'NO', workfast: 'YES' },
        { title: 'Employee performance analytics', notion: 'NO', workfast: 'YES' },
        { title: 'Embed links, Docs, Videos, Audios within Documents.', notion: 'YES', workfast: 'YES' },
        { title: 'Simple and elegant UI/UX', notion: 'NO', workfast: 'YES' },
    ]
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);
    return (
        <div className='flex flex-col justify-center items-center gap-[32px] w-11/12 sm:w-9/12 mx-auto'>
            <div className='flex flex-col justify-center items-center'>
                <h3 className='text-[#0A0800] text-[24px] sm:text-[30px] lg:text-[40px] xl:text-[52px] font-semibold text-center w-11/12 sm:w-auto'>Compare what Workfast.ai can provide </h3>
                <div className='text-[#0A0800] text-[24px] sm:text-[30px] lg:text-[40px] xl:text-[52px] font-semibold text-center flex flex-wrap gap-[8px] sm:gap-[12px] items-center justify-center w-11/12 sm:w-auto'>
                    <h3>More Than</h3>
                    <div className='w-[25px] sm:w-[35px] lg:w-[50px] xl:w-[55px]'>
                        <NotionIconSvg size={position >= 1440 ? "55" : position >= 1024 ? "50" : position >= 645 ? "35" : "25"} />
                    </div>
                    <h3>Notion</h3>
                </div>
            </div>
            <div className='w-full'>
                <div className='grid grid-cols-4 border-[1px] border-b-[#CECEEA] rounded-t-[6px] sm:rounded-t-[12px] w-full'>
                    <div className='col-span-2 px-[10px] py-[14px] sm:px-[18px] sm:py-[25px]'>
                        <div className='text-[#514B81] text-[12px] sm:text-[16px] font-bold'>Compare features</div>
                    </div>
                    <div className='border-l-[1px] border-[#CECEEA] p-[2px] sm:p-[5px]'>
                        <div className='bg-[#F002500D] w-full h-full flex flex-col sm:flex-row justify-center items-center sm:gap-[6px]'>
                        <div className='w-[15px] sm:w-auto'>
                                <NotionIconSvg size={position >= 645 ? "25" : "15"} />
                            </div>
                            <div className='text-[#24223E] text-[10px] sm:text-[14px] lg:text-[16px] font-bold'>Notion</div>
                        </div>
                    </div>
                    <div className='border-l-[1px] border-[#CECEEA] p-[2px] sm:p-[5px]'>
                        <div className='bg-[#4CB9390D] w-full h-full flex flex-col sm:flex-row  justify-center items-center sm:gap-[6px]'>
                            <div className='w-[15px] sm:w-auto'>
                                <WorkfastSvg size={position >= 645 ? "25" : "15"} />
                            </div>
                            <div className='text-[#24223E] text-[10px] ssm:text-[14px] lg:text-[16px] font-bold'>Workfast.ai</div>
                        </div>
                    </div>
                </div>
                {list?.map((each, index) => (
                    <div key={index} className={`grid grid-cols-4 border-b-[1px] border-x-[1px] border-[#CECEEA4D] w-full ${list.length - 1 === index ? "rounded-b-[12px]" : ""}`}>
                        <div className='col-span-2 p-[10px] sm:p-[20px]'>
                            <div className='text-[#24223E] font-semibold text-[10px] sm:text-[15px]'>{each.title}</div>
                        </div>
                        <div className='border-l-[1px] border-[#CECEEA4D] p-[2px] sm:p-[5px]'>
                            <div className={`flex justify-center items-center w-full h-full ${each.notion === "YES" ? "bg-[#4CB9390D]" : "bg-[#F002500D]"}`}>{each.notion === "YES" ? <TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#4CB939]' /> : <TbCircleMinus className='size-[14px] sm:size-[28px] text-[#F00250]' />}</div>
                        </div>
                        <div className='border-l-[1px] border-[#CECEEA4D] p-[2px] sm:p-[5px]'>
                            <div className={`flex justify-center items-center w-full h-full ${each.workfast === "YES" ? "bg-[#4CB9390D]" : "bg-[#F002500D]"}`}>{each.workfast === "YES" ? <TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#4CB939]' /> : <TbCircleMinus className='size-[14px] sm:size-[28px] text-[#F00250]' />}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='mt-[40px] sm:mt-[100px] flex flex-col justify-center items-center gap-[20px] sm:gap-[40px]'>
                <h3 className='text-[#24223E] text-[22px] sm:text-[30px] lg:text-[42px] font-semibold sm:w-10/12 text-center'>Do collective work, get collective result with Workfast.ai</h3>
                <button className='w-[170px] h-[40px] lg:w-[270px] lg:h-[64px] rounded-[11px] bg-gradient-to-br from-[#FDD01F] via-[#FDD320] to-[#F8861B] text-[#2A2200] text-[16px] lg:text-[21px] font-semibold cursor-pointer' onClick={() => { navigate('/book-for-demo') }}>Try it Free</button>
            </div>
        </div>
    )
}
