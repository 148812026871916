import React from 'react'
import bg from "../../../../images/bg.png";
import available_screen from "../../../../images/home/available-screen.webp";
import { AndroidSvg, IncreaseFocusSvg, IosSvg, ReductionTaskSvg, TeamProductivitySvg, WebSvg } from './svg';

export default function Availables() {
    const [position, setPosition] = React.useState(window.innerWidth);
    
    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    const handleButtonClick = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
        if (/android/i.test(userAgent)) {
          window.location.href = 'https://play.google.com/store/apps/details?id=com.workfast.ai.app';
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          window.location.href = 'https://apps.apple.com/in/app/workfast-team-productivity/id6504078096';
        } else {
          alert('Please open this link on your mobile device.');
        }
      };

    return (
        <div className='mt-[20px] sm:mt-[60px] px-[20px] sm:px-0 w-full mx-auto'>
            <div className='flex justify-center items-center flex-col gap-[20px] sm:gap-[60px] w-full'>
                <div className='text-center w-full'>
                    <div className='mt-[20px] sm:mt-[32px] bg-[#FFFDF4] border border-[#D4D2C6] w-full py-[40px] xl:py-[60px] flex flex-col sm:flex-row justify-center items-center gap-[40px] xl:gap-[90px] rounded-[11px] sm:rounded-0'>
                        <div className='text-center flex flex-col gap-[16px] lg:gap-[22px] items-center'>
                            <IncreaseFocusSvg size={position >= 1024 ? "100" : position >= 645 ?  "80" : "50"}/>
                            <div>
                                <h4 className='text-[#FDD01F] text-[24px] lg:text-[40px] font-bold'>10-15%</h4>
                                <p className='text-[#180833] text-[14px] lg:text-[20px] font-semibold'>Increased employee focus</p>
                            </div>
                        </div>
                        <div className='w-[1px] h-[140px] lg:h-[220px] bg-[#D4D2C6] hidden sm:block'></div>
                        <div className='text-center flex flex-col gap-[16px] lg:gap-[22px] items-center'>
                            <ReductionTaskSvg size={position >= 1024 ? "100" : position >= 645 ?  "80" : "50"}/>
                            <div>
                                <h4 className='text-[#FDD01F] text-[24px] lg:text-[40px] font-bold'>20-30%</h4>
                                <p className='text-[#180833] text-[14px] lg:text-[20px] font-semibold'>Faster Task Completion </p>
                            </div>
                        </div>
                        <div className='w-[1px] h-[140px] lg:h-[220px] bg-[#D4D2C6] hidden sm:block'></div>
                        <div className='text-center flex flex-col gap-[16px] lg:gap-[22px] items-center'>
                            <TeamProductivitySvg size={position >= 1024 ? "100" : position >= 645 ?  "80" : "50"}/>
                            <div>
                                <h4 className='text-[#FDD01F] text-[24px] lg:text-[40px] font-bold'>15-25%</h4>
                                <p className='text-[#180833] text-[14px] lg:text-[20px] font-semibold'>Improved Team Productivity </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-center w-full relative'>
                    <img src={bg} alt='workfast' loading='lazy' className='absolute z-[1] h-full' />
                    <div className='flex justify-center items-center gap-[20px] sm:gap-[40px] lg:gap-[58px] flex-col relative z-[10]'>
                        <div className='flex flex-col justify-center items-center gap-[20px] sm:gap-[26px] relative w-full'>
                            <div className='relative z-[5] w-full'>
                                <h3 className='text-[#180833] text-[24px] sm:text-[32px] lg:text-[40px] font-medium'>Stay connected, anytime, anywhere.</h3>
                                <h4 className='text-[#1D272C] text-[14px] sm:text-[18px] font-normal mt-[12px] lg:mt-[14px]'>Maintain productivity on the go-manage tasks and communicate with your team from anywhere.</h4>
                                <div className='mt-[26px] flex flex-col sm:flex-row gap-[20px] items-center justify-center'>
                                    <button className='py-[12px] px-[31px] bg-[#FDD01F] rounded-[8px] text-[#452700] text-[16px] font-semibold' onClick={() => {handleButtonClick()}}>Download Workfast.ai</button>
                                    <button className='py-[12px] px-[31px] border-[2px] border-[#FDD01F] rounded-[8px] text-[#452700] text-[16px] font-semibold' onClick={() => {window.location.href = 'https://app.workfast.ai/'}}>Open in Web Browser</button>
                                </div>
                            </div>
                            <div className='bg-[#FFF] border-[#E3E9EC] border rounded-[8px] px-[12px] sm:px-[40px] lg:px-[57px] py-[12px] flex flex-col lg:flex-row items-center justify-center gap-[20px] sm:gap-[30px] relative z-[5] lg:w-[760px]'>
                                <div>
                                    <h5 className='text-[#180833] text-[16px] sm:text-[20px] font-medium'>Also available on:</h5>
                                </div>
                                <div className='flex gap-[16px] sm:gap-[32px] items-center text-[#1D272C] text-[14px] sm:text-[18px] font-normal'>
                                    <div className='flex gap-[6px] items-center'><AndroidSvg /><h6>Android</h6></div>
                                    <div className='flex gap-[6px] items-center'><IosSvg /><h6>iOS</h6></div>
                                    <div className='flex gap-[6px] items-center'><WebSvg /><h6>Web</h6></div>
                                </div>
                            </div>
                        </div>
                        <div className='relative z-[10]'>
                            <img src={available_screen} alt='collaborate' loading='lazy' className='w-[80%] mx-auto'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
