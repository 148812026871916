import React from 'react'

export default function MobileFirst() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[18px] xl:gap-[22px]'>
            <h2 className='text-[#000000] text-[21px] md:text-[24px] lg:text-[30px] font-semibold relative z-[1] w-[120px] md:w-[140px] lg:w-[180px]'>Mobile first :</h2>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Whole lot of project management softwares comes in web view. In addition there is a mobile app as well. One pain point Workfast.ai Analysed was, “Despite having mobile view, it is not UI/UX friendly”.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Workfast.ai will give same user experience for mobile users similar to web users. But one thing everyone can question is, “anyways i am managing my work in laptops, it makes no difference for me if I have in mobile view”. I would say, Mobile first is developed for many reasons catering different hierarchy in an organisation.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'> <span className='font-semibold'>Firstly,</span> managers and above in an organisation are constantly on the move. It will be much easier to know whats happening inside the company or projects in realtime. This will be a true gift for them. </p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'> <span className='font-semibold'>Secondly,</span> work from home is on rise and many company encourage employees working remotely. Mobile view immensely help employees to manage work and personal time efficiently.  </p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'> <span className='font-semibold'>Finally,</span> freelancers who work for big clients can manage their work in mobile in an easy way possible. Workfast.ai has been designed and developed to give a rich mobile experience similar to web view. A right Mobile project management Top 7 ways Workfast.ai can Revolutionise your project management 2 tool will actually tend to increase productivity for anyone who is looking for novelty in work productivity.</p>
        </div>
    )
}
