import React from 'react'
import Settings from './settings';
import EditProfile from './edit-profile';


export default function Mobile() {
    return (
        <div className='flex flex-col gap-[16px] relative w-full px-[20px] mt-[12px]'>
            <div className='text-[#000000] text-[15px] font-normal'>
                <p>The "Settings" section is your personal space in Workfast. Here, you can view and update your basic information like name, email, and profile picture. Check Privacy Policy, Terms & Conditions, Help & Support and About Workfast. "My Profile" is designed to help you manage your experience in Workfast effortlessly.</p>
            </div>
            <div id='settings'><Settings /></div>
            <div id='edit-profile'><EditProfile /></div>
        </div>
    )
}

