import React from 'react'
import banner from "../../../../../images/confidential/competitors.svg"
import mobile_banner from "../../../../../images/confidential/mobile-competitor.svg"
import teams from "../../../../../images/confidential/teams.svg"
import { ClickUpSvg, MondaySvg } from '../svg'
import { AsanaIconSvg, JiraSvg, SlackIconSvg } from '../../../comparison/svg'

export default function Competitor() {
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);
    return (
        <div className='px-[16px] sm:px-[20px] mt-[120px] relative z-[1] w-full'>
            <div className='flex flex-col lg:flex-row justify-center sm:gap-[0] sm:justify-between items-center mx-auto sm:w-9/12 lg:w-auto xl:w-9/12  xl:max-h-[570px] rounded-[24px] sm:rounded-[36px] border border-[#CECACA] overflow-hidden relative'>
                <div className='p-[20px] sm:p-[44px] flex flex-col gap-[29px]'>
                    <div className='relative'>
                        <h3 className='text-[#180833] font-bold text-[24px] sm:text-[26px] lg:text-[40px] text-center sm:text-start'>Our Competitors</h3>
                    </div>
                    <div className='flex items-start sm:items-center justify-center sm:justify-normal gap-[12px]'>
                        <div className='flex shrink-0 w-[28px] sm:w-[50px]'>
                            <ClickUpSvg size={position >= 645 ? "50" : "28"} />
                        </div>
                        <p className='text-[#180833] text-[14px] sm:text-[22px] font-medium text-center sm:text-start'>ClickUp Valuation - $4 Billion</p>
                    </div>
                    <div className='flex items-start sm:items-center justify-center sm:justify-normal gap-[12px]'>
                        <div className='w-[28px] sm:w-[50px] flex shrink-0'>
                            <SlackIconSvg size={position >= 645 ? "50" : "28"} />
                        </div>
                        <p className='text-[#180833] text-[14px] sm:text-[22px] font-medium text-center sm:text-start'>Slack Valuation - $26.51 Billion</p>
                    </div>
                    <div className='flex items-start sm:items-center justify-center sm:justify-normal gap-[12px]'>
                        <div className='w-[28px] sm:w-[50px] flex shrink-0'>
                            <JiraSvg size={position >= 645 ? "50" : "28"} />
                        </div>
                        <p className='text-[#180833] text-[14px] sm:text-[22px] font-medium text-center sm:text-start'>Jira Valuation - $72.81 Billion</p>
                    </div>
                    <div className='flex items-start sm:items-center justify-center sm:justify-normal gap-[12px]'>
                        <div className='w-[28px] sm:w-[50px] flex shrink-0'>
                            <AsanaIconSvg size={position >= 645 ? "50" : "28"} />
                        </div>
                        <p className='text-[#180833] text-[14px] sm:text-[22px] font-medium text-center sm:text-start'>Asana Valuation - $3.49 Billion</p>
                    </div>
                    <div className='flex items-start sm:items-center justify-center sm:justify-normal gap-[12px]'>
                        <img src={teams} alt='teams' className='w-[28px] sm:w-[50px] flex shrink-0' loading='lazy'/>
                        <p className='text-[#180833] text-[14px] sm:text-[22px] font-medium text-center sm:text-start'>Teams Valuation - $80 Billion</p>
                    </div>
                    <div className='flex items-start sm:items-center justify-center sm:justify-normal gap-[12px]'>
                        <div className='flex shrink-0 w-[28px] sm:w-[50px]'>
                            <MondaySvg size={position >= 645 ? "50" : "28"} />
                        </div>
                        <p className='text-[#180833] text-[14px] sm:text-[22px] font-medium text-center sm:text-start'>Monday.com Valuation - $14.46 Billion</p>
                    </div>
                </div>
                {position >= 1024
                    ?
                    <img src={banner} alt='banner' className='mt-0 w-[70%] xl:w-auto absolute -right-[130px]' loading='lazy' />
                    :
                    <img src={mobile_banner} alt='banner' className='relative -right-[50px] -bottom-[35px]' loading='lazy' />
                }
            </div>
        </div>
    )
}
