import React from 'react'

export default function SendMedia() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Send Media</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Chat/chat8.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Enhance your messages by sharing files and attachments directly in the chat. Click the “Attach” button to upload documents, images, or other files. This feature allows you to provide additional context and resources, making your discussions more informative and interactive.</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Share photos, videos, and documents within the chat</p>
                </div>
            </div>
        </div>
    </div>
  )
}
