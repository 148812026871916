import React from 'react'

export default function SetExpirationDate() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Set Expiration Date for Guest & Customer Invites</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/People/people4.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>In Workfast, you can set expiration dates for guest and customer invites to ensure that access is temporary and aligned with your project needs.</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold mt-[3px] shrink-0'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Define a time limit for the access granted to Guests and Customers.</p>
                </div>
                <div className='flex gap-[8px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0'>2</div>
                    <p className='text-[#000000] text-[16px] font-normal'>Select specific channels for your guest and customer users to limit their access to relevant areas within your workspace.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
