import React from 'react'

export default function AllTasks() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>All Tasks</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Task/task5.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Access a comprehensive list of all tasks assigned to you.</p>
        </div>
    </div>
  )
}
