import React from 'react'

export default function EditGroupInfo() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Edit Group Info</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Chat/chat4.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>To edit group info in Workfast, simply navigate to the group settings and update the group name, description, or other details. This ensures that your group's information stays current and relevant for all members.</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Edit the Group Name</p>
                </div>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Edit Group Description</p>
                </div>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Leave the Group</p>
                </div>
            </div>
        </div>
    </div>
  )
}
