import React from 'react'
import tieChennai from "../../../../images/home/tie-chennai.webp";
import oneDot from "../../../../images/home/one-dot.webp";
import vista from "../../../../images/home/vista.webp";
import adept from "../../../../images/home/adept.webp";
import naturals from "../../../../images/home/naturals.webp";
import rajalakshmi from "../../../../images/home/rajalakshmi-institute.webp";


export default function Trusted() {
  return (
    <div className='flex flex-col gap-[20px] sm:gap-[32px] items-center justify-center w-full mt-[20px] sm:mt-[60px] lg:mt-[90px] px-[20px] sm:px-0'>
        <p className='text-[#000000] text-[24px] sm:text-[32px] lg:text-[47px] font-bold text-center'>Our Trusted Customers</p>
        <div className='flex flex-col sm:flex-row flex-wrap gap-[43px] items-center justify-center'>
            <img src={tieChennai} alt='workfast' loading='lazy' className='w-[50%] sm:w-auto' width={300} height={300}/>
            <img src={oneDot} alt='workfast' loading='lazy' className='w-[50%] sm:w-auto' width={300} height={300}/>
            <img src={vista} alt='workfast' loading='lazy' className='w-[50%] sm:w-auto' width={300} height={300}/>
            <img src={adept} alt='workfast' loading='lazy' className='w-[50%] sm:w-auto' width={300} height={300}/>
            <img src={naturals} alt='workfast' loading='lazy' className='w-[50%] sm:w-auto' width={300} height={300}/>
            <img src={rajalakshmi} alt='workfast' loading='lazy' className='w-[50%] sm:w-auto' width={300} height={300}/>
        </div>
    </div>
  )
}
