import React from 'react'

export default function Settings() {
    return (
        <div className='flex flex-col gap-[11px] justify-center'>
            <h3 className='text-[#000000] text-[18px] font-bold'>Settings</h3>
            <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Profile/profile1.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
            <div className='mt-[6px]'>
                <p className='text-[#000000] text-[14px] font-medium'>Take control of your Workfast experience by exploring the “Settings” menu.</p>
                <div className='mt-[11px] flex flex-col gap-[7px]'>
                    <div className='flex gap-[4px] items-start'>
                        <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
                        <p className='text-[#000000] text-[14px] font-normal'>Click Settings Tab</p>
                    </div>
                    <div className='flex gap-[4px] items-start'>
                        <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>2</div>
                        <p className='text-[#000000] text-[14px] font-normal'>View Admin Dashboard, Privacy Policy, Terms & Conditions, Help & Support, About, Sign Out</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
