import React from 'react'

export default function GuestCustomerInvitations() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Guest & Customer Invitations</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/People/people3.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Inviting guests and customers to your workspace in Workfast allows you to collaborate with external stakeholders, such as clients, contractors, or partners, without compromising the privacy and security of your internal operations.</p>
            <div className='mt-[11px] flex flex-col'>
                <div className='text-[#000000] text-[14px] font-semibold'>Customer:</div>
                <p className='text-[#000000] text-[14px] font-normal'>A Customer in Workfast typically refers to a vendor or business partner engaged with your organization. Customers might require periodic updates or limited access to specific tasks, projects, or files relevant to their business relationship with your company.</p>
            </div>
            <div className='mt-[11px] flex flex-col'>
                <div className='text-[#000000] text-[14px] font-semibold'>Guest:</div>
                <p className='text-[#000000] text-[14px] font-normal'>A Guest refers to external collaborators such as clients, consultants, or others who need temporary or restricted access to your workspace. They are typically added for specific interactions or projects without full membership rights.</p>
            </div>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold mt-[3px] shrink-0'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Click this to assign a guest user to only post content within the invited channel in workspace</p>
                </div>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold mt-[3px] shrink-0'>2</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Click this to assign a guest user to only Post & Invite content within the invited channel in workspace</p>
                </div>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold mt-[3px] shrink-0'>3</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Select this for sending Copy Invite Link</p>
                </div>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold mt-[3px] shrink-0'>4</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Select this for Invite using email</p>
                </div>
            </div>
        </div>
    </div>
  )
}
