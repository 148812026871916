import React from 'react'

export default function PublicChannel() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Public Channel</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Channel/channel4.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>A public channel is open for everyone to join, view, and participate in. It's perfect for sharing knowledge, updates, or fostering community-wide discussions. Set your channel to "Public" in the settings to maximize visibility and engagement.</p>
            <div className='mt-[22px] flex flex-col gap-[15px]'>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>1</div>
                    <p className='text-[#000000] text-[16px] font-normal'>Anyone in the organization can view the channel list and  join the group.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
