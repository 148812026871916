import React from 'react'
import RecentSearches from './recent-searches';
import Search from './search';
import ChannelSearch from './channels-search';
import TaskSearch from './tasks-search';
import PeopleSearch from './people-search';
import FileSearch from './file-search';


export default function Mobile() {
    return (
        <div className='flex flex-col gap-[16px] relative w-full px-[20px] mt-[12px]'>
            <div className='text-[#000000] text-[15px] font-normal'>
                <p>The "Universal Search" feature in Workfast is your shortcut to finding any information quickly. Use the search bar at the top to locate tasks, projects, documents, or team members instantly. Filter results by type, date, or category to narrow down your search. Find relevant updates, messages, and files across all your projects in seconds. Search within tasks or comments to track specific details.</p>
            </div>
            <div id='recent-searches'><RecentSearches /></div>
            <div id='search'><Search /></div>
            <div id='channels-search'><ChannelSearch /></div>
            <div id='tasks-search'><TaskSearch /></div>
            <div id='people-search'><PeopleSearch /></div>
            <div id='file-search'><FileSearch /></div>
        </div>
    )
}
