import React from 'react'
import Channel from './channel'
import Project from './project';
import Task from './task';
import Chat from './chat';
import Report from './report';

export default function CommunicationHub() {
    return (
        <div className='mt-[20px] sm:mt-[60px] xl:mt-[90px] w-full px-[20px] sm:px-0 sm:w-10/12 mx-auto relative'>
            <div className='flex flex-col gap-[80px] sm:gap-[90px] xl:gap-[180px] justify-center items-center w-full text-center'>
                <div>
                    <h3 className='text-[#180833] text-[30px] xl:text-[40px] font-bold'>Key Features for Modern Teams</h3>
                    {/* <h4 className='text-[#1D272C] text-[16px] xl:text-[20px] font-normal sm:w-8/12 xl:w-9/12 mx-auto'>Start channels, share documents, organize video calls, and invite partners to collaborate.</h4> */}
                </div>
                <Channel/>
                <Project/>
                <Task/>
                <Chat/>
                <Report/>
            </div>
        </div>
    )
}
