import React from 'react'
import { useNavigate } from 'react-router-dom';
import background from "../../../../images/website-background.png";
import girish from "../../../../images/investors/girish.webp";
import guns from "../../../../images/investors/guns.webp";
import vijay from "../../../../images/investors/vijay.webp";




export default function Banner() {
    const navigate = useNavigate();
    return (
        <div className="h-full w-full flex flex-col relative mb-[20px] lg:mb-[60px] xl:mb-[100px] overflow-hidden gap-[20px] lg:gap-[60px] xl:gap-[100px] px-[20px] sm:px-[0px]">
            <div className='flex flex-col gap-[20px] lg:gap-[35px] text-center items-center my-[40px] relative'>
                <h2 className='text-[24px]  sm:text-[40px] xl:text-[70px] text-[#060606] font-bold relative z-[1]'>Investors</h2>
                <div className='hidden sm:flex flex-col gap-[10px] xl:gap-[20px] relative z-[1]'>
                    <h2 className='text-[16px] lg:text-[22px] xl:text-[27px] text-[#313131] font-medium'>We are on a mission to change the world! To accomplish this audacious vision, we are open </h2>
                    <h2 className='text-[16px]  lg:text-[22px] xl:text-[27px] text-[#313131] font-medium'> to investments with great people, who can travel with us in long term.</h2>
                </div>
                <h2 className='text-[14px] text-[#313131] font-medium text-center sm:hidden relative z-[1]'>We are on a mission to change the world! To accomplish this audacious vision, we are open to investments with great people, who can travel with us in long term.</h2>
                <button className='w-[200px] h-[38px] sm:h-[44px] lg:w-[300px] lg:h-[50px] rounded-[18px] bg-gradient-to-bl from-[#FDD01F] via-[#FDD320] to-[#F8861B] b3 relative z-[1]' onClick={() => { navigate('/investor/form') }}>
                    <span className='text text-[#2A2200] text-[12px] lg:text-[16px] font-semibold'>Apply for Investment</span>
                    <span class="shimmer2"></span>
                </button>
                <img src={background} alt='background' loading='lazy' className='absolute z-[0] w-full top-0' />
            </div>
            <div className='flex flex-col gap-[20px] lg:gap-[75px] text-center items-center relative w-full mb-[40px]'>
                <h2 className='text-[24px]  sm:text-[40px] xl:text-[55px] text-[#060606] font-semibold'>Our seed funding investors</h2>
                <div className='sm:w-11/12 xl:w-10/12 mx-auto grid grid-cols-1 sm:flex justify-between items-center'>
                    <div className='flex flex-col gap-[12px] items-center'>
                        <img src={guns} alt='guns' loading='lazy' className='sm:size-[300px] object-cover'/>
                        <h2 className='text-[#FDBC37] text-[20px] lg:text-[26px] font-semibold text-center'>Gans Subramaniam</h2>
                        <div className='text-[#060606] text-[18px] lg:text-[25px] font-medium text-center'>
                            <h2>Founder & Managing Partner</h2>
                            <h2>Hourglass Venture Partners</h2>
                        </div>
                    </div>
                    <div className='flex flex-col gap-[12px] items-center'>
                        <img src={girish} alt='girish' loading='lazy' className='sm:size-[300px] object-cover'/>
                        <h2 className='text-[#F4A4D7] text-[20px] lg:text-[26px] font-semibold text-center'>Girish Mathrubootham</h2>
                        <div className='text-[#060606] text-[18px] lg:text-[25px] font-medium text-center'>
                            <h2>CEO & Founder of</h2>
                            <h2>Freshworks</h2>
                        </div>
                    </div>
                    <div className='flex flex-col gap-[12px] items-center'>
                        <img src={vijay} alt='paytm' loading='lazy' className='sm:size-[300px] object-cover'/>
                        <h2 className='text-[#1ABC9C] text-[20px] lg:text-[26px] font-semibold text-center'>Vijay Shekhar Sharma</h2>
                        <div className='text-[#060606] text-[18px] lg:text-[25px] font-medium text-center'>
                            <h2>Founder & CEO of</h2>
                            <h2>Paytm</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
