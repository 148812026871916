import React from 'react'

export default function Priority() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Priority</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Project/project8.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Ensure your team focuses on the most critical tasks by setting clear priorities. Use priority labels like High, Medium, or Low to highlight urgency and importance.</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Assign priority levels to tasks.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
