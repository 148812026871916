import React from 'react'

export default function CustomizableAxis() {
    return (
        <div className='flex flex-col gap-[11px] justify-center'>
            <h3 className='text-[#000000] text-[18px] font-bold'>Customizable Axis</h3>
            <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Report/report3.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
            <div className='mt-[6px]'>
                <p className='text-[#000000] text-[14px] font-medium'>Tailor your report visuals with customizable axes to display the most relevant data. Adjust the X and Y axes to highlight key metrics, trends, or comparisons that matter to your project. This feature helps you create meaningful charts that drive insights.</p>
                <div className='mt-[11px] flex flex-col gap-[7px]'>
                    <div className='flex gap-[4px] items-start'>
                        <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold mt-[3px] shrink-0'>1</div>
                        <p className='text-[#000000] text-[14px] font-normal'>Select the Project</p>
                    </div>
                    <div className='flex gap-[4px] items-start'>
                        <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold mt-[3px] shrink-0'>2</div>
                        <p className='text-[#000000] text-[14px] font-normal'>Choose your preferred chart style as <span className='font-semibold'>Bar, Donut or Radial</span></p>
                    </div>
                    <div className='flex gap-[4px] items-start'>
                        <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold mt-[3px] shrink-0'>3</div>
                        <div>
                            <p className='text-[#000000] text-[14px] font-normal'>Choose your X axis as <span className='font-semibold'>Task Status or Assignee</span></p>
                            <p className='text-[#000000] text-[14px] font-normal mt-[4px]'>Choose your Y axis as <span className='font-semibold'>Task Count</span></p>
                        </div>
                    </div>
                    <div className='flex gap-[4px] items-start'>
                        <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold mt-[3px] shrink-0'>5</div>
                        <p className='text-[#000000] text-[14px] font-normal'>Save to generate the Report</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
