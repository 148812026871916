import React from 'react'

export default function VerifyOtp() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Verify OTP</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Onboard/onboard3.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>A One-Time Password (OTP) is a unique code sent to your email to verify your identity. This step helps ensure your account’s security by confirming that you are the rightful owner of the email address used to sign up.</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-center'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Enter your OTP</p>
                </div>
                <div className='flex gap-[4px] items-center'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold'>2</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Click Continue to proceed.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
