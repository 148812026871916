import React from 'react'

export default function EditDeleteMessages() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Edit & Delete Messages</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Dashboard/dashboard4.mov" autoPlay playsInline loop muted className='rounded-[2px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Maintain clarity and accuracy in your communications with the “Edit & Delete Messages” feature. Modify or remove messages to correct errors or remove outdated information. This functionality ensures your conversations remain relevant and error-free.</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Edit Messages feature on and off</p>
                </div>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>2</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Delete Messages feature on and off</p>
                </div>
            </div>
        </div>
    </div>
  )
}
