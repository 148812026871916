import React from 'react';
import Banner from './banner';
import Content from './content';
import Footer from '../../../footer';
import background from "../../../../images/website-background.png";
import Availbles from '../availables';
import Articles from '../articles';
import Header from '../header';

export default function Articles3() {
  const [positions, setPositions] = React.useState(false);

  return (
    <div className={`w-full relative ${positions ? "overflow-hidden" : ""}`}>
      <Header setPositions={setPositions}/>
      <Banner />
      <img src={background} alt='background' loading="lazy" className='absolute z-[0] top-[20px] sm:top-[80px] w-full' />
      <Content />
      <Availbles />
      <Articles/>
      <Footer />
    </div>
  );
}
