import React from 'react'
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import copy from 'clipboard-copy';
import mainImg from "../../../../../images/blog/article-2.webp";
import peter from "../../../../../images/blog/peter.webp";
import { FacebookIconSvg, LinkedinIconSvg, ShareIconSvg, TwitterIconSvg } from '../../svg';

export default function Banner() {
    const [showPopup, setShowPopup] = React.useState(false);
    const [copyPopup, setCopyPopup] = React.useState('');
    const backgroundRef = React.useRef(null);
    const [backgroundHeight, setBackgroundHeight] = React.useState(null);
    const [position, setPosition] = React.useState(window.innerWidth);

    const handleImageLoad = () => {
        if (backgroundRef.current) {
            setBackgroundHeight(backgroundRef.current.clientHeight);
        }
    };


    const handleCopy = (text) => {
        copy(text)
            .then(() => {
                setShowPopup(true);
                setCopyPopup('Link copied and ready to share');
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };

    React.useEffect(() => {
        let timer;
        if (showPopup) {
            timer = setTimeout(() => {
                setShowPopup(false);
                setCopyPopup("");
            }, 5000);
        }
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [showPopup]);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    return (
        <div className='px-[20px] sm:p-0 w-full sm:w-[92%] xl:w-[88%] mx-auto sm:flex gap-[40px] items-center relative xl:max-h-[350px] mt-[20px] lg:mt-[100px] mb-[20px] sm:mb-[60px] lg:mb-[100px]'>
            <img src={mainImg} alt='articles' loading="lazy" className='relative z-[1] sm:w-[50%] rounded-[14px]' ref={backgroundRef} onLoad={handleImageLoad} />
            <div className='flex flex-col gap-[30px] justify-between mt-[40px] sm:mt-0' style={{ height: position <= 645 ? "170px" : `${backgroundHeight}px` }}>
                <h3 className='text-[#000000] text-[18px] md:text-[22px] lg:text-[30px] xl:text-[40px] font-semibold relative z-[1] text-center sm:text-start'>Breaking Barriers in Communication and Collaboration with Workfast.ai</h3>
                <div className='flex gap-[20px] xl:gap-[40px] items-center relative z-[1]'>
                    <div className='flex gap-[8px] xl:gap-[12px] items-center'>
                        <img src={peter} alt='articles' loading="lazy" className='w-[20px] h-[20px] sm:w-[30px] sm:h-[30px] lg:w-[40px] lg:h-[40px] xl:w-[50px] xl:h-[50px] rounded-full' />
                        <h2 className='text-[#252525] text-[14px] lg:text-[20px] xl:text-[26px] font-medium'>Peter Elk</h2>
                    </div>
                    <div className='h-[24px] w-[1px] bg-[#252525] relative z-[1]'></div>
                    <h3 className='text-[#252525] text-[14px] lg:text-[20px] xl:text-[26px] font-medium relative z-[1]'>May 31,2024</h3>
                    <div className='h-[24px] w-[1px] bg-[#252525] relative z-[1]'></div>
                    <h3 className='text-[#252525] text-[14px] lg:text-[20px] xl:text-[26px] font-medium relative z-[1]'>18 min read</h3>
                </div>
                <div className='flex gap-[24px] xl:gap-[32px] justify-center sm:justify-start'>
                    <TwitterShareButton url="https://workfast.ai/blog/breaking-barriers-in-communication-collaboration-with-workfast.ai">
                        <div className='relative z-[1] cursor-pointer w-[30px] lg:w-[45px] xl:w-auto'>
                            <TwitterIconSvg size={position >= 1440 ? "60" : position >= 1024 ? "45" : "30"} />
                        </div>
                    </TwitterShareButton>
                    <LinkedinShareButton url='https://workfast.ai/blog/breaking-barriers-in-communication-collaboration-with-workfast.ai'>
                        <div className='relative z-[1] cursor-pointer w-[30px] lg:w-[45px] xl:w-auto'>
                            <LinkedinIconSvg size={position >= 1440 ? "60" : position >= 1024 ? "45" : "30"} />
                        </div>
                    </LinkedinShareButton>
                    <FacebookShareButton url="https://workfast.ai/blog/breaking-barriers-in-communication-collaboration-with-workfast.ai">
                        <div className='relative z-[1] cursor-pointer w-[30px] lg:w-[45px] xl:w-auto'>
                            <FacebookIconSvg size={position >= 1440 ? "60" : position >= 1024 ? "45" : "30"} />
                        </div>
                    </FacebookShareButton>
                    <div className='relative z-[1] cursor-pointer w-[30px] lg:w-[45px] xl:w-auto' onClick={() => { handleCopy("https://workfast.ai/blog/breaking-barriers-in-communication-collaboration-with-workfast.ai") }}>
                        <ShareIconSvg size={position >= 1440 ? "60" : position >= 1024 ? "45" : "30"} />
                    </div>
                </div>
            </div>
            {showPopup && (
                <div className="absolute z-[100] -top-[50px] left-[50%] -translate-x-[50%] px-[30px] py-[10px] bg-[#27AE6040] border-[#27AE60] border rounded-[10px] text-[#252525] text-[15px] font-medium transition-all duration-300">
                    <p>{copyPopup}</p>
                </div>
            )}
        </div>
    )
}
