import React from 'react'

export default function InfoTab() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Info Tab</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Project/project17.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Access key details about your project or task with the “Info Tab.” View essential information like descriptions, deadlines, and assigned team members all in one place. This tab helps you stay informed and manage your tasks effectively. Check the Info Tab to ensure you have all the relevant details at your fingertips!</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>View all key project details in one place</p>
                </div>
            </div>
        </div>
    </div>
  )
}
