import React from 'react'

export default function ContinueWithEmail() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Create a New Workspace or Continue with Work Email</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Onboard/onboard2.mov" autoPlay playsInline loop muted className=' xl:w-[706px] xl:h-[400px] rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>By selecting "Create a new workspace"or "Continue with Work Email" you can enter your work email. If an existing workspace is linked to your email, you’ll be redirected there. If not, you’ll have the option to create your own workspace seamlessly.</p>
            <div className='mt-[22px] flex flex-col gap-[15px]'>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>1</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Enter Your Email address here</p>
                </div>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>2</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Click Continue for next screen</p>
                </div>
            </div>
        </div>
    </div>
  )
}
