import React from 'react'

export default function SavingViewingReports() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Saving and Viewing Reports</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Report/report6.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Keep track of your team's progress by saving important reports for easy access. Click “Save Report” after generating to store it in your reports library. Access your saved reports anytime to review performance, analyze trends, and share insights. Start saving reports to maintain a clear project overview!</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold mt-[3px] shrink-0'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>The saved report is accessible in the "Report View" for future reference.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
