import React from 'react'
import axios from 'axios';
import { TbMenuDeep } from "react-icons/tb"
import { RxCross2 } from "react-icons/rx";
import { useNavigate, useLocation } from 'react-router-dom';
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import API from '../../../utils/API';
import { AdminDashboardSvg, AlertSvg, ChannelSvg, ChatSvg, OnboardingSvg, PeopleSvg, ProfileSvg, ProjectSvg, ReportSvg, TaskSvg, UniversalSearchSvg } from '../svg';

export default function Mobile() {
    const [isOpen, setIsOpen] = React.useState(false);
    const [isUserGuide, setIsUserGuide] = React.useState(false)
    const navigate = useNavigate();
    const location = useLocation();

    const handleLiginClick = async (e) => {
        e.preventDefault();
        const response = await axios.post(API.BUTTON,
            {
                "eventName": "LOG_IN_FOR_FREE_MAIN"
            }
        );
        if (response.data.statusCode === 200) {
            window.location.href = 'https://app.workfast.ai/';
        }
    };

    return (
        <div className={`${isOpen ? "fixed w-full" : "relative"} z-[60] w-full`}>
            <div className={`w-full flex justify-between items-center p-[20px] z-[50] border-[1px] border-[#CECEEA] relative ${isOpen ? "bg-[#FFF]" : ""}`}>
                <div className='px-[10px] py-[10px] border border-[#CECEEA] rounded-[16px] bg-[#FFF]' style={{ boxShadow: '0px 4px 7px 0px #432E860A' }} onClick={() => { navigate('/') }}><img src="https://dz1x1c630cl14.cloudfront.net/webassets/whiteThemeLogo.svg" alt='logo' loading="lazy" className='w-[110px]' /></div>
                <div className='flex gap-[10px] items-center'>
                    <button className='border border-[#C5A313] bg-[#FDD01F] rounded-[12px] h-[34px] w-[100px] text-[#2A2200] text-[13px] font-semibold' onClick={() => { window.location.href = '/book-for-demo'; }}>Book a Demo</button>
                    {!isOpen ?
                        <TbMenuDeep className='bg-[#FFF] text-[#24223E] w-[34px] h-[34px] p-[6px] border-[#CECEEA] border-[1px] rounded-[12px]' style={{ boxShadow: '0px 4px 7px 0px #432E860A' }} onClick={() => setIsOpen(!isOpen)} />
                        :
                        <RxCross2 className='bg-[#FFF] text-[#24223E] w-[34px] h-[34px] p-[6px] border-[#CECEEA] border-[1px] rounded-[12px]' style={{ boxShadow: '0px 4px 7px 0px #432E860A' }} onClick={() => { setIsOpen(!isOpen); setIsUserGuide(false) }} />
                    }
                </div>
            </div>
            <div className={`${isOpen ? "max-h-full opacity-100 pb-6" : "max-h-0 opacity-0 hidden"} w-full transition-all duration-300 relative rounded-b-[20px] bg-[#FFF] z-[50]`}>
                <div className={`w-10/12 mx-auto ${!isUserGuide ? "" : "hidden"}`}>
                    {/* <div className={`mr-[40px] py-[10px] mb-6 cursor-pointer rounded-[5px] ${location?.pathname === "features" ? "text-[#FDD01F]" : "text-[#24223E]"}`} onClick={(e) => { navigate('/blog') }}>
                        <p className="font-medium text-[14px]">Features</p>
                    </div> */}
                    <div className={`py-[18px] cursor-pointer flex justify-between ${isUserGuide ? "text-[#FDD01F]" : "text-[#24223E]"}`} onClick={(e) => { setIsUserGuide(!isUserGuide) }}>
                        <p className="font-semibold text-[18px]">User Guide</p>
                        <FaAngleRight className={`text-[18px] text-[#24223E]`} />
                    </div>
                    <div className={`py-[18px] cursor-pointer ${location?.pathname === "/blog" ? "text-[#FDD01F]" : "text-[#24223E]"}`} onClick={(e) => { navigate('/blog') }}>
                        <p className="font-semibold text-[18px]">Blog</p>
                    </div>
                    <div className={`py-[18px] cursor-pointer ${location?.pathname === "/pricing" ? "text-[#FDD01F]" : "text-[#24223E]"}`} onClick={() => { navigate('/pricing') }}>
                        <p className="font-semibold text-[18px]">Pricing</p>
                    </div>
                    <div className={`py-[18px] cursor-pointer ${location?.pathname === "/investor" ? "text-[#FDD01F]" : "text-[#24223E]"}`} onClick={(e) => { navigate('/investor') }}>
                        <p className="font-semibold text-[18px]">Investor</p>
                    </div>
                    <div className={`py-[18px] cursor-pointer ${location?.pathname === "/partnership-program" ? "text-[#FDD01F]" : "text-[#24223E]"}`} onClick={(e) => { navigate('/partnership-program') }}>
                        <p className="font-semibold text-[18px]">Reseller</p>
                    </div>
                    <button className='border border-[#C5A313] bg-[#FDD01F] rounded-[12px] h-[45px] w-full text-[#2A2200] text-[18px] font-semibold' onClick={(e) => { handleLiginClick(e) }}>Login</button>
                </div>
                <div className={`w-full ${isUserGuide ? "" : "hidden"}`}>
                    <div className='py-[14px] border-b border-[#CECEEA] px-[20px]' style={{ boxShadow: "0px 0px 4px 0px #D4D4D440" }}>
                        <div className='flex gap-[8px] items-center text-[#FDD01F] text-[18px] font-semibold cursor-pointer' onClick={() => { setIsUserGuide(false) }}>
                            <FaAngleLeft />
                            <div>Back</div>
                        </div>
                    </div>
                    <div className='p-[20px] flex gap-[20px] flex-col justify-start items-center h-[80vh] overflow-y-scroll'>
                        <div className='p-[10px] border-[1px] border-[#CECEEA] rounded-[12px] w-full' onClick={() => { navigate('/user-guide-onboarding'); setIsOpen(false); setIsUserGuide(false) }}>
                            <div className='flex gap-[4px] items-center'>
                                <OnboardingSvg size="20" />
                                <div className='text-[18px] text-[#FF3B30] font-semibold'>Onboarding</div>
                            </div>
                            <div className='text-[#000000] text-[16px] font-normal'>Set up and start using your account quickly.</div>
                        </div>
                        <div className='p-[10px] border-[1px] border-[#CECEEA] rounded-[12px] w-full' onClick={() => { navigate('/user-guide-people'); setIsOpen(false); setIsUserGuide(false) }}>
                            <div className='flex gap-[4px] items-center'>
                                <PeopleSvg size="20" />
                                <div className='text-[18px] text-[#FF9500] font-semibold'>People</div>
                            </div>
                            <div className='text-[#000000] text-[16px] font-normal'>LEasily manage and assign team roles.</div>
                        </div>
                        <div className='p-[10px] border-[1px] border-[#CECEEA] rounded-[12px] w-full' onClick={() => { navigate('/user-guide-channel'); setIsOpen(false); setIsUserGuide(false) }}>
                            <div className='flex gap-[4px] items-center'>
                                <ChannelSvg size="20" />
                                <div className='text-[18px] text-[#FFCC00] font-semibold'>Channels</div>
                            </div>
                            <div className='text-[#000000] text-[16px] font-normal'>Create and manage channels for team communication.</div>
                        </div>
                        <div className='p-[10px] border-[1px] border-[#CECEEA] rounded-[12px] w-full' onClick={() => { navigate('/user-guide-chat'); setIsOpen(false); setIsUserGuide(false) }}>
                            <div className='flex gap-[4px] items-center'>
                                <ChatSvg size="20" />
                                <div className='text-[18px] text-[#34C759] font-semibold'>Chat</div>
                            </div>
                            <div className='text-[#000000] text-[16px] font-normal'>Instantly chat and collaborate seamlessly.</div>
                        </div>
                        <div className='p-[10px] border-[1px] border-[#CECEEA] rounded-[12px] w-full' onClick={() => { navigate('/user-guide-task'); setIsOpen(false); setIsUserGuide(false) }}>
                            <div className='flex gap-[4px] items-center'>
                                <TaskSvg size="20" />
                                <div className='text-[18px] text-[#00C7BE] font-semibold'>Tasks</div>
                            </div>
                            <div className='text-[#000000] text-[16px] font-normal'>Create, assign, and track tasks effortlessly.</div>
                        </div>
                        <div className='p-[10px] border-[1px] border-[#CECEEA] rounded-[12px] w-full' onClick={() => { navigate('/user-guide-project'); setIsOpen(false); setIsUserGuide(false) }}>
                            <div className='flex gap-[4px] items-center'>
                                <ProjectSvg size="20" />
                                <div className='text-[18px] text-[#30B0C7] font-semibold'>Projects</div>
                            </div>
                            <div className='text-[#000000] text-[16px] font-normal'>Create, manage, and track projects easily.</div>
                        </div>
                        <div className='p-[10px] border-[1px] border-[#CECEEA] rounded-[12px] w-full' onClick={() => { navigate('/user-guide-report'); setIsOpen(false); setIsUserGuide(false) }}>
                            <div className='flex gap-[4px] items-center'>
                                <ReportSvg size="20" />
                                <div className='text-[18px] text-[#32ADE6] font-semibold'>Reports</div>
                            </div>
                            <div className='text-[#000000] text-[16px] font-normal'>Generate and analyze reports with ease.</div>
                        </div>
                        <div className='p-[10px] border-[1px] border-[#CECEEA] rounded-[12px] w-full' onClick={() => { navigate('/user-guide-notification'); setIsOpen(false); setIsUserGuide(false) }}>
                            <div className='flex gap-[4px] items-center'>
                                <AlertSvg size="20" />
                                <div className='text-[18px] text-[#007AFF] font-semibold'>Alert</div>
                            </div>
                            <div className='text-[#000000] text-[16px] font-normal'>Receive and manage alerts for important updates.</div>
                        </div>
                        <div className='p-[10px] border-[1px] border-[#CECEEA] rounded-[12px] w-full' onClick={() => { navigate('/user-guide-universal-search'); setIsOpen(false); setIsUserGuide(false) }}>
                            <div className='flex gap-[4px] items-center'>
                                <UniversalSearchSvg size="20" />
                                <div className='text-[18px] text-[#5856D6] font-semibold'>Universal Search</div>
                            </div>
                            <div className='text-[#000000] text-[16px] font-normal'>Quickly find tasks, projects, and more.</div>
                        </div>
                        <div className='p-[10px] border-[1px] border-[#CECEEA] rounded-[12px] w-full' onClick={() => { navigate('/user-guide-profile'); setIsOpen(false); setIsUserGuide(false) }}>
                            <div className='flex gap-[4px] items-center'>
                                <ProfileSvg size="20" />
                                <div className='text-[18px] text-[#FF2D55] font-semibold'>My Profile</div>
                            </div>
                            <div className='text-[#000000] text-[16px] font-normal'>Update and manage your profile settings.</div>
                        </div>
                        <div className='p-[10px] border-[1px] border-[#CECEEA] rounded-[12px] w-full' onClick={() => { navigate('/user-guide-admin-dashboard'); setIsOpen(false); setIsUserGuide(false) }}>
                            <div className='flex gap-[4px] items-center'>
                                <AdminDashboardSvg size="20" />
                                <div className='text-[18px] text-[#A2845E] font-semibold'>Admin Dashboard</div>
                            </div>
                            <div className='text-[#000000] text-[16px] font-normal'>Manage users, settings, and data easily.</div>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && (
                <div className='fixed h-screen w-full top-0 left-0 bg-[#000]/[90%] z-[10]' onClick={() => setIsOpen(!isOpen)}></div>
            )}
        </div>
    )
}
