import React from 'react'
import bg from "../../../../../images/home/task-bg.png";
import { AssignedSvg, CollabaorationSvg, ListSvg, TaskArcheryScg, TaskClownSvg, TaskDocSvg, TaskIconSvg, TaskTickSvg } from './svg';



export default function Task() {
    const [cardButton, setCardButton] = React.useState(0);
    const [position, setPosition] = React.useState(window.innerWidth);
    
    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    return (
        <div className='lg:flex gap-[60px] xl:gap-[90px] items-end relative z-[15]'>
            <div className='relative z-[10]'>
                <div className='flex gap-[9px] items-center'>
                    <TaskIconSvg size={position >= 1440 ? "40" : "35"} />
                    <h3 className='text-[#000000] text-[26px] xl:text-[32px] font-semibold'>Task</h3>
                </div>
                <div className='flex gap-[20px] items-center lg:justify-center flex-col mt-[26px] xl:mt-[32px]'>
                    <div className={`w-full lg:w-[300px] xl:w-[390px] h-[90px] rounded-[8px] bg-[#FFF] flex gap-[8px] items-start pl-[16px] pt-[16px] xl:pl-[20px] transition-all duration-300 shadow-md cursor-pointer ${cardButton === 0 ? "scale-[1.05]" : ""}`} onClick={() => { setCardButton(0) }}>
                        <ListSvg size={position >= 1440 ? "25" : "20"} />
                        <div className='text-start'>
                            <h5 className='text-[#180833] text-[16px] xl:text-[20px] font-medium'>Task and Subtask</h5>
                            <p className='text-[#1D272C] text-[14px] xl:text-[16px] font-normal'>Create infinite tasks and subtasks.</p>
                        </div>
                    </div>
                    <div className={`w-full lg:w-[300px] xl:w-[390px] h-[90px] rounded-[8px] bg-[#FFF] flex gap-[8px] items-start pl-[16px] pt-[16px] xl:pl-[20px] transition-all duration-300 shadow-md cursor-pointer ${cardButton === 1 ? "scale-[1.05]" : ""}`} onClick={() => { setCardButton(1) }}>
                        <AssignedSvg size={position >= 1440 ? "25" : "20"} />
                        <div className='text-start'>
                            <h5 className='text-[#180833] text-[16px] xl:text-[20px] font-medium'>Assigned to me</h5>
                            <p className='text-[#1D272C] text-[14px] xl:text-[16px] font-normal'>View all tasks assigned to me in one place.</p>
                        </div>
                    </div>
                    <div className={`w-full lg:w-[300px] xl:w-[390px] h-[90px] rounded-[8px] bg-[#FFF] flex gap-[8px] items-start pl-[16px] pt-[16px] xl:pl-[20px] transition-all duration-300 shadow-md cursor-pointer ${cardButton === 2 ? "scale-[1.05]" : ""}`} onClick={() => { setCardButton(2) }}>
                        <TaskDocSvg size={position >= 1440 ? "25" : "20"} />
                        <div className='text-start'>
                            <h5 className='text-[#180833] text-[16px] xl:text-[20px] font-medium'>Docs</h5>
                            <p className='text-[#1D272C] text-[14px] xl:text-[16px] font-normal'>Attach docs to task and subtask</p>
                        </div>
                    </div>
                    <div className={`w-full lg:w-[300px] xl:w-[390px] h-[90px] rounded-[8px] bg-[#FFF] flex gap-[8px] items-start pl-[16px] pt-[16px] xl:pl-[20px] transition-all duration-300 shadow-md cursor-pointer ${cardButton === 3 ? "scale-[1.05]" : ""}`} onClick={() => { setCardButton(3) }}>
                        <CollabaorationSvg size={position >= 1440 ? "25" : "20"} />
                        <div className='text-start'>
                            <h5 className='text-[#180833] text-[16px] xl:text-[20px] font-medium'>Collaborators</h5>
                            <p className='text-[#1D272C] text-[14px] xl:text-[16px] font-normal'>Add collaborators to task and subtask</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='relative z-[10] mt-[100px] sm:mt-[140px] lg:mt-0'>
                <div className='absolute -top-[60px] sm:-top-[80px] z-[0] w-[100px] sm:w-auto'>
                    <TaskArcheryScg size={position >= 645 ? "138" : "100"}/>
                </div>
                <div className='absolute -right-[20px] sm:-right-[80px] -top-[30px] sm:-top-[70px] w-[30px] sm:w-auto'>
                    <TaskClownSvg size={position >= 645 ? "88" : "30"}/>
                </div>
                <div className='absolute right-[0px] sm:-right-[100px] -bottom-[50px] sm:bottom-[30px] w-[50px]'>
                    <TaskTickSvg size={position >= 645 ? "118" : "50"}/>
                </div>
                <img src={bg} alt='workfast' loading='lazy' className='absolute -bottom-[10px] sm:-bottom-[20px] -right-[10px] sm:-right-[20px] z-0'/>
                {cardButton === 0 ?
                    <video src="https://dz1x1c630cl14.cloudfront.net/webassets/home/task/task1.mov" autoPlay loop muted playsInline className='xl:w-[653px] xl:h-[428px] rounded-[9px] sm:rounded-[17px] xl:rounded-[21px] relative z-10'></video>
                    : cardButton === 1 ?
                        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/home/task/task2.mov" autoPlay loop muted playsInline className='xl:w-[653px] xl:h-[428px] rounded-[9px] sm:rounded-[17px] xl:rounded-[21px] relative z-10'></video>
                        : cardButton === 2 ?
                            <video src="https://dz1x1c630cl14.cloudfront.net/webassets/home/task/task3.mov" autoPlay loop muted playsInline className='xl:w-[653px] xl:h-[428px] rounded-[9px] sm:rounded-[17px] xl:rounded-[21px] relative z-10'></video>
                            : cardButton === 3 &&
                            <video src="https://dz1x1c630cl14.cloudfront.net/webassets/home/task/task4.mov" autoPlay loop muted playsInline className='xl:w-[653px] xl:h-[428px] rounded-[9px] sm:rounded-[17px] xl:rounded-[21px] relative z-10'></video>
                }
            </div>
        </div>
    )
}
