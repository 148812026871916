import React from 'react'

export default function TaskListManagement() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
      <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Task List Management</h3>
      <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Task/task13.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
      <div className='mt-[10px]'>
        <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Keep your tasks list organized by creating, editing, or deleting task lists as needed. Use the “Task List Management” feature to prioritize, categorize, and streamline your workflow.</p>
        <div className='mt-[22px] flex flex-col gap-[15px]'>
          <div className='flex gap-[8px] items-center'>
            <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>1</div>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Use the ellipsis icon (3-dot menu) to Edit List, Duplicate List, Archive List</p>
          </div>
        </div>
      </div>
    </div>
  )
}
