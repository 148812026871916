import React from 'react'
import bg from "../../../../../images/home/chat-bg.png";
import { ChatClownSvg, ChatDmSvg, ChatIconSvg, ChatRocketSvg, ChatShareSvg, ChatVoiceSvg, DmSvg, GroupMessagesSvg } from './svg';




export default function Chat() {
    const [cardButton, setCardButton] = React.useState(0);
    const [position, setPosition] = React.useState(window.innerWidth);
    
    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    return (
        <div className='flex flex-col-reverse lg:flex-row gap-[100px] sm:gap-[160px] lg:gap-[60px] xl:gap-[90px] items-end relative z-[15]'>
            <div className='relative z-[10]'>
                <div className='absolute -top-[70px] sm:-top-[95px] right-[20px] w-[50px] sm:w-auto'>
                    <ChatDmSvg size={position >= 645 ? "108" : "50"}/>
                </div>
                <div className='absolute -left-[20px] sm:-left-[80px] -top-[30px] sm:-top-[70px] w-[30px] sm:w-auto' >
                    <ChatClownSvg size={position >= 645 ? "88" : "30"}/>
                </div>
                <div className='absolute left-[40px] sm:left-[100px] -bottom-[60px] sm:-bottom-[90px] w-[50px] sm:w-auto'>
                    <ChatRocketSvg size={position >= 645 ? "77" : "50"}/>
                </div>
                <img src={bg} alt='workfast' loading='lazy' className='absolute -bottom-[10px] sm:-bottom-[20px] -left-[10px] sm:-left-[20px] z-0'/>
                {cardButton === 0 ?
                    <video src="https://dz1x1c630cl14.cloudfront.net/webassets/home/chat/chat1.mov" autoPlay loop muted playsInline className='xl:w-[653px] xl:h-[428px] rounded-[9px] sm:rounded-[17px] xl:rounded-[21px] relative z-10'></video>
                    : cardButton === 1 ?
                        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/home/chat/chat2.mov" autoPlay loop muted playsInline className='xl:w-[653px] xl:h-[428px] rounded-[9px] sm:rounded-[17px] xl:rounded-[21px] relative z-10'></video>
                        : cardButton === 2 ?
                            <video src="https://dz1x1c630cl14.cloudfront.net/webassets/home/chat/chat3.mov" autoPlay loop muted playsInline className='xl:w-[653px] xl:h-[428px] rounded-[9px] sm:rounded-[17px] xl:rounded-[21px] relative z-10'></video>
                            : cardButton === 3 &&
                            <video src="https://dz1x1c630cl14.cloudfront.net/webassets/home/chat/chat4.mov" autoPlay loop muted playsInline className='xl:w-[653px] xl:h-[428px] rounded-[9px] sm:rounded-[17px] xl:rounded-[21px] relative z-10'></video>
                }
            </div>
            <div className='relative z-[10] w-full lg:w-auto'>
                <div className='flex gap-[9px] items-center'>
                    <ChatIconSvg size={position >= 1440 ? "40" : "35"}/>
                    <h3 className='text-[#000000] text-[26px] xl:text-[32px] font-semibold'>Chat</h3>
                </div>
                <div className='flex gap-[20px] items-center lg:justify-center flex-col mt-[26px] xl:mt-[32px]'>
                    <div className={`w-full lg:w-[300px] xl:w-[390px] h-[90px] rounded-[8px] bg-[#FFF] flex gap-[8px] items-start pl-[16px] pt-[16px] xl:pl-[20px] transition-all duration-300 shadow-md cursor-pointer ${cardButton === 0 ? "scale-[1.05]" : ""}`} onClick={() => { setCardButton(0) }}>
                        <DmSvg size={position >= 1440 ? "25" : "20"} />
                        <div className='text-start'>
                            <h5 className='text-[#180833] text-[16px] xl:text-[20px] font-medium'>DM’s</h5>
                            <p className='text-[#1D272C] text-[14px] xl:text-[16px] font-normal'>DM your colleagues</p>
                        </div>
                    </div>
                    <div className={`w-full lg:w-[300px] xl:w-[390px] h-[90px] rounded-[8px] bg-[#FFF] flex gap-[8px] items-start pl-[16px] pt-[16px] xl:pl-[20px] transition-all duration-300 shadow-md cursor-pointer ${cardButton === 1 ? "scale-[1.05]" : ""}`} onClick={() => { setCardButton(1) }}>
                        <GroupMessagesSvg size={position >= 1440 ? "25" : "20"} />
                        <div className='text-start'>
                            <h5 className='text-[#180833] text-[16px] xl:text-[20px] font-medium'>Group Messages</h5>
                            <p className='text-[#1D272C] text-[14px] xl:text-[16px] font-normal'>Create multiple groups for teams</p>
                        </div>
                    </div>
                    <div className={`w-full lg:w-[300px] xl:w-[390px] h-[90px] rounded-[8px] bg-[#FFF] flex gap-[8px] items-start pl-[16px] pt-[16px] xl:pl-[20px] transition-all duration-300 shadow-md cursor-pointer ${cardButton === 2 ? "scale-[1.05]" : ""}`} onClick={() => { setCardButton(2) }}>
                        <ChatShareSvg size={position >= 1440 ? "25" : "20"} />
                        <div className='text-start'>
                            <h5 className='text-[#180833] text-[16px] xl:text-[20px] font-medium'>Share Files & Docs</h5>
                            <p className='text-[#1D272C] text-[14px] xl:text-[16px] font-normal'>Share important assets in groups</p>
                        </div>
                    </div>
                    <div className={`w-full lg:w-[300px] xl:w-[390px] h-[90px] rounded-[8px] bg-[#FFF] flex gap-[8px] items-start pl-[16px] pt-[16px] xl:pl-[20px] transition-all duration-300 shadow-md cursor-pointer ${cardButton === 3 ? "scale-[1.05]" : ""}`} onClick={() => { setCardButton(3) }}>
                        <ChatVoiceSvg size={position >= 1440 ? "25" : "20"} />
                        <div className='text-start'>
                            <h5 className='text-[#180833] text-[16px] xl:text-[20px] font-medium'>Audio Message</h5>
                            <p className='text-[#1D272C] text-[14px] xl:text-[16px] font-normal'>Send audio messages with one click</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

