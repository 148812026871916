import React from 'react'

export default function InvitePeople() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Invite People</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/People/people1.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Inviting people to your workspace in Workfast.ai is the first step to building a collaborative environment where your team can work together seamlessly.</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold mt-[3px] shrink-0'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Click the "Invite People" button to begin the process.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
