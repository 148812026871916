import React from 'react'

export default function WorkspaceSetup() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Workspace Setup Successful</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Onboard/onboard8.mov" autoPlay playsInline loop muted className=' xl:w-[706px] xl:h-[400px] rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Congratulations! Your workspace setup is complete. You’re now ready to start collaborating with your team, managing projects, and report generation in Workfast.ai 🚀</p>
        </div>
    </div>
  )
}
