import React from 'react'

export default function EnterWorkSpaceName() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Create New Workspace</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Onboard/onboard4.mov" autoPlay playsInline loop muted className=' xl:w-[706px] xl:h-[400px] rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Creating your new workspace is quick and easy. Just follow a few simple steps to get started!</p>
            <div className='mt-[22px] flex flex-col gap-[15px]'>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>1</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Enter your Workspace Name</p>
                </div>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>2</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Click Continue to proceed.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
