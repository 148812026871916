import React from 'react'
import CustomizingYourReport from './customizing-your-report';
import CreateReport from './create-a-report';
import CustomizableAxis from './customizable-axis';
import TableView from './table-view';
import GraphView from './graph-view';
import SavingViewingReports from './saving-viewing-reports';


export default function Mobile() {
    return (
        <div className='flex flex-col gap-[16px] relative w-full px-[20px] mt-[12px]'>
            <div className='text-[#000000] text-[15px] font-normal'>
                <p>Reports in Workfast help you track project and task performance, providing detailed insights and data summaries. You can generate reports to analyze productivity, task completion, and team progress. These reports can be customized to display key metrics, visual graphs, and timelines, making it easier to monitor workflow and make informed decisions. You can export these reports in various formats, helping you share updates with stakeholders or for internal analysis.</p>
            </div>
            <div id='customizing-your-report'><CustomizingYourReport /></div>
            <div id='create-a-report'><CreateReport /></div>
            <div id='customizable-axis'><CustomizableAxis /></div>
            <div id='table-view'><TableView /></div>
            <div id='graph-view'><GraphView /></div>
            <div id='saving-viewing-reports'><SavingViewingReports /></div>
        </div>
    )
}
