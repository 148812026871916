import React from 'react'

export default function Integration() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[18px] xl:gap-[22px]'>
            <h2 className='text-[#000000] text-[21px] md:text-[26px] lg:text-[30px] font-semibold relative z-[1] md:w-[395px] lg:w-[460px]'>Integration and personalisation :</h2>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>To get an uninterrupted and seamless workflow, Workfast.ai provides with integration option with multiple tools. You can get customised as well. You can integrate Asana, Jira, Slack, Click-up, Adobe, Github, Notion, Email etc with just one click.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>The user can personalise the launch bar or bottom bar for their choice. What a user really needs is his/her choice. Whenever user opens the web Breaking Barriers in Communication and Collaboration with Workfast.ai 5 he/she can have the option to see ‘What’s the daily task?’ so they can customise to see task section in the first place.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Similarly if they need projects or channels or calendars or emails to be in their menu, they can easily click and shift the place.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Even one can switch off unwanted sections in menu with one click. Why a sales executive want to know about CXO’s Dashboard or Finance sheets.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>There is a seperate section for Daily reports in side bar. This can help to see year long data and helps in analysing the performance of the employee correctly.</p>
        </div>
    )
}
