import React from 'react'
import { AssigneByMeSvg, AssignedToMeSvg, CompletedTaskSvg, MainSubTaskSvg, OverdueTaskSvg, PeopleSvg, PrioritySvg, StatusSvg, TagSvg } from '../../../../project/svg';

export default function TaskFiltering() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
      <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Task Filtering</h3>
      <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Task/task9.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
      <div className='mt-[10px]'>
        <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Quickly find the tasks you need by using the “Filter” option. Narrow down your task list by criteria like status, assignee, due date, or tags. Start filtering to streamline your task management today!</p>
        <div className='mt-[22px] flex flex-col gap-[22px]'>
          <div className='flex gap-[8px] items-center'>
            <AssigneByMeSvg size="24" />
            <p className='text-[#000] text-[14px] lg:text-[16px] font-normal'><span className='text-[#FF3B30] font-semibold'>Assigned by me</span> : Assigned by Me lists tasks you’ve assigned to others.</p>
          </div>
          <div className='flex gap-[8px] items-center'>
            <AssignedToMeSvg size="24" />
            <p className='text-[#000] text-[14px] lg:text-[16px] font-normal'><span className='text-[#FF9500] font-semibold'>Assigned to me</span> : Assigned to Me lists tasks assigned to you.</p>
          </div>
          <div className='flex gap-[8px] items-center'>
            <CompletedTaskSvg size="24" />
            <p className='text-[#000] text-[14px] lg:text-[16px] font-normal'><span className='text-[#FFCC00] font-semibold'>Completed tasks</span> : Completed Tasks shows tasks that are finished.</p>
          </div>
          <div className='flex gap-[8px] items-center'>
            <PeopleSvg size="24" />
            <p className='text-[#000] text-[14px] lg:text-[16px] font-seminormalbold'><span className='text-[#34C759] font-semibold'>People</span> : People displays all team members or participants.</p>
          </div>
          <div className='flex gap-[8px] items-center'>
            <PrioritySvg size="24" />
            <p className='text-[#000] text-[14px] lg:text-[16px] font-normal'><span className='text-[#00C7BE] font-semibold'>Priority</span> : Priority indicates the importance or urgency of a task.</p>
          </div>
          <div className='flex gap-[8px] items-center'>
            <StatusSvg size="24" />
            <p className='text-[#000] text-[14px] lg:text-[16px] font-normal'><span className='text-[#007AFF] font-semibold'>Status</span> : Status shows the current state or progress of a task.</p>
          </div>
          <div className='flex gap-[8px] items-center'>
            <TagSvg size="24" />
            <p className='text-[#000] text-[14px] lg:text-[16px] font-normal'><span className='text-[#5856D6] font-semibold'>Tag</span> : Tag labels or categorizes tasks for easier organization and filtering.</p>
          </div>
          <div className='flex gap-[8px] items-center'>
            <OverdueTaskSvg size="24" />
            <p className='text-[#000] text-[14px] lg:text-[16px] font-normal'><span className='text-[#AF52DE] font-semibold'>Overdue Tasks</span> : Overdue Tasks shows tasks that have passed their due date.</p>
          </div>
          <div className='flex gap-[8px] items-center'>
            <MainSubTaskSvg size="24" />
            <p className='text-[#000] text-[14px] lg:text-[16px] font-normal'><span className='text-[#FF2D55] font-semibold'>Main & Sub Tasks</span> : Main & Sub Tasks organize primary tasks with related smaller tasks.</p>
          </div>
        </div>
      </div>
    </div>
  )
}
