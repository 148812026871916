import React from 'react'
import { TbCircleMinus, TbCircleCheckFilled } from "react-icons/tb";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { JiraSvg, TwoSvg, WorkfastSvg } from '../../../svg';


export default function Comparison() {

    React.useEffect(() => {
        AOS.init();
    }, []);

    const list = [
        { title: 'Story Points', jira: 'YES', workfast: 'YES' },
        { title: 'T-Shirt Size (Task Estimation)', jira: 'YES', workfast: 'YES' },
        { title: 'Sprint', jira: 'YES', workfast: 'YES' },
        { title: 'Sprint Insights', jira: 'YES', workfast: 'YES' },
        { title: 'Backlog Insights', jira: 'YES', workfast: 'YES' },
        { title: 'Task Priority Breakdown', jira: 'YES', workfast: 'YES' },
        { title: 'Whiteboard Retrospective', jira: 'NO', workfast: 'YES' },
        { title: 'Page Retrospective', jira: 'NO', workfast: 'YES' },
        { title: 'Velocity Report', jira: 'YES', workfast: 'YES' },
        { title: 'Workflow Automation', jira: 'YES', workfast: 'YES' },
        { title: 'Standup Call', jira: 'NO', workfast: 'YES' },
        { title: 'Issue Tracking', jira: 'YES', workfast: 'YES' },
        { title: 'Custom Workflows', jira: 'YES', workfast: 'YES' },
        { title: 'Kanban Board', jira: 'YES', workfast: 'YES' },
        { title: 'Scrum Board', jira: 'YES', workfast: 'YES' },
        { title: 'Burndown Charts', jira: 'YES', workfast: 'YES' },
        { title: 'Gantt Charts', jira: 'YES', workfast: 'YES' },
        { title: 'Epics and Stories', jira: 'YES', workfast: 'YES' },
        { title: 'Backlog Management', jira: 'YES', workfast: 'YES' },
        { title: 'Time Tracking', jira: 'YES', workfast: 'YES' },
        { title: 'Advanced Searching (JQL)', jira: 'YES', workfast: 'YES' },
        { title: 'Permissions and Roles', jira: 'YES', workfast: 'YES' },
        { title: 'Audit Logs', jira: 'YES', workfast: 'YES' },
        { title: 'Slack Integration', jira: 'YES', workfast: 'YES' },
        { title: 'Git Integration', jira: 'YES', workfast: 'YES' },
        { title: 'Roadmap Management', jira: 'YES', workfast: 'YES' },
        { title: 'Custom Fields', jira: 'YES', workfast: 'YES' },
        { title: 'Release Management', jira: 'YES', workfast: 'YES' },
        { title: 'Test Case Management', jira: 'YES', workfast: 'YES' },
        { title: 'Permissions Schemes', jira: 'YES', workfast: 'YES' },
        { title: 'API Access', jira: 'YES', workfast: 'YES' },
        { title: 'Integrations', jira: 'YES', workfast: 'YES' },
        { title: 'Mobile App', jira: 'YES', workfast: 'YES' },
        { title: 'Cloud/On-prem', jira: 'YES', workfast: 'YES' },
    ]
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);
    return (
        <div className='flex flex-col justify-center items-center gap-[20px] w-full px-[20px] sm:px-0 '>
            <div className='h-[100px]' data-aos="fade-down">
                <TwoSvg size={position >= 645 ? "130" : "100"} />
            </div>
            <div className='text-[#000000] text-[22px] sm:text-[30px] lg:text-[50px] font-semibold text-center'>Run your business professionally</div>
            <div className=' sm:mt-[20px] sm:w-10/12 mx-auto relative overflow-x-scroll'>
                <div className='grid grid-cols-4 border-[1px] border-[#CECEEA] rounded-t-[6px] sm:rounded-t-[12px] w-full '>
                    <div className='col-span-2 px-[10px] py-[14px] sm:px-[18px] sm:py-[25px]'>
                        <div className='text-[#000000] text-[14px] sm:text-[20px] font-bold'>Compare features</div>
                    </div>
                    <div className='border-l-[1px] border-[#CECEEA] p-[2px] sm:p-[5px]'>
                        <div className='bg-[#1868DB0D] w-full h-full flex flex-col-reverse sm:flex-row justify-center items-center sm:gap-[6px]'>
                            <div className='w-[15px] sm:w-auto'>
                                <JiraSvg size={position >= 645 ? "25" : "15"} />
                            </div>
                            <div className='text-[#000000] text-[12px] sm:text-[16px] font-bold'>Jira</div>
                        </div>
                    </div>
                    <div className='border-l-[1px] border-[#CECEEA] p-[2px] sm:p-[5px]'>
                        <div className='bg-[#4CB9390D] w-full h-full flex flex-col-reverse sm:flex-row  justify-center items-center sm:gap-[6px]'>
                            <div className='w-[15px] sm:w-auto'>
                                <WorkfastSvg size={position >= 645 ? "25" : "15"} />
                            </div>
                            <div className='text-[#000000] text-[12px] sm:text-[16px] font-bold'>Workfast.ai</div>
                        </div>
                    </div>
                </div>
                {list?.map((each, index) => (
                    <div key={index} className={`grid grid-cols-4 border-b-[1px] border-x-[1px] border-[#CECEEA4D] w-full ${list.length - 1 === index ? "rounded-b-[12px]" : ""}`}>
                        <div className='col-span-2 p-[10px] sm:p-[20px]'>
                            <div className='text-[#24223E] font-semibold text-[10px] sm:text-[15px]'>{each.title}</div>
                        </div>
                        <div className='border-l-[1px] border-[#CECEEA4D] p-[2px] sm:p-[5px]'>
                            <div className={`flex justify-center items-center w-full h-full ${each.jira === "YES" ? "bg-[#4CB9390D]" : "bg-[#F002500D]"}`}>{each.jira === "YES" ? <TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#4CB939]' /> : <TbCircleMinus TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#F00250]' />}</div>
                        </div>
                        <div className='border-l-[1px] border-[#CECEEA4D] p-[2px] sm:p-[5px]'>
                            <div className={`flex justify-center items-center w-full h-full ${each.workfast === "YES" ? "bg-[#4CB9390D]" : "bg-[#F002500D]"}`}>{each.workfast === "YES" ? <TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#4CB939]' /> : <TbCircleMinus TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#F00250]' />}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}