import React from 'react'

export default function SpacesFolderManagement() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Spaces & Folder Management</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Project/project19.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Keep your projects and tasks neatly organized using “Spaces” and “Folders.” Create and manage spaces for different projects or teams, and use folders to categorize tasks and documents. This structure helps you easily navigate and find what you need. Start organizing with spaces and folders to streamline your workflow and enhance productivity!</p>
            <div className='mt-[22px] flex flex-col gap-[15px]'>
                <div className='flex gap-[8px] items-start'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold shrink-0'>1</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Organize projects within spaces and folders for better structure and management.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
