import React from 'react'

export default function CreateFirstChannel() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Create Your First Channel</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Onboard/onboard7.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Channels in Workspace help organize conversations, by specific topics, teams, or departments. Ready to create your own channel. Follow this simple steps to set up your new channel in just a few minutes.</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[3px]'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Enter Your Channel Name</p>
                </div>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[3px]'>2</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Click Continue for next step</p>
                </div>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[3px]'>3</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Click 'Skip for now' to skip this step and create your channel later.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
