import React from 'react'

export default function CreateYourProfile() {
    return (
        <div className='flex flex-col gap-[11px] justify-center'>
            <h3 className='text-[#000000] text-[18px] font-bold'>Create Your Profile</h3>
            <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Onboard/onboard5.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
            <div className='mt-[6px]'>
                <p className='text-[#000000] text-[14px] font-medium'>Set up your profile to enjoy a seamless and personalized experience on Workfast!</p>
                <div className='mt-[11px] flex flex-col gap-[7px]'>
                    <div className='flex gap-[4px] items-center'>
                        <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[3px]'>1</div>
                        <p className='text-[#000000] text-[14px] font-normal'>Add Your Profile Picture (Optional)</p>
                    </div>
                    <div className='flex gap-[4px] items-center'>
                        <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[3px]'>2</div>
                        <p className='text-[#000000] text-[14px] font-normal'>Enter your Name</p>
                    </div>
                    <div className='flex gap-[4px] items-center'>
                        <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[3px]'>3</div>
                        <p className='text-[#000000] text-[14px] font-normal'>Enter Your Designation</p>
                    </div>
                    <div className='flex gap-[4px] items-center'>
                        <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[3px]'>4</div>
                        <p className='text-[#000000] text-[14px] font-normal'>Click Continue to Proceed</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
