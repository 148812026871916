import React from 'react'
import banner from "../../../../../images/confidential/milestone.webp"
import { BuildSpanSvg } from '../svg';

export default function Milestone() {
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    return (
        <div className='flex flex-col justify-center items-center mt-[120px] px-[16px] sm:px-0 relative z-[1]'>
            <div className='relative'>
                <h3 className='text-[#180833] font-bold text-[24px] sm:text-[32px] lg:text-[42px] text-center relative z-[1]'>Long-Term Milestone</h3>
                <div className={`absolute top-[3px] sm:top-1/2 sm:-translate-y-1/2 -left-[30px] sm:-left-[30px] z-0`}>
                    <BuildSpanSvg colour="#FF9500" width={position >= 1024 ? "262" : "182"} height={position >= 1024 ? "54" : position >= 645 ? "40" : "33"} />
                </div>
            </div>
            <p className='text-[#333333] text-[19px] sm:text-[20px] lg:text-[26px] leading-[33px] sm:leading-[36px] lg:leading-[42px] font-medium w-full sm:w-10/12 xl:w-[60%] text-start sm:text-center mt-[8px]'>Achieve a multi-billion-dollar valuation with 10 million global users, positioning Workfast.ai as a leader in productivity solutions.</p>
            <img src={banner} alt='banner' className='mt-[35px] sm:w-[50%]' loading='lazy'/>
        </div>
    )
}
