import React from 'react'

export default function PeopleSearch() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>People Search</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Search/search5.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Quickly locate colleagues and collaborators using the “People Search” feature. Enter a name or role to find team members across your workspace instantly.</p>
        </div>
    </div>
  )
}
