import React from 'react'
import pendingInvite from '../../../../../../../images/userguide/chat-voice-record.webp';

export default function RecordVoiceMessages() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Record Voice Messages</h3>
        <img src={`${pendingInvite}`} alt='voice' className='rounded-[12px]' loading='lazy'/>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Communicate quickly and clearly by sending audio messages directly in the chat. Tap the “Record” button to capture and send your voice message. Audio messages are great for sharing detailed information or adding a personal touch. Start using audio messages to make your communication more dynamic and engaging!</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Capture and send voice messages for quick communication.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
