import React from 'react'
import { IoCheckboxOutline } from "react-icons/io5";

export default function Roles() {
    return (
        <div className='flex flex-col justify-center items-center w-10/12 relative z-[1] mx-auto mb-[20px] sm:mb-[60px] xl:mb-[100px]'>
            <h2 className='text-[20px] md:text-[26px] lg:text-[32px] xl:text-[40px] text-[#000000] font-bold relative z-[1]'>What are the roles in product management?</h2>
            <p className='text-[#252525] font-normal text-[16px] md:text-[18px] lg:text-[24px] xl:text-[32px] mt-[20px] lg:mt-[40px] mx-auto sm:text-center'>Generally the following roles are used in different companies across world. </p>
            <p className='text-[#252525] font-normal text-[16px] md:text-[18px] lg:text-[24px] xl:text-[32px] mt-[10px] lg:mt-[20px] sm:ml-[20px] lg:ml-[40px] xl:ml-[15px] text-start w-full xl:w-10/12'>They are :</p>
            <div className='mt-[20px] sm:mt-[40px] lg:w-[94%] xl:w-10/12 lg:grid lg:grid-cols-2 gap-[60px] relative'>
                <div className='w-full md:w-[490px] rounded-[20px] sm:rounded-[34px] p-[1px] bg-[#CECEEACC]'>
                    <div className='w-full h-full rounded-[20px] sm:rounded-[34px] bg-[#FFF] p-[5px] sm:p-[10px]'>
                        <div className='w-full h-full rounded-[16px] sm:rounded-[30px] bg-[#F4F4F4] p-[15px] sm:p-[20px] border-[1px] border-[#CECEEA] flex flex-col gap-[20px]'>
                            <div className='flex gap-2 items-center'>
                                <IoCheckboxOutline className='text-[14px] md:text-[20px] lg:text-[24px] text-[#FFDD09]' />
                                <p className='text-[#000000] text-[14px] md:text-[20px] lg:text-[24px] font-normal'>Chief product officer</p>
                            </div>
                            <div className='flex gap-2 items-center'>
                                <IoCheckboxOutline className='text-[14px] md:text-[20px] lg:text-[24px] text-[#FFDD09]' />
                                <p className='text-[#000000] text-[14px] md:text-[20px] lg:text-[24px] font-normal'>Director of product management </p>
                            </div>
                            <div className='flex gap-2 items-center'>
                                <IoCheckboxOutline className='text-[14px] md:text-[20px] lg:text-[24px] text-[#FFDD09]' />
                                <p className='text-[#000000] text-[14px] md:text-[20px] lg:text-[24px] font-normal'>Product manager</p>
                            </div>
                            <div className='flex gap-2 items-center'>
                                <IoCheckboxOutline className='text-[14px] md:text-[20px] lg:text-[24px] text-[#FFDD09]' />
                                <p className='text-[#000000] text-[14px] md:text-[20px] lg:text-[24px] font-normal'>Product owner</p>
                            </div>
                            <div className='flex gap-2 items-center'>
                                <IoCheckboxOutline className='text-[14px] md:text-[20px] lg:text-[24px] text-[#FFDD09]' />
                                <p className='text-[#000000] text-[14px] md:text-[20px] lg:text-[24px] font-normal'>Product marketing manager</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-[180px] xl:w-[380px] h-[150px] xl:h-[100px] rounded-br-[20px] border-r-[1px] border-b-[1px] border-[#18599F]/[60%] absolute top-0 right-[50px] hidden lg:block'></div>
            </div>
        </div>
    )
}