import React from 'react'

export default function ChannelSearch() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Channels Search</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Search/search3.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Quickly locate the channels you need by using the “Channel Search” feature. Enter channel names or keywords to find relevant conversations, updates, and discussions in seconds.</p>
        </div>
    </div>
  )
}
