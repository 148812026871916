import React from 'react'

export default function CreateYourProfile() {
    return (
        <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
            <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Create Your Profile</h3>
            <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Onboard/onboard5.mov" autoPlay playsInline loop muted className=' xl:w-[706px] xl:h-[400px] rounded-[12px]'></video>
            <div className='mt-[10px]'>
                <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Set up your profile to enjoy a seamless and personalized experience on Workfast!</p>
                <div className='mt-[22px] flex flex-col gap-[15px]'>
                    <div className='flex gap-[8px] items-center'>
                        <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>1</div>
                        <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Add Your Profile Picture (Optional)</p>
                    </div>
                    <div className='flex gap-[8px] items-center'>
                        <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>2</div>
                        <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Enter your Name</p>
                    </div>
                    <div className='flex gap-[8px] items-center'>
                        <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>3</div>
                        <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Enter Your Designation</p>
                    </div>
                    <div className='flex gap-[8px] items-center'>
                        <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>4</div>
                        <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Click Continue to Proceed</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
