import React from 'react'
import { useNavigate } from 'react-router-dom';
import one from "../../../../images/investors/investors-1.webp";
import two from "../../../../images/investors/investors-2.webp";
import three from "../../../../images/investors/investors-3.webp";
import four from "../../../../images/investors/investors-4.webp";
import confetti from "../../../../images/investors/confetti.webp";





export default function LastSection() {
    const navigate = useNavigate();
    return (
        <div className="h-full w-full flex flex-col items-center relative overflow-hidden px-[20px] sm:px-[40px] gap-[40px] sm:gap-[70px] lg:gap-[140px]">
            <h2 className='text-[20px]  sm:text-[30px] xl:text-[45px] text-[#060606] font-semibold'>Together we will create a history</h2>
            <div className='w-11/12 mx-auto flex justify-between relative p-[30px]'>
                <img src={confetti} alt='confetti' className='absolute inset-[0px] z-[0] w-[80%]' loading='lazy' />
                <img src={one} alt='workfast' className='relative z-[1] investors1 w-[25%]' loading='lazy' />
                <img src={four} alt='intelligent' className='relative z-[1] investors2  w-[25%]' loading='lazy' />
                <img src={two} alt='task' className='relative z-[1] investors2  w-[25%]' loading='lazy' />
                <img src={three} alt='management' className='relative z-[1] investors1  w-[25%]' loading='lazy' />
            </div>
            <button className='w-[200px] h-[38px] sm:h-[44px] lg:w-[570px] lg:h-[50px] rounded-[18px] bg-gradient-to-bl from-[#FDD01F] via-[#FDD320] to-[#F8861B] b3 relative mb-[40px] lg:mb-[60px] xl:mb-[100px]' onClick={() => { navigate('/investor/form') }}>
                <span className='text text-[#2A2200] text-[12px] lg:text-[16px] font-semibold'>Apply for Investment</span>
                <span class="shimmer2"></span>
            </button>
        </div>
    )
}
