import React from 'react'

export default function PrivateChannel() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Private Channel</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Channel/channel5.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>A private channel is perfect for secure, focused conversations among selected members. Only those invited can view or join, ensuring privacy and exclusivity. Set your channel to "Private" in the settings to control access and keep discussions confidential.</p>
            <div className='mt-[22px] flex flex-col gap-[15px]'>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>1</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Only invited members can join and participate in the chat.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
