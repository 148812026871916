import React from 'react'
import Desktop from './desktop';
import Mobile from './mobile';

export default function Banner() {
  const [position, setPosition] = React.useState(window.innerWidth <= 425);
    
    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth <= 425);
        };
    }, []);
  return (
    position ? (<Mobile/>) : (<Desktop/>)
  )
}
