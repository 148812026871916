import React from 'react'

export default function GroupChat() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Group Chat</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Chat/chat3.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Group chat in Workfast allows teams to communicate and collaborate in real-time within specific groups. It enables easy sharing of ideas, updates, and files, making team discussions more organised and efficient.</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Create and participate in group chats for team collaboration.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
