import React from 'react'

export default function InviteTeamMember() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Invite Team Members via Email</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/People/people2.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Inviting team members via email in Workfast is a quick and effective way to bring new collaborators into your workspace.</p>
            <div className='mt-[22px] flex flex-col gap-[15px]'>
                <div className='flex gap-[8px] items-start'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold shrink-0'>1</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Enter your colleague's email address for inviting them via mail</p>
                </div>
                <div className='flex gap-[8px] items-start'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold shrink-0'>2</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Invite as Admin, Member, Guest or Customer to control their access</p>
                </div>
                <div className='flex gap-[8px] items-start'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold shrink-0'>3</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Click Next to Invite</p>
                </div>
            </div>
        </div>
    </div>
  )
}
