import React from 'react'

export default function Search() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Search</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Search/search2.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Locate tasks, projects, files, or team members instantly using the “Search” feature. Enter keywords to quickly find relevant items across your entire workspace.</p>
        </div>
    </div>
  )
}
