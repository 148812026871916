import React from 'react'
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa6";
import API from '../../../../../utils/API';
import { AdminDashboardSvg, AlertSvg, ChannelSvg, ChatSvg, OnboardingSvg, PeopleSvg, ProfileSvg, ProjectSvg, ReportSvg, TaskSvg, UniversalSearchSvg } from '../svg';





export default function Desktop({setPositions}) {
    const navigate = useNavigate();
    const location = useLocation();
    const [isUserGuide, setIsUserGuide] = React.useState(false);

    const [position, setPosition] = React.useState(window.innerWidth);
    
    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    const handleLiginClick = async (e) => {
        e.preventDefault();
        const response = await axios.post(API.BUTTON,
            {
                "eventName": "LOG_IN_FOR_FREE_MAIN"
            }
        );
        if (response.data.statusCode === 200) {
            window.location.href = 'https://app.workfast.ai/';
        }
    };

    React.useEffect(() => {
        if((location?.pathname === "/blog" || location?.pathname === "/blog/breaking-barriers-in-communication-collaboration-with-workfast.ai" || location?.pathname === "/blog/top-7-ways-workfast.ai-can-revolutionise-your-project-management" || location?.pathname === "/blog/looking-for-a-new-platform-for-project-management" ) && isUserGuide ) {
            setPositions(true)
        } else {
            setPositions(false)
        }
    },[location, isUserGuide])
    

    return (
        <div className={`flex justify-between items-center h-[80px] px-[30px] w-full mx-auto lg:px-[60px] pt-[23px] pb-[8px] z-[55] ${isUserGuide ? "fixed" : "relative"}`}>
            <a title='Workfast' href='/'><div className='px-[8px] lg:px-[12px] py-[12px] lg:py-[8px] border border-[#CECEEA] rounded-[16px] relative z-[20] bg-white' style={{ boxShadow: '0px 4px 7px 0px #432E860A' }}><img src="https://dz1x1c630cl14.cloudfront.net/webassets/whiteThemeLogo.svg" alt='logo' loading="lazy" className='w-[100px] lg:w-auto'/></div></a>
            <div className='flex px-[8px] lg:px-[21px] lg:py-[5px] rounded-[16px] border-[1px] border-[#CECEEA] bg-white relative z-[20]' style={{ boxShadow: '0px 4px 7px 0px #432E860A' }}>
                {/* <div className='px-[8px] lg:px-[14px] py-[12px] lg:py-[8px]'><div className={`font-normal text-[13px] lg:text-[16px] cursor-pointer ${location?.pathname === "features" ? "text-[#FDD01F]" : "text-[#24223E]"}`} onClick={(e) => { navigate('/blog') }}>Features</div></div> */}
                <div className='px-[8px] lg:px-[14px] py-[12px] lg:py-[8px] relative flex gap-[6px] items-center cursor-pointer' onClick={(e) => {setIsUserGuide(!isUserGuide)}}><div className={`text-[13px] lg:text-[16px] cursor-pointer duration-500 transition-all ${isUserGuide ? "text-[#FDD01F] font-semibold" : "text-[#24223E] font-normal"}`}>User Guide</div><FaAngleDown className={`font-normal text-[13px] lg:text-[16px] cursor-pointer transition-all duration-500 ${isUserGuide ? "text-[#FDD01F] -rotate-180" : "text-[#24223E]"}`}/></div>
                <div className='px-[8px] lg:px-[14px] py-[12px] lg:py-[8px]'><div className={`text-[13px] lg:text-[16px] cursor-pointer ${location?.pathname === "/blog" ? "text-[#FDD01F] font-semibold" : "text-[#24223E] font-normal"}`} onClick={(e) => { navigate('/blog') }}>Blog</div></div>
                <div className='px-[8px] lg:px-[14px] py-[12px] lg:py-[8px]'><div className={`text-[13px] lg:text-[16px] cursor-pointer ${location?.pathname === "/pricing" ? "text-[#FDD01F] font-semibold" : "text-[#24223E] font-normal"}`} onClick={() => { navigate('/pricing') }}>Pricing</div></div>
                <div className='px-[8px] lg:px-[14px] py-[12px] lg:py-[8px]'><div className={`text-[13px] lg:text-[16px] cursor-pointer ${location?.pathname === "/investor" ? "text-[#FDD01F] font-semibold" : "text-[#24223E] font-normal"}`} onClick={(e) => { navigate('/investor') }}>Investor</div></div>
                <div className='px-[8px] lg:px-[14px] py-[12px] lg:py-[8px]'><div className={`text-[13px] lg:text-[16px] cursor-pointer ${location?.pathname === "/partnership-program" ? "text-[#FDD01F] font-semibold" : "text-[#24223E] font-normal"}`} onClick={(e) => { navigate('/partnership-program') }}>Reseller</div></div>
            </div>
            <div className='p-[4px] rounded-[16px] flex gap-[10px] items-center bg-[#FFF] border border-[#CECEEA] relative z-[20]'>
                <button className='py-[8px] lg:py-[10px] px-[14px] text-[#24223E] text-[13px] lg:text-[16px] font-medium' onClick={(e) => {handleLiginClick(e)}}>Log in</button>
                <button className='py-[8px] lg:py-[10px] px-[14px] text-[#FFF] text-[13px] lg:text-[16px] font-bold rounded-[10px] bg-gradient-to-r from-[#FF4E50]  to-[#F9D423]' onClick={() => {window.location.href = '/book-for-demo';}}>Book a Demo</button>
            </div>
            {/* {isUserGuide && ( */}
                <div className={`${isUserGuide ? "max-h-full" : "max-h-0 z-0 hidden"} transition-all duration-500 fixed top-[100px]`}>
                <div className={`fixed inset-0 bg-[#00000099] transition-all duration-500 ${isUserGuide ? "max-h-full" : "max-h-0"}`} onClick={(e) => {setIsUserGuide(!isUserGuide)}}></div>
                    <div className={`bg-[#FFF] relative rounded-[22px] transition-all duration-500 max-w-[710px] lg:max-w-[844px] xl:max-w-[1186px] ${isUserGuide ? "max-h-full z-[20] p-[30px] xl:p-[42px]" : "max-h-0 opacity-0 z-0 p-0"}`}>
                        <div className='flex gap-[24px] lg:gap-[30px] xl:gap-[42px] justify-start items-center flex-wrap'>
                            <div className='p-[10px] rounded-[12px] border border-[#A8A8A8] max-w-[200px] lg:max-w-[241px] cursor-pointer' onClick={() => {navigate('/user-guide-onboarding')}}>
                                <div className='flex gap-[4px] items-center'>
                                    <OnboardingSvg size={position >= 1024 ? "20" : "15"}/>
                                    <div className='text-[14px] lg:text-[18px] text-[#FF3B30] font-semibold'>Onboarding</div>
                                </div>
                                <div className='text-[#000000] text-[12px] lg:text-[16px] font-normal'>Set up and start using your account quickly.</div>
                            </div>
                            <div className='p-[10px] rounded-[12px] border border-[#A8A8A8] max-w-[200px] lg:max-w-[241px] cursor-pointer' onClick={() => {navigate('/user-guide-people')}}>
                                <div className='flex gap-[4px] items-center'>
                                    <PeopleSvg size={position >= 1024 ? "20" : "15"}/>
                                    <div className='text-[14px] lg:text-[18px] text-[#FF9500] font-semibold'>People</div>
                                </div>
                                <div className='text-[#000000] text-[12px] lg:text-[16px] font-normal'>Easily manage and assign team roles.</div>
                            </div>
                            <div className='p-[10px] rounded-[12px] border border-[#A8A8A8] max-w-[200px] lg:max-w-[241px] cursor-pointer' onClick={() => {navigate('/user-guide-channel')}}>
                                <div className='flex gap-[4px] items-center'>
                                    <ChannelSvg size={position >= 1024 ? "20" : "15"}/>
                                    <div className='text-[14px] lg:text-[18px] text-[#FFCC00] font-semibold'>Channels</div>
                                </div>
                                <div className='text-[#000000] text-[12px] lg:text-[16px] font-normal'>Create and manage channels for team communication.</div>
                            </div>
                            <div className='p-[10px] rounded-[12px] border border-[#A8A8A8] max-w-[200px] lg:max-w-[241px] cursor-pointer' onClick={() => {navigate('/user-guide-chat')}}>
                                <div className='flex gap-[4px] items-center'>
                                    <ChatSvg size={position >= 1024 ? "20" : "15"}/>
                                    <div className='text-[14px] lg:text-[18px] text-[#34C759] font-semibold'>Chat</div>
                                </div>
                                <div className='text-[#000000] text-[12px] lg:text-[16px] font-normal'>Instantly chat and collaborate seamlessly.</div>
                            </div>
                            <div className='p-[10px] rounded-[12px] border border-[#A8A8A8] max-w-[200px] lg:max-w-[241px] cursor-pointer' onClick={() => {navigate('/user-guide-task')}}>
                                <div className='flex gap-[4px] items-center'>
                                    <TaskSvg size={position >= 1024 ? "20" : "15"}/>
                                    <div className='text-[14px] lg:text-[18px] text-[#00C7BE] font-semibold'>Tasks</div>
                                </div>
                                <div className='text-[#000000] text-[12px] lg:text-[16px] font-normal'>Create, assign, and track tasks effortlessly.</div>
                            </div>
                            <div className='p-[10px] rounded-[12px] border border-[#A8A8A8] max-w-[200px] lg:max-w-[241px] cursor-pointer' onClick={() => {navigate('/user-guide-project')}}>
                                <div className='flex gap-[4px] items-center'>
                                    <ProjectSvg size={position >= 1024 ? "20" : "15"}/>
                                    <div className='text-[14px] lg:text-[18px] text-[#30B0C7] font-semibold'>Projects</div>
                                </div>
                                <div className='text-[#000000] text-[12px] lg:text-[16px] font-normal'>Create, manage, and track projects easily.</div>
                            </div>
                            <div className='p-[10px] rounded-[12px] border border-[#A8A8A8] max-w-[200px] lg:max-w-[241px] cursor-pointer' onClick={() => {navigate('/user-guide-report')}}>
                                <div className='flex gap-[4px] items-center'>
                                    <ReportSvg size={position >= 1024 ? "20" : "15"}/>
                                    <div className='text-[14px] lg:text-[18px] text-[#32ADE6] font-semibold'>Reports</div>
                                </div>
                                <div className='text-[#000000] text-[12px] lg:text-[16px] font-normal'>Generate and analyze reports with ease.</div>
                            </div>
                            <div className='p-[10px] rounded-[12px] border border-[#A8A8A8] max-w-[200px] lg:max-w-[241px] cursor-pointer' onClick={() => {navigate('/user-guide-notification')}}>
                                <div className='flex gap-[4px] items-center'>
                                    <AlertSvg size={position >= 1024 ? "20" : "15"}/>
                                    <div className='text-[14px] lg:text-[18px] text-[#007AFF] font-semibold'>Alert</div>
                                </div>
                                <div className='text-[#000000] text-[12px] lg:text-[16px] font-normal'>Receive and manage alerts for important updates.</div>
                            </div>
                            <div className='p-[10px] rounded-[12px] border border-[#A8A8A8] max-w-[200px] lg:max-w-[241px] cursor-pointer' onClick={() => {navigate('/user-guide-universal-search')}}>
                                <div className='flex gap-[4px] items-center'>
                                    <UniversalSearchSvg size={position >= 1024 ? "20" : "15"}/>
                                    <div className='text-[14px] lg:text-[18px] text-[#5856D6] font-semibold'>Universal Search</div>
                                </div>
                                <div className='text-[#000000] text-[12px] lg:text-[16px] font-normal'>Quickly find tasks, projects, and more.</div>
                            </div>
                            <div className='p-[10px] rounded-[12px] border border-[#A8A8A8] max-w-[200px] lg:max-w-[241px] cursor-pointer' onClick={() => {navigate('/user-guide-profile')}}>
                                <div className='flex gap-[4px] items-center'>
                                    <ProfileSvg size={position >= 1024 ? "20" : "15"}/>
                                    <div className='text-[14px] lg:text-[18px] text-[#FF2D55] font-semibold'>My Profile</div>
                                </div>
                                <div className='text-[#000000] text-[12px] lg:text-[16px] font-normal'>Update and manage your profile settings.</div>
                            </div>
                            <div className='p-[10px] rounded-[12px] border border-[#A8A8A8] max-w-[200px] lg:max-w-[241px] cursor-pointer' onClick={() => {navigate('/user-guide-admin-dashboard')}}>
                                <div className='flex gap-[4px] items-center'>
                                    <AdminDashboardSvg size={position >= 1024 ? "20" : "15"}/>
                                    <div className='text-[14px] lg:text-[18px] text-[#A2845E] font-semibold'>Admin Dashboard</div>
                                </div>
                                <div className='text-[#000000] text-[12px] lg:text-[16px] font-normal'>Manage users, settings, and data easily.</div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* )} */}
        </div>
    )
}
