import React from 'react'

export default function PersonalTasks() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Personal Tasks</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Task/task4.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Create personal to-do lists that are independent of project tasks.</p>
        </div>
    </div>
  )
}
