import React from 'react'
import background from "../../../../../../images/userguide-mobile-bg.svg"
import Logo from "../../../../../../images/userguide-icon.svg";

export default function Mobile() {
  const backgroundRef = React.useRef(null);
  const [backgroundHeight, setBackgroundHeight] = React.useState(null);

  const handleImageLoad = () => {
    if (backgroundRef.current) {
      setBackgroundHeight(backgroundRef.current.clientHeight);
    }
  };
  return (
    <div className='relative w-full z-[21]'>
      <img src={background} ref={backgroundRef} alt="people" loading='lazy' className='absolute z-0 w-full' style={{ boxShadow: '2px 0px 4px 0px #00000040' }} onLoad={handleImageLoad} />
      <div className='px-[20px] relative z-[1] flex justify-center flex-col' style={{ height: `${backgroundHeight}px` }}>
        <img src={Logo} alt='workfast' loading='lazy' width={133} height={18} />
        <h1 className='text-[#000000] text-[23px] font-bold'>User Guide</h1>
        <h2 className='text-[#000000] text-[10px] font-medium'>People</h2>
      </div>
    </div>
  )
}
