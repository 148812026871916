import React from 'react'
import { IoIosArrowRoundForward } from 'react-icons/io'
import { useNavigate } from 'react-router-dom';

export default function Programs() {
    const navigate = useNavigate();
  return (
    <div className='flex flex-col justify-center items-center gap-[60px]'>
        <div className='grid sm:grid-cols-2 gap-[44px] items-center w-11/12 lg:w-10/12 mx-auto'>
            <div className='p-[20px] sm:p-[34px] bg-[#F5F2EB] rounded-[13px] w-full'>
                <h3 className='text-[#181F38] text-[20px] sm:text-[25px] font-bold'>Affiliate Partnership Program</h3>
                <h4 className='text-[#2B3857] text-[14px] sm:text-[19px] font-normal mt-[10px]'>Sign up for Workfast.ai Affiliate program and recommend our platform to prospects and clients. This program is suitable for Individuals who have very good professional networks and to consultants of any degree.</h4>
                <div className='flex gap-[10px] items-center text-[16px] sm:text-[22px] font-medium text-[#624500] mt-[17px] sm:mt-[73px] xl:mt-[53px] cursor-pointer bg-[#F7E1AF] hover:bg-[#F9C240] py-[10px] px-[20px] rounded-[14px] w-[190px]' onClick={() => {navigate('/partnership-program/affiliate-form')}}>
                    <h4>Apply now</h4>
                    <IoIosArrowRoundForward className='mt-[3px]'/>
                </div>
            </div>
            <div className='p-[20px] sm:p-[34px] bg-[#F5F2EB] rounded-[13px] w-full'>
                <h3 className='text-[#181F38] text-[20px] sm:text-[25px] font-bold'>Reseller Partnership Program</h3>
                <h4 className='text-[#2B3857] text-[14px] sm:text-[19px] font-normal mt-[10px]'>Sign up for Workfast.ai Reseller Partnership program and Recommend to Prospects and companies in your network . This program is suitable for Agencies & Consultants who have in-house workforce to manage and convert the leads and Prospects. Get all support related to product .</h4>
                <div className='flex gap-[10px] items-center text-[16px] sm:text-[22px] font-medium text-[#624500] mt-[17px] cursor-pointer bg-[#F7E1AF] hover:bg-[#F9C240] py-[10px] px-[20px] rounded-[14px] w-[190px]' onClick={() => {navigate('/partnership-program/reseller-form')}}>
                    <h4>Apply now</h4>
                    <IoIosArrowRoundForward className='mt-[3px]'/>
                </div>
            </div>
        </div>
        <div className='bg-[#FFFDF6] w-full py-[32px] sm:py-[52px] flex flex-col justify-center items-center px-[20px] sm:px-0'>
            <h3 className='text-[#24223E] text-[24px] sm:text-[30px] xl:text-[42px] font-semibold text-center'>Do collective work, get collective result with Workfast.ai</h3>
            <h4 className='text-[#333333] text-[14px] sm:text-[18px] font-normal text-center'>Ready to see how Workfast.ai improves alignment across teams?</h4>
            <button className='text-[#2A2200] text-[14px] sm:text-[18px] lg:text-[21px] font-semibold px-[30px] py-[14px] lg:px-[38px] lg:py-[18px] bg-gradient-to-bl from-[#FDD01F] via-[#FDD320] to-[#F8861B] rounded-[11px] relative z-[10] border border-[#FFF] mt-[20px]' onClick={() => {navigate('/book-for-demo')}}>Try it Free</button>
        </div>
    </div>
  )
}
