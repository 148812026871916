import React from 'react'
import LookingForANewPlatform from './looking-for-a-new-platform';
import WorkfastAi from './workfast.ai';
import CoreFeaturesOfWorkfastAi from './core-features-of-workfast.ai';
import UniqueFeatures from './unique-features';
import WhyWorkfastAiIsCreated from './why-workfast.ai-is-created';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import { FaAngleRight } from "react-icons/fa";
import { CheckImgSvg } from '../../svg';


export default function Content() {
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = React.useState(null);

    // Update active section on scroll
    const handleSetActive = (to) => {
        setActiveSection(to);
    };

    const tocData = [
        { title: 'Looking for a new platform for Project Management', id: 'looking-for-a-new-platform' },
        { title: 'What is Workfast.ai ?', id: 'workfast.ai' },
        { title: 'Core features of Workfast.ai', id: 'core-features-of-workfast.ai' },
        { title: 'Unique features that will excites the users while using Workfast.ai', id: 'unique-features' },
        { title: 'Why Workfast.ai is created?', id: 'why-workfast.ai-is-created' },
    ];

    return (
        <div className='sm:flex px-[20px] md:px-0 md:w-[88%] mx-auto gap-[40px] relative z-[1] mb-[20px] sm:mb-[60px] lg:mb-[100px]'>
            <div className='flex flex-col gap-[15px] md:gap-[30px] lg:gap-[50px] w-full md:w-[65%] overflow-y-auto'>
                <div id='looking-for-a-new-platform'><LookingForANewPlatform /></div>
                <div id='workfast.ai'><WorkfastAi /></div>
                <div id='core-features-of-workfast.ai'><CoreFeaturesOfWorkfastAi /></div>
                <div id='unique-features'><UniqueFeatures /></div>
                <div id='why-workfast.ai-is-created'><WhyWorkfastAiIsCreated /></div>
            </div>
            <aside className='sticky right-0 top-[40px] self-start w-[35%] rounded-[8px] hidden md:block'>
                <h3 className='text-[#000000] text-[18px] lg:text-[22px] font-medium pl-[9px]'>Table of contents</h3>
                <ul className={`mt-[10px]`} data-hs-scrollspy="#scrollspy-2" data-hs-scrollspy-scrollable-parent="#scrollspy-scrollable-parent-2">
                    {tocData.map((section, index) => (
                        <li key={section.id}>
                            <Link
                                activeClass="" // Active link style
                                to={section.id}
                                spy={true}
                                smooth={true}
                                duration={100}
                                className={`flex gap-[10px] lg:gap-[17px] items-start p-[9px] rounded-[6px] cursor-pointer ${activeSection === section.id ? "bg-[#FFF5C9]" : "bg-transparent"}`}
                                onSetActive={handleSetActive}
                            >
                                <FaAngleRight className='mt-[2px] lg:mt-[4px] xl:mt-[6px] text-[#000000] text-[16px] lg:text-[20px]' />
                                <h3 className='text-[#000000] text-[16px] lg:text-[20px] font-medium'>{section.title}</h3>
                            </Link>
                        </li>
                    ))}
                </ul>
                <div className='mt-[40px] border-t border-[#A8A8A8] pt-[31px] flex flex-col gap-[12px]'>
                    <h3 className='text-[#000000] font-extrabold text-[17px]'>Create your own workspace in  Workfast today</h3>
                    <div className='flex flex-col gap-[10px]'>
                        <div className='flex gap-[8px] items-center text-[14px] font-normal text-[#252525]'>
                            <CheckImgSvg />
                            <h4>Communicate with your team</h4>
                        </div>
                        <div className='flex gap-[8px] items-center text-[14px] font-normal text-[#252525]'>
                            <CheckImgSvg />
                            <h4>Add and complete Tasks</h4>
                        </div>
                        <div className='flex gap-[8px] items-center text-[14px] font-normal text-[#252525]'>
                            <CheckImgSvg />
                            <h4>Engage with your team</h4>
                        </div>
                    </div>
                    <button className='bg-gradient-to-l from-[#FDD01F] via-[#FDD320] to-[#F8861B] w-9/12 py-[13px] rounded-[14px] text-[#221B00] text-[16px] font-semibold' onClick={() => { navigate('/book-for-demo') }}>Get Started</button>
                </div>
            </aside>
        </div>
    )
}
