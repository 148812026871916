import React from 'react'
import { AiSvg, BuildSpanSvg, GlobalSvg, GrowthSvg, MarketingSvg } from '../svg';

export default function UsesFunds() {
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);
    return (
        <div className='flex flex-col justify-center items-center mt-[120px] px-[16px] sm:px-0 relative z-[1] w-full'>
            <div className='relative'>
                <h3 className='text-[#180833] font-bold text-[24px] sm:text-[32px] lg:text-[42px] text-center relative z-[1]'>Use of Funds</h3>
                <div className={`absolute top-[3px] sm:top-1/2 sm:-translate-y-1/2 -left-[30px] sm:-left-[30px] z-0`}>
                    <BuildSpanSvg colour="#FF2D55" width={position >= 1024 ? "262" : "182"} height={position >= 1024 ? "54" : position >= 645 ? "40" : "33"} />
                </div>
            </div>
            <div className='flex flex-col gap-[14px] sm:gap-[32px] justify-center items-start mt-[32px]'>
                <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-center'>
                    <AiSvg size={position >= 1024 ? "73" : position >= 625 ? "50" : "35"}/>
                    <div>
                        <p className='text-[#181F38] font-medium text-[16px] sm:text-[22px] lg:text-[28px]'>AI Agent Development</p>
                    </div>
                </div>
                <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-center'>
                    <MarketingSvg size={position >= 1024 ? "73" : position >= 625 ? "50" : "35"}/>
                    <div>
                        <p className='text-[#181F38] font-medium text-[16px] sm:text-[22px] lg:text-[28px]'>Marketing & Customer Acquisition</p>
                    </div>
                </div>
                <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-center'>
                    <GlobalSvg size={position >= 1024 ? "73" : position >= 625 ? "50" : "35"}/>
                    <div>
                        <p className='text-[#181F38] font-medium text-[16px] sm:text-[22px] lg:text-[28px]'>Expansion into U.S. and global markets</p>
                    </div>
                </div>
                <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-center'>
                    <GrowthSvg size={position >= 1024 ? "73" : position >= 625 ? "50" : "35"}/>
                    <div>
                        <p className='text-[#181F38] font-medium text-[16px] sm:text-[22px] lg:text-[28px]'>Team Growth</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
