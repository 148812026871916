import React from 'react'

export default function RecentSearches() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Recent Searches</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Search/search1.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Quickly revisit previous queries with the “Recent Searches” feature. Easily access your search history to find tasks, projects, or files you’ve recently looked up.</p>
        </div>
    </div>
  )
}
