import React from 'react'

export default function Inbox() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Inbox</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Task/task1.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Plan checklist items for daily tasks such as meetings, follow-ups, and personal reminders.</p>
        </div>
    </div>
  )
}
