import React from 'react'
import BreakinBarriers from './breaking_barriers';
import AllInOne from './all_in__one';
import AiInteligence from './ai_inteligence';
import EmailCalender from './email_callender';
import Organisation from './organisation';
import DailyReport from './daily_report';
import Integration from './integration';
import Workflow from './workflow';
import GoalProject from './goal';
import Automation from './automation';
import UniversalSearch from './universal_search';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import { FaAngleRight } from "react-icons/fa";
import { CheckImgSvg } from '../../svg';

export default function Content() {
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = React.useState(null);

    // Update active section on scroll
    const handleSetActive = (to) => {
        setActiveSection(to);
    };

    const tocData = [
        { title: 'Breaking Barriers in Communication and Collaboration', id: 'breaking-barriers' },
        { title: 'All-in-one workbase', id: 'all-in-one' },
        { title: 'Automation makes work easier', id: 'automation' },
        { title: 'AI intelligence', id: 'ai-intelligence' },
        { title: 'Email - calendar duo', id: 'email-calendar' },
        { title: 'Organisation chart and reporting', id: 'organisation' },
        { title: 'Daily report generation', id: 'daily-report' },
        { title: 'Integration and personalisation', id: 'integration' },
        { title: 'Workflow Setting', id: 'workflow' },
        { title: 'Universal search', id: 'universal-search' },
        { title: 'Goal - Project - Task linkage', id: 'goal-project' },
    ];

    return (
        <div className='sm:flex px-[20px] md:px-0 md:w-[88%] mx-auto gap-[40px] relative z-[1] mb-[20px] sm:mb-[60px] lg:mb-[100px]'>
            <div className='flex flex-col gap-[24px] md:gap-[30px] lg:gap-[50px] w-full md:w-[65%] overflow-y-auto'>
                <div id='breaking-barriers'><BreakinBarriers /></div>
                <div id='all-in-one'><AllInOne /></div>
                <div id='automation'><Automation /></div>
                <div id='ai-intelligence'><AiInteligence /></div>
                <div id='email-calendar'><EmailCalender /></div>
                <div id='organisation'><Organisation /></div>
                <div id='daily-report'><DailyReport /></div>
                <div id='integration'><Integration /></div>
                <div id='workflow'><Workflow /></div>
                <div id='universal-search'><UniversalSearch /></div>
                <div id='goal-project'><GoalProject /></div>
            </div>
            <aside className='sticky right-0 top-[40px] self-start w-[35%] rounded-[8px] hidden md:block'>
                <h3 className='text-[#000000] text-[18px] lg:text-[22px] font-bold pl-[9px]'>Table of contents</h3>
                <ul className={`mt-[10px]`} data-hs-scrollspy="#scrollspy-2" data-hs-scrollspy-scrollable-parent="#scrollspy-scrollable-parent-2">
                    {tocData.map((section) => (
                        <li key={section.id}>
                            <Link
                                activeClass="" // Active link style
                                to={section.id}
                                spy={true}
                                smooth={true}
                                duration={100}
                                className={`flex gap-[10px] lg:gap-[17px] items-start p-[9px] cursor-pointer rounded-[6px] ${activeSection === section.id ? "bg-[#FFF5C9]" : "bg-transparent"}`}
                                onSetActive={handleSetActive}
                            >
                                <FaAngleRight className='mt-[2px] lg:mt-[4px] xl:mt-[6px] text-[#000000] text-[16px] lg:text-[20px]' />
                                <h3 className='text-[#000000] text-[16px] lg:text-[20px] font-medium'>{section.title}</h3>
                            </Link>
                        </li>
                    ))}
                </ul>
                <div className='mt-[40px] border-t border-[#A8A8A8] pt-[31px] flex flex-col gap-[12px]'>
                    <h3 className='text-[#000000] font-semibold text-[17px]'>Create your own workspace in  Workfast today</h3>
                    <div className='flex flex-col gap-[10px]'>
                        <div className='flex gap-[8px] items-center text-[14px] font-normal text-[#252525]'>
                            <CheckImgSvg />
                            <h4>Communicate with your team</h4>
                        </div>
                        <div className='flex gap-[8px] items-center text-[14px] font-normal text-[#252525]'>
                            <CheckImgSvg />
                            <h4>Add and complete Tasks</h4>
                        </div>
                        <div className='flex gap-[8px] items-center text-[14px] font-normal text-[#252525]'>
                            <CheckImgSvg />
                            <h4>Engage with your team</h4>
                        </div>
                    </div>
                    <button className='bg-gradient-to-l from-[#FDD01F] via-[#FDD320] to-[#F8861B] w-9/12 py-[13px] rounded-[14px] text-[#221B00] text-[16px] font-semibold cursor-pointer' onClick={() => { navigate('/book-for-demo') }}>Get Started</button>
                </div>
            </aside>
        </div>
    )
}
