import React from 'react'
import ManageMembers from './manage-members';
import ChangeAccountType from './change-account-type';
import DeactivateAccount from './deactivate-account';
import EditDeleteMessages from './edit-delete-messages';
import Billing from './billing';


export default function Mobile() {
    return (
        <div className='flex flex-col gap-[16px] relative w-full px-[20px] mt-[12px]'>
            <div className='text-[#000000] text-[15px] font-normal'>
                <p>The "Admin Dashboard" is the central hub for managing your Workfast workspace. As an admin, you have full control over user roles, permissions, and access levels. View detailed analytics. Customize workspace settings to align with your organization’s needs. Track billing, subscription details, and manage invoices effortlessly.</p>
            </div>
            <div id='manage-members'><ManageMembers /></div>
            <div id='change-account-type'><ChangeAccountType /></div>
            <div id='deactivate-account'><DeactivateAccount /></div>
            <div id='edit-delete-messages'><EditDeleteMessages /></div>
            <div id='billing'><Billing /></div>
        </div>
    )
}
