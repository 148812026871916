import React from 'react'
import available_screen from "../../../../../../images/home/available-screen.webp";

export default function WorkFastAi() {
  return (
    <div className='flex flex-col justify-center items-center w-10/12 relative z-[1] mx-auto mb-[20px] sm:mb-[60px] xl:mb-[100px]'>
        <h2 className='text-[20px] md:text-[26px] lg:text-[32px] xl:text-[40px] text-[#000000] font-bold relative z-[1]'>What is Workfast.ai ?</h2>
        <p className='text-[#252525] font-normal text-[14px] md:text-[16px] lg:text-[20px] xl:text-[26px] mt-[20px] md:mt-[40px] sm:w-10/12 mx-auto'>Workfast.ai is a communication and collaboration platform for organising complex work systems and executing with ease which developed to work faster for enterprises.</p>
        <p className='text-[#252525] font-normal text-[14px] md:text-[16px] lg:text-[20px] xl:text-[26px] mt-[10px] md:mt-[20px] sm:w-10/12 mx-auto'>It can be used by any enterprises which has a systemic workflow both in services and product segment. Also it is used by many software development companies , sales and marketing companies , Fintech sectors, Airline sectors , manufacturing sectors , services sector etc.</p>
        <img src={available_screen} alt='laptop' loading="lazy" className='mt-[20px] md:mt-[40px] md:w-[80%]'/>
    </div>
  )
}