import React from 'react'

export default function CreateNewChannel() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Create a New Channel</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Channel/channel1.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Create your own channel by following this simple steps to set up your new channel in just a few minutes.</p>
            <div className='mt-[22px] flex flex-col gap-[15px]'>
                <div className='flex gap-[8px] items-start'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold shrink-0'>1</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Click the "+" button to initiate the creation of a new channel.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
