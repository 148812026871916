import React from 'react'

export default function DeactivateAccount() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Deactivate Account</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Dashboard/dashboard3.mov" autoPlay playsInline loop muted className='rounded-[6px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Need a break or leaving the platform? Easily deactivate user’s account with the “Deactivate Account” option. This action pauses your access and secures your data until you're ready to return. Remember, you can always reactivate whenever you want.</p>
            <div className='mt-[22px] flex flex-col gap-[15px]'>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>1</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Change the account type or Deactivate account</p>
                </div>
            </div>
        </div>
    </div>
  )
}
