import React from 'react'
import { BuildSpanSvg, NumberBgSvg } from '../svg'


export default function FundrasingDetails() {
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    const points = [
        { number: "01", colour: "#00C7BE", },
        { number: "02", colour: "#007AFF", },
        { number: "03", colour: "#5856D6", },
        { number: "04", colour: "#AF52DE", },
    ]
    return (
        <div className='flex flex-col justify-center items-center mt-[120px] px-[16px] sm:px-0 relative z-[1] w-full'>
            <div className='relative'>
                <h3 className='text-[#180833] font-bold text-[24px] sm:text-[32px] lg:text-[42px] text-center relative z-[1]'>Fundraising Details</h3>
                <div className={`absolute top-[3px] sm:top-1/2 sm:-translate-y-1/2 -left-[30px] sm:-left-[30px] z-0`}>
                    <BuildSpanSvg colour="#5856D6" width={position >= 1024 ? "262" : "182"} height={position >= 1024 ? "54" : position >= 645 ? "40" : "33"} />
                </div>
            </div>
            <div className='flex flex-col gap-[14px] sm:gap-[22px] w-full sm:w-10/12 lg:w-8/12 xl:w-7/12 mx-auto mt-[42px]'>
                <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start'>
                    <div className='relative size-[31px] sm:size-[51px] lg:size-[71px] flex justify-start items-center shrink-0'>
                        <div className='absolute left-0 z-[0]'>
                            <NumberBgSvg colour={points[0]?.colour} size={position >= 1024 ? "68" : position >= 645 ? "50" : "35"}/>
                        </div>
                        <div className='text-[#000000] text-[21px] sm:text-[29px] lg:text-[39px] font-medium z-[1] relative'>{points[0].number}</div>
                    </div>
                    <div>
                        <p className='text-[#181F38] font-semibold text-[22px] sm:text-[34px]'>Amount</p>
                        <p className='text-[#2B3857] font-medium text-[20px] sm:text-[26px] mt-[4px] lg:mt-[8px]'>$10 Million (Series A)</p>
                    </div>
                </div>
                <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start'>
                    <div className='relative size-[31px] sm:size-[51px] lg:size-[71px] flex justify-start items-center shrink-0'>
                        <div className='absolute left-0 z-[0]'>
                            <NumberBgSvg colour={points[1]?.colour} size={position >= 1024 ? "68" : position >= 645 ? "50" : "35"}/>
                        </div>
                        <div className='text-[#000000] text-[21px] sm:text-[29px] lg:text-[39px] font-medium z-[1] relative'>{points[1].number}</div>
                    </div>
                    <div>
                        <p className='text-[#181F38] font-semibold text-[22px] sm:text-[34px]'>Valuation</p>
                        <p className='text-[#2B3857] font-medium text-[20px] sm:text-[26px] mt-[4px] lg:mt-[8px]'>$100 Million Pre-Money</p>
                    </div>
                </div>
                <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start'>
                    <div className='relative size-[31px] sm:size-[51px] lg:size-[71px] flex justify-start items-center shrink-0'>
                        <div className='absolute left-0 z-[0]'>
                            <NumberBgSvg colour={points[2]?.colour} size={position >= 1024 ? "68" : position >= 645 ? "50" : "35"}/>
                        </div>
                        <div className='text-[#000000] text-[21px] sm:text-[29px] lg:text-[39px] font-medium z-[1] relative'>{points[2].number}</div>
                    </div>
                    <div>
                        <p className='text-[#181F38] font-semibold text-[22px] sm:text-[34px]'>Dilution</p>
                        <p className='text-[#2B3857] font-medium text-[20px] sm:text-[26px] mt-[4px] lg:mt-[8px]'>10%</p>
                    </div>
                </div>
                <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start'>
                    <div className='relative size-[31px] sm:size-[51px] lg:size-[71px] flex justify-start items-center shrink-0'>
                        <div className='absolute left-0 z-[0]'>
                            <NumberBgSvg colour={points[3]?.colour} size={position >= 1024 ? "68" : position >= 645 ? "50" : "35"}/>
                        </div>
                        <div className='text-[#000000] text-[21px] sm:text-[29px] lg:text-[39px] font-medium z-[1] relative'>{points[3].number}</div>
                    </div>
                    <div>
                        <p className='text-[#181F38] font-semibold text-[22px] sm:text-[34px]'>Negotiation on Valuation</p>
                        <p className='text-[#2B3857] font-medium text-[20px] sm:text-[26px] mt-[4px] lg:mt-[8px]'>Not open, as it reflects fair market value and future potential.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
