import React from 'react'

export default function Note() {
    return (
        <div className='flex flex-col justify-center items-center mt-[120px] px-[16px] sm:px-0 relative z-[1]'>
            <div className='relative'>
                <h3 className='text-[#180833] font-bold text-[24px] sm:text-[32px] lg:text-[42px] text-center'>Note</h3>
            </div>
            <p className='text-[#333333] text-[19px] sm:text-[20px] lg:text-[26px] leading-[33px] sm:leading-[36px] lg:leading-[42px] font-medium w-full sm:w-11/12 text-start sm:text-center mt-[8px]'>Investing in Startups may sound fancy as we have Swiggy and Zomatto IPOs but while two companies has listed. Thousands have failed and few have become zero so investing in startups is always a high risk.</p>
        </div>
    )
}
