import React from 'react'
import banner_img from "../../../../../images/home/banner-project.webp"

export default function Banner() {
  return (
    <div className='mt-[20px] flex flex-col gap-[20px] justify-center items-center mx-auto relative z-[15] w-full px-[20px]'>
        <div className='flex flex-col items-center justify-center text-center gap-[16px]'>
            <h1 className='text-[#180833] text-[26px] font-bold'>Workfast helps you to get the most valuable thing in the world, "Your Time".</h1>
            <h2 className='text-[#180833] text-[16px] font-medium'>Workfast.ai is designed and developed to save your time by bringing in Focus and Productivity.</h2>
            <button className='bg-[#FDD01F] rounded-[8px] w-full py-[12px] text-[#181000] text-[14px] font-semibold' onClick={() => {window.location.href = '/book-for-demo';}}>Book a Demo</button>
        </div>
        <img src={banner_img}  alt='workfast' loading='lazy' width={400} height={400}/>
    </div>
  )
}
