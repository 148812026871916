import React from 'react'

export default function Notifications() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Notifications</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Notification/notification1.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Stay on top of your work with Workfast notifications! By clicking on any notification, you’ll be redirected to the relevant task, project, or action. For even quicker responses, you can chat directly from the message notifications, ensuring seamless communication and keeping you updated without missing a beat.</p>
            <div className='mt-[22px] flex flex-col gap-[15px]'>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>1</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Get Real-time updates and never miss any messages.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
