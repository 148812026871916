import React from 'react'

export default function ContactUs() {
    const handleEmailClick = () => {
        window.open('https://mail.google.com/mail/?view=cm&fs=1&to=hello@workfast.ai');
      };
    return (
        <div className='flex flex-col w-full h-screen justify-center items-centerrelative overflow-hidden p-[10px] md:p-[0px]'>
            <img src='https://dz1x1c630cl14.cloudfront.net/webassets/whiteThemeLogo.svg' alt='logo' className='relative z-[10] w-[200px] lg:w-[300px] mx-auto' loading='lazy'/>
            <h3 className='text-[#252525] font-medium text-[12px] sm:text-[14px] lg:text-[28px] mt-[30px] lg:mt-[50px] text-center relative z-[10]'>For contact, reach us at <span className='text-[#FDD220] underline cursor-pointer' onClick={() => {handleEmailClick()}}>hello@workfast.ai</span>.</h3>
            <h3 className='text-[#252525] font-medium text-[12px] sm:text-[14px] lg:text-[28px] mt-[16px] text-center'>Our team will respond and do the needful.</h3>

        </div>
    )
}
