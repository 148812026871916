import React from 'react'

export default function WhyWorkfastAiIsCreated() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[18px] xl:gap-[22px]'>
            <h2 className='text-[#070707] text-[21px] md:text-[26px] lg:text-[30px] font-semibold relative z-[1] w-[280px] md:w-[340px] xl:w-[395px]'>Why workfast.ai is created ?</h2>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Major pain points which I see across sectors, industry and business are :</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'><span className='font-semibold'>Firstly</span> “Most software tools are really complex in UI/UX and as a layman many will face the difficulty to use such tool. On other hand anyone from small company of 10 to enterprise of 1,00,000 people can use Workfast.ai seamlessly within few minutes of onboarding“.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'> <span className='font-semibold'>Secondly </span> “Why to waste lots of money on multiple tools for handling projects, Team communication, client communication, CRM etc“. Just use one or two effective tool and reduce your finance on wasteful resources.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'><span className='font-semibold'>Thirdly </span> “miscommunication happens even using 10 tools and teams are not in sync with company goal“.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'><span className='font-semibold'>Finally </span> “Exporting Data from existing platforms to new one is really a difficult process both physically and financially. It cost millions of dollars to retrieve information from most software tools“.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Hence, as a company of 100 people we also felt the same issues and built a platform for our own use. When it truly benefited us , we felt it will benefits lots of folks out there who are similarly facing.</p>
        </div>
    )
}
