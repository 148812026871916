import React from 'react'

export default function TaskListManagement() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
      <h3 className='text-[#000000] text-[18px] font-bold'>Task List Management</h3>
      <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Task/task13.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
      <div className='mt-[6px]'>
        <p className='text-[#000000] text-[14px] font-medium'>Keep your projects organized by creating, editing, or deleting task lists as needed. Use the “Task List Management” feature to prioritize, categorize, and streamline your workflow. Easily track progress and ensure nothing falls through the cracks. Start managing your task lists for better productivity today!</p>
        <div className='mt-[11px] flex flex-col gap-[7px]'>
          <div className='flex gap-[4px] items-start'>
            <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[3px]'>1</div>
            <p className='text-[#000000] text-[14px] font-normal'>Use the ellipsis icon (3-dot menu) to Edit List, Duplicate List, Archive List</p>
          </div>
        </div>
      </div>
    </div>
  )
}
