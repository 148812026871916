import React from 'react'
import Banner from './component/banner'
import Principles from './component/principles'
import Programs from './component/programs'
import Header from '../../header'
import Footer from '../../footer'

export default function Reseller() {
    return (
        <div>
            <Header />
            <div className='flex flex-col justify-start items-center w-full h-full bg-[#FFFFFF] relative overflow-hidden gap-[30px] md:gap-[60px] lg:gap-[100px]'>
                <Banner />
                <Principles />
                <Programs />
                <Footer />
            </div>
        </div>
    )
}
