import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function InvestorBanner() {
    const navigate = useNavigate();
  return (
    <div className='w-11/12 mx-auto bg-[#fff] rounded-[11px] sm:rounded-[25px] sm:py-[21px] p-[14px] sm:px-[26px]  mt-[20px] lg:mt-[40px] xl:mt-[60px] flex flex-col lg:flex-row justify-between items-center relative z-[10]' style={{boxShadow : "0px 1px 4px 0px #00000040"}} onClick={() => {navigate('/investor/form')}}>
        <div>
            <p className='text-[#180833] font-medium text-[13px] sm:text-[17px] text-center lg:text-start'>After Successfully Raising 5.5 Million Dollars So Far</p>
            <p className='text-[#180833] text-[14px] sm:text-[20px] font-bold text-center lg:text-start'>🎉 Happy to say Workfast.ai will be raising the next round of funding with We Founder Circle</p>
        </div>
        <button className='py-[12px] sm:py-[18px] px-[33px] bg-[#FFDD09] rounded-[13px] text-[#1E1A00] font-semibold text-[14px] sm:text-[18px] mt-[10px] sm:mt-[40px] lg:mt-[0] w-full sm:w-auto' onClick={() => {navigate('/investor/form')}}>Click here to Apply</button>
    </div>
  )
}
