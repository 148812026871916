import React from 'react'

export default function CreateReport() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Create a Report</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Report/report2.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Easily generate reports to track progress, analyze performance, and share insights. Go to the “Reports” section, select your desired parameters, and click “Create Report.” Customize your data to focus on key metrics that matter to your team. Start creating reports to keep everyone aligned and informed!</p>
            <div className='mt-[22px] flex flex-col gap-[15px]'>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>1</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Enter a Report Title</p>
                </div>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>2</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Select the Report Project</p>
                </div>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>3</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Choose the People or Select All </p>
                </div>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>4</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Choose Save for Creating a Report</p>
                </div>
            </div>
        </div>
    </div>
  )
}
