import React from 'react'

export default function CreateProjectSpace() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[rgb(0,0,0)] text-[26px] lg:text-[32px] font-bold'>Create Project Space</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Project/project20.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Set up a dedicated area for your project by creating a new “Project Space.” Go to the “Create Space” option, name your space, and customize it with relevant settings and permissions. This feature helps you keep all project-related tasks, files, and discussions organized in one place.</p>
            <div className='mt-[22px] flex flex-col gap-[15px]'>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>1</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Enter your Space title</p>
                </div>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>2</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Select your Space Icon</p>
                </div>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>3</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Select your Space Colour</p>
                </div>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>4</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Click Create New Space to proceed</p>
                </div>
            </div>
        </div>
    </div>
  )
}
