import React from 'react'

export default function ChangeAccountType() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Change Account Type</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Dashboard/dashboard2.mov" autoPlay playsInline loop muted className='rounded-[6px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Easily upgrade or downgrade your account with the “Change Account Type” feature. Choose the account type as Admin or Member.</p>
        </div>
    </div>
  )
}
