import React from 'react'
import exist from "../../../../../../../images/userguide/existing-workspace.webp";

export default function ExistingWorkspace() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Existing Workspace</h3>
        <img src={`${exist}`} alt='exist' className='rounded-[12px]' loading='lazy'/>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>A workspace in Workfast is a dedicated space where you and your team can manage projects, collaborate on tasks, and stay organised. If you're already a member or have been invited, select your existing workspace.</p>
            <div className='mt-[22px] flex flex-col gap-[7px]'>
                <div className='flex gap-[8px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[3px]'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>If you see your workspace listed, simply click "Open Workspace" to access it and start collaborating!</p>
                </div>
                <div className='flex gap-[8px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[3px]'>2</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Click "Create a New Workspace" to set one up quickly and easily!</p>
                </div>
            </div>
        </div>
    </div>
  )
}
