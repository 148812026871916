import React from 'react'

export default function Breakin_Barriers() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[18px] xl:gap-[22px]'>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>An underlying truth about communication and collaboration tools in an enterprise is, its acts as a lifeline which brings success to it. So most enterprises and organisation puts heavy weight to productivity and management tools that enhances communication and collaboration. What if there is a communication and collaboration mishaps occur?</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Firstly such mishaps will hinders the flow of information within the team. Teams are important pillar for an organisation. Already teams work like silos, which shield information from leaking beyond them. But successful organisation wont work like that. They always encourage free flow of necessary information and knowledge to be transferred across work structure.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Secondly, There exist conflicting goals and priorities inside the organisation because teams are not aligned with company vision. If proper communication can bring excellence in work, the opposite is true for miscommunication.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Thirdly, we can see workload imbalance in teams easily. Not all designers have same workload nor does all sales executive close 20 deals per day. Basically this is because of poor resource allocation and management. This actually creates resentment among teams.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Finally, every teams in an enterprise face conflicts in finalising priorities, tasks, goals and projects and even agendas. Improper communication will not set a conflict resolution mechanism for this conflicts. Using of multiple tools will not help solve problems rather a better tool for collaboration is the need of the hour.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>In this blog we are going to see about Time tested, Novel project management tool which caters most needs of an enterprise for communication and collaboration and will addresses many common challenges in an organisation:Workfast.ai</p>
        </div>
    )
}
