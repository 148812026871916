import React from 'react'
import CreateNewChannel from './create-new-channel'
import CustomizeChanelIcon from './customize-channel-icon'
import SetChannelVisibility from './set-channel-visibility'
import PublicChannel from './public-channel'
import PrivateChannel from './private-channel'
import AddColleagues from './add-colleagues'
import StartConversations from './start-conversations'
import TagColleagues from './tag-colleagues'
import ReactMessages from './react-to-messages'
import ReplyMessages from './reply-to-messages'
import ThreadedReplies from './threaded-replies'
import ReplyChannel from './reply-to-channel'
import ShareAttachments from './share-attachments'
import SendAudioMessages from './send-audio-messages'
import MessageActions from './message-actions'
import ChannelDetails from './channel-details'
import ViewPinnedSavedMessages from './view-pinned-saved-messages'
import ManageChannelMembers from './manage-channel-members'


export default function Mobile() {
    return (
        <div className='flex flex-col gap-[16px] relative w-full px-[20px] mt-[12px]'>
            <div className='text-[#000000] text-[15px] font-normal'>
                <p>In Workfast.ai, channels are dedicated spaces within your workspace where teams can communicate, collaborate, and stay organized around specific topics or projects. Each channel can be customized to allow members, guests, or customers to participate and share relevant updates. You can create channels for different purposes such as team discussions, project collaborations, or general announcements.</p>
            </div>
            <div id='create-new-channel'><CreateNewChannel /></div>
            <div id='customize-channel-icon'><CustomizeChanelIcon /></div>
            <div id='set-channel-visibility'><SetChannelVisibility /></div>
            <div id='public-channel'><PublicChannel /></div>
            <div id='private-channel'><PrivateChannel /></div>
            <div id='add-colleagues'><AddColleagues /></div>
            <div id='start-conversations'><StartConversations /></div>
            <div id='tag-colleagues'><TagColleagues /></div>
            <div id='react-to-messages'><ReactMessages /></div>
            <div id='reply-to-messages'><ReplyMessages /></div>
            <div id='threaded-replies'><ThreadedReplies /></div>
            <div id='reply-to-channel'><ReplyChannel /></div>
            <div id='share-attachments'><ShareAttachments /></div>
            <div id='send-audio-messages'><SendAudioMessages /></div>
            <div id='message-actions'><MessageActions /></div>
            <div id='channel-details'><ChannelDetails /></div>
            <div id='view-pinned-saved-messages'><ViewPinnedSavedMessages /></div>
            <div id='manage-channel-members'><ManageChannelMembers /></div>
        </div>
    )
}
