import React from 'react'
import { Link } from 'react-scroll';
import Inbox from './inbox';
import AssignedToMe from './assigned-to-me';
import CollaboratorTasks from './collaborator-tasks';
import PersonalTasks from './personal-tasks';
import AllTasks from './all-tasks';
import CreateNewListIcon from './create-new-list-icon';
import Task from './tasks';
import TaskSorting from './task-sorting';
import TaskFiltering from './task-filtering';
import PriorityView from './priority-view';
import PeopleView from './people-view';
import DateView from './date-view';
import TaskListManagement from './task-list-management';
import TaskManagement from './task-management';
import Priority from './priority';
import Status from './status';
import QuickTaskFeature from './quick-task-feature';


export default function Content() {
    const [activeSection, setActiveSection] = React.useState(null);

    // Update active section on scroll
    const handleSetActive = (to) => {
        setActiveSection(to);
    };

    const tocData = [
        { title: 'Inbox', id: 'inbox' },
        { title: 'Assigned to Me', id: 'assigned-to-me' },
        { title: 'Collaborator Tasks', id: 'collaborator-tasks' },
        { title: 'Personal Tasks', id: 'personal-tasks' },
        { title: 'All Tasks', id: 'all-tasks' },
        { title: 'Task Management', id: 'task-management' },
        { title: 'Priority', id: 'priority' },
        { title: 'Status', id: 'Status' },
        { title: 'Quick Task Feature', id: 'quick-task-feature' },
        { title: 'Create a New List & Icon', id: 'create-new-list-icon' },
        { title: 'Task', id: 'task' },
        { title: 'Task Sorting', id: 'task-sorting' },
        { title: 'Task Filtering', id: 'task-filtering' },
        { title: 'Priority View', id: 'priority-view' },
        { title: 'People View', id: 'people-view' },
        { title: 'Date View', id: 'date-view' },
        { title: 'Task List Management', id: 'task-list-management' },
    ];

    return (
        <div className='flex w-9/12 mx-auto gap-[63px] relative z-[21]'>
            <aside className='sticky top-[10px] right-0 self-start w-[35%] z-[21] rounded-[8px] block'>
                <ul className={`mt-[30px]`} data-hs-scrollspy="#scrollspy-2" data-hs-scrollspy-scrollable-parent="#scrollspy-scrollable-parent-2">
                    {tocData.map((section) => (
                        <li key={section.id}>
                            <Link
                                activeClass="" // Active link style
                                to={section.id}
                                spy={true}
                                smooth={true}
                                duration={100}
                                className={`flex gap-[10px] lg:gap-[17px] items-start py-[17px] cursor-pointer rounded-[6px] border-[#E6E9EF] border-b`}
                                onSetActive={handleSetActive}
                            >
                                <h3 className={`${activeSection === section.id ? "text-[#F6831D] text-[14px] lg:text-[18px] font-semibold" : "text-[#676879] text-[12px] lg:text-[16px] font-medium"}`}>{section.title}</h3>
                            </Link>
                        </li>
                    ))}
                </ul>
            </aside>
            <div className='flex flex-col gap-[22px] lg:gap-[32px] w-[65%] xl:w-[57%] relative top-[40px]'>
                <div className='text-[#000000] text-[15px] lg:text-[18px] font-normal'>
                    <p>In Workfast.ai, tasks are key elements used to manage work and assign responsibilities within a team or project. You can create, assign, and track tasks, set deadlines, and prioritize them. Tasks can be broken down into subtasks, making complex projects more manageable. You can also set task statuses, add comments, and upload files to ensure smooth communication. Managing tasks effectively helps ensure team alignment, accountability, and progress tracking.</p>
                </div>
                <div id='inbox'><Inbox /></div>
                <div id='assigned-to-me'><AssignedToMe/></div>
                <div id='collaborator-tasks'><CollaboratorTasks/></div>
                <div id='personal-tasks'><PersonalTasks/></div>
                <div id='all-tasks'><AllTasks/></div>
                <div id='create-new-list-icon'><CreateNewListIcon/></div>
                <div id='task'><Task /></div>
                <div id='task-management'><TaskManagement/></div>
                <div id='priority'><Priority/></div>
                <div id='status'><Status/></div>
                <div id='quick-task-feature'><QuickTaskFeature/></div>
                <div id='task-sorting'><TaskSorting/></div>
                <div id='task-filtering'><TaskFiltering/></div>
                <div id='priority-view'><PriorityView/></div>
                <div id='people-view'><PeopleView/></div>
                <div id='date-view'><DateView/></div>
                <div id='task-list-management'><TaskListManagement /></div>
            </div>
        </div>
    )
}
