import React from 'react'
import Banner from './component/banner'
import Comparison from './component/comparison'
import Price from './component/price'
import Header from '../../../header'
import Footer from '../../../footer'


export default function Jira() {
  return (
    <div className='flex flex-col justify-start items-center w-full h-full bg-[#FFFFFF] relative overflow-hidden gap-[30px] md:gap-[60px] lg:gap-[100px]'>
        <Header/>
        <Banner/>
        <Comparison/>
        <Price/>
        <Footer/>
    </div>
  )
}
