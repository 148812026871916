import React from 'react'

export default function TableView() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Table View</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Report/report4.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>View all your tasks and data in a structured, spreadsheet-like format with the “Table View” feature. Easily sort, filter, and edit tasks directly from the table to manage your workload more efficiently. This view is perfect for quickly finding details and making bulk updates. Switch to Table View to enhance your task management!</p>
        </div>
    </div>
  )
}
