import React from 'react'

export default function EditDeleteMessages() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Edit & Delete Messages</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Dashboard/dashboard4.mov" autoPlay playsInline loop muted className='rounded-[6px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Maintain clarity and accuracy in your communications with the “Edit & Delete Messages” feature. Modify or remove messages to correct errors or remove outdated information. This functionality ensures your conversations remain relevant and error-free.</p>
            <div className='mt-[22px] flex flex-col gap-[15px]'>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>1</div>
                    <p className='text-[#000000] text-[16px] font-normal'>Edit Messages feature on and off</p>
                </div>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>2</div>
                    <p className='text-[#000000] text-[16px] font-normal'>Delete Messages feature on and off</p>
                </div>
            </div>
        </div>
    </div>
  )
}
