import React from 'react'
import { SortByAscendingSvg, SortByCreateOnSvg, SortByDescendingSvg, SortByDueDateSvg, SortByRecentSvg, SortByTaskNameSvg } from '../../../svg';


export default function SortBy() {
    return (
        <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
            <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Sort by</h3>
            <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Project/project11.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
            <div className='mt-[10px]'>
                <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Keep your tasks organized by sorting them according to priority, due date, or status. Use the “Sort” option in your task view to arrange tasks in the order that best suits your workflow. This feature ensures you focus on what matters most and helps you stay on top of your to-do list!</p>
                <div className='mt-[22px] flex flex-col gap-[22px]'>
                    <div className='flex gap-[8px] items-center'>
                        <SortByRecentSvg size="24" />
                        <p className='text-[#000000] text-[16px] font-normal'><span className='text-[#D93FFF] font-semibold'>Sort by Recent</span> : Sort by Recent arranges items from newest to oldest.</p>
                    </div>
                    <div className='flex gap-[8px] items-center'>
                        <SortByTaskNameSvg size="24" />
                        <p className='text-[#000000] text-[16px] font-normal'><span className='text-[#34C759] font-semibold'>Sort by Task Name</span> : Sort by Task Name organizes items alphabetically.</p>
                    </div>
                    <div className='flex gap-[8px] items-center'>
                        <SortByCreateOnSvg size="24" />
                        <p className='text-[#000000] text-[16px] font-normal'><span className='text-[#01B0EF] font-semibold'>Sort by Created On</span> : Sort by Created On arranges items by their creation date.</p>
                    </div>
                    <div className='flex gap-[8px] items-center'>
                        <SortByDueDateSvg size="24" />
                        <p className='text-[#000000] text-[16px] font-normal'><span className='text-[#FF473D] font-semibold'>Sort by Due Date</span> : Sort by Due Date organizes items by their deadline.</p>
                    </div>
                    <div className='flex gap-[8px] items-center'>
                        <SortByAscendingSvg size="24" />
                        <p className='text-[#000000] text-[16px] font-normal'><span className='text-[#32ADE6] font-semibold'>Sort by Ascending</span> : Sort by Ascending arranges items from smallest to largest or A to Z.</p>
                    </div>
                    <div className='flex gap-[8px] items-center'>
                        <SortByDescendingSvg size="24" />
                        <p className='text-[#000000] text-[16px] font-normal'><span className='text-[#FF9500] font-semibold'>Sort by Descending</span> : Sort by Descending arranges items from largest to smallest or Z to A.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
