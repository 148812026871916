import React from 'react'

export default function ViewPinnedSavedMessages() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>View Pinned and Saved Messages</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Channel/channel17.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Easily access important messages by checking the “Pinned” and “Saved” sections. Pinned messages highlight key updates or announcements, while saved messages keep your favorite or crucial content easily reachable. Use these features to stay organized and keep track of valuable information in your channel!</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Access a list of pinned and saved messages.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
