import React from 'react'
import banner from "../../../../../images/confidential/build-workfast.webp"
import { BuildSpanSvg } from '../svg'

export default function BuildWorkfast() {
    const [position, setPosition] = React.useState(window.innerWidth);
    const [startValue, setStartValue] = React.useState(null);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    const headerRef = React.useRef(null);

    React.useEffect(() => {
        if (headerRef.current) {
            const startValue = headerRef.current.getBoundingClientRect().left;
            console.log(startValue);
            setStartValue(startValue)
        }
    }, []);
    return (
        <div className='flex flex-col justify-center items-center mt-[40px] sn:mt-[33px] px-[16px] sm:px-0 relative z-[1]'>
            <div className='relative'>
                <h3 ref={headerRef} className='text-[#180833] font-bold text-[24px] sm:text-[32px] lg:text-[42px] text-center relative z-[1]'>Why Did We Build Workfast.ai?</h3>
                <div className={`absolute top-[3px] sm:top-1/2 sm:-translate-y-1/2 -left-[${startValue}px] sm:-left-[30px] z-0`}>
                    <BuildSpanSvg colour="#34C759" width={position >= 1024 ? "262" : "182"} height={position >= 1024 ? "54" : position >= 645 ? "40" : "33"} />
                </div>
            </div>
            <p className='text-[#333333] text-[19px] sm:text-[20px] lg:text-[26px] leading-[33px] sm:leading-[36px] lg:leading-[42px] font-medium w-full sm:w-10/12 xl:w-[60%] text-start sm:text-center mt-[8px]'>Workfast.ai was created to address the challenges of using multiple tools that strained focus and budgets. Initially developed as an internal solution, it quickly gained traction with other businesses, proving its potential to enhance productivity and collaboration.</p>
            <img src={banner} alt='workfast' className='mt-[35px] sm:w-[50%]' loading='lazy'/>
        </div>
    )
}
