import React from 'react'

export default function SettingsTab() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Settings Tab</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Project/project18.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Customize your experience by accessing the “Settings Tab.” Adjust preferences for Edit or Delete the project, and more to tailor Workfast to your needs.</p>
            <div className='mt-[22px] flex flex-col gap-[15px]'>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>1</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Delete the Project</p>
                </div>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>2</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Edit the Project</p>
                </div>
            </div>
        </div>
    </div>
  )
}
