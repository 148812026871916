import React from 'react'

export default function WorkfastAi() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[18px] xl:gap-[22px]'>
            <h2 className='text-[#070707] text-[21px] md:text-[26px] lg:text-[30px] font-semibold relative z-[1] w-[210px] md:w-[260px] lg:w-[300px]'>What is Workfast.ai ?</h2>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Workfast.ai is a platform designed to streamline organisational processes, facilitating faster work execution than ever before. Workfast.ai is an intelligent platform to organise, collaborate and engage your work in an easiest and faster way possible. Acting as a communication and collaboration hub, Workfast.ai enables the setting, assignment, and collaborative execution of tasks, aimed at enhancing productivity. Through its usage, organisations can efficiently manage their business operations, projects, teams, and tasks, among other aspects easily and smartly.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Looking for a new platform for project management? Discover Workfast.ai 2 If your inner voice saying like, Hey BoB, Similarly thousands of platforms already exist in the market, what’s New with Workfast.ai ?…</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>i would say, there is nothing new but we have to analyse how it will benefit organisation more in terms of productivity and working faster without miscommunication.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Here we go. I will list and explain the core features of Workfast.ai software tool which will give a insight of the product clearly.</p>
        </div>
    )
}
