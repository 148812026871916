import React from 'react'
import gold from "../../../../../images/confidential/gold.webp"
import silver from "../../../../../images/confidential/silver.webp"
import { StarSvg } from '../svg'


export default function Metrics() {
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);
    return (
        <div className='bg-[#F8FBFF] py-[20px] sm:py-[50px] lg:py-[73px] mt-[120px] px-[20px] sm:px-0 relative z-[1] w-full flex flex-col gap-[14px] sm:gap-[24px] lg:gap-[42px] justify-center items-center'>
            <h3 className='text-[#181F38] font-bold text-[26px] sm:text-[32px] lg:text-[40px] text-center'>Metrics We Track</h3>
            <div className='relative flex flex-col sm:flex-row justify-center items-center gap-[40px] sm:gap-[32px] lg::gap-[54px] sm:px-[20px] lg:px-0'>
                <div className='p-[20px] sm:p-[26px] lg:p-[34px] rounded-[24px] bg-[#EBEDFD] sm:w-[300px] lg:w-[462px] sm:h-[300px] lg:h-auto flex flex-col justify-center items-center'>
                    <div className='absolute top-[250px] sm:-top-[60px] right-0'><StarSvg size={position >= 625 ? "54" : "40"} /></div>
                    <div className='absolute -top-[60px] left-0'><StarSvg size={position >= 625 ? "33" : "23"} /></div>
                    <div className='absolute bottom-0 -right-[10px] sm:-bottom-[10px] sm:-right-[20px] hidden sm:flex'><StarSvg size={position >= 625 ? "20" : "15"} /></div>
                    <img src={gold} alt='gold' className='w-[150px]' loading='lazy'/>
                    <p className='text-[#0E0E11] text-[24px] sm:text-[28px] lg:text-[32px] font-bold'>Primary</p>
                    <p className='text-[#3B3C45] text-[14px] sm:text-[16px] lg:text-[18px] font-normal mt-[12px] text-center'>Customer Churn, Activation Rate, Conversion Rate, CLV, CAC, LTV/CAC Ratio, MRR, ARR.</p>
                </div>
                <div className='p-[20px] sm:p-[26px] lg:p-[34px] rounded-[24px] bg-[#E6F5FD] sm:w-[300px] lg:w-[462px] sm:h-[300px] lg:h-auto  flex flex-col justify-center items-center'>
                    <img src={silver} alt='silver' className='w-[150px]' loading='lazy' />
                    <p className='text-[#0E0E11] text-[24px] sm:text-[28px] lg:text-[32px] font-bold'>Secondary</p>
                    <p className='text-[#3B3C45] text-[14px] sm:text-[16px] lg:text-[18px] font-normal mt-[12px] text-center'>CPS, Burn Rate, ARPU, Expansion Revenue, Sales Efficiency.</p>
                </div>
            </div>
        </div>
    )
}
