import React from 'react'

export default function Workflow() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[18px] xl:gap-[22px]'>
            <h2 className='text-[#000000] text-[21px] md:text-[26px] lg:text-[30px] font-semibold relative z-[1] w-[190px] md:w-[230px] lg:w-[270px]'>Workflow Setting :</h2>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Workfast.ai is one platform for industries which has a clear workflow with input/output mechanism.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>For example, App and web development, sales and marketing, service companies, product development companies, digital marketing agencies, Transport, Tourism, Hoteling and hospitality, Audit and finance companies and corporates to name a few can manage their work easily with Workfast.ai.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>The organisation can create a complex or simple workflow of their choice. Its not static. Whenever you want to change the workflow, you can. They can add any number of team members to justify the workflow.</p>
        </div>
    )
}
