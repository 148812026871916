import React from 'react'

export default function PeopleView() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>People View</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Project/project14.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Get a clear picture of your team’s workload with the “People View” feature. See tasks assigned to each team member, track progress, and manage resources effectively.</p>
        </div>
    </div>
  )
}
