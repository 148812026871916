import React from 'react'

export default function Signup() {
    return (
        <div className='flex flex-col gap-[11px] justify-center'>
            <h3 className='text-[#000000] text-[18px] font-bold'>Signup/Sign-in</h3>
            <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Onboard/onboard1.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
            <div className='mt-[6px]'>
                <p className='text-[#000000] text-[14px] font-semibold'>How to Log In to Workfast.ai</p>
                <p className='text-[#000000] text-[14px] font-medium mt-[12px]'>To get started with Workfast.ai, follow these simple steps:  </p>
                <div className='mt-[11px] flex flex-col gap-[7px]'>
                    <div className='flex gap-[4px] items-start'>
                        <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[3px]'>1</div>
                        <div>
                            <p className='text-[#000000] text-[14px] font-medium'>Create a New Workspace</p>
                            <p className='text-[#000000] text-[14px] font-normal mt-[4px]'>If you are new to Workfast.ai or plan to invite your team to collaborate in a workspace, select the <span className='font-medium'>"Create a Workspace"</span> option.</p>
                            <p className='text-[#000000] text-[14px] font-normal mt-[2px]'>This option is typically used by workspace admins to set up a dedicated space for team collaboration.</p>
                            <p className='text-[#000000] text-[14px] font-normal mt-[2px]'>Once the workspace is created, admins can invite team members to join.</p>
                        </div>
                    </div>
                    <div className='flex gap-[4px] items-start'>
                        <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[3px]'>2</div>
                        <div>
                            <p className='text-[#000000] text-[14px] font-medium'>Continue with Work Email</p>
                            <p className='text-[#000000] text-[14px] font-normal mt-[4px]'> Sign In to an Existing Workspace If you are already a workspace member that you were invited to, select the <span className='font-medium'>"Continue with Work Email"</span> option.</p>
                            <p className='text-[#000000] text-[14px] font-normal mt-[2px]'>This is the preferred method for team members or guests who need access to an existing workspace.</p>
                        </div>
                    </div>
                    <div className='flex gap-[4px] items-start'>
                        <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[3px]'>3</div>
                        <div>
                            <p className='text-[#000000] text-[14px] font-medium'>Continue with Google</p>
                            <p className='text-[#000000] text-[14px] font-normal mt-[4px]'>If you prefer a quick and easy sign-in experience, choose <span className='font-medium'>"Continue with Google."</span></p>
                            <p className='text-[#000000] text-[14px] font-normal mt-[2px]'>This option simplifies onboarding by allowing you to sign in using your Google account.</p>
                            <p className='text-[#000000] text-[14px] font-normal mt-[2px]'>Ensure that your email ID is synced with the workspace for seamless access.</p>
                        </div>
                    </div>
                    <div className='flex gap-[4px] items-start'>
                        <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[3px]'>4</div>
                        <div>
                            <p className='text-[#000000] text-[14px] font-medium'>Continue with Apple</p>
                            <p className='text-[#000000] text-[14px] font-normal mt-[4px]'>If you prefer a quick and easy sign-in experience, choose <span className='font-medium'>"Continue with Apple."</span></p>
                            <p className='text-[#000000] text-[14px] font-normal mt-[2px]'>This option simplifies onboarding by allowing you to sign in using your Apple account.</p>
                            <p className='text-[#000000] text-[14px] font-normal mt-[2px]'>Ensure that your email ID is synced with the workspace for seamless access.</p>
                            <p className='text-[#000000] text-[14px] font-normal mt-[2px]'> With these four options, Workfast.ai ensures a smooth and flexible login process tailored to different user needs.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
