import React from 'react'
import Header from '../../header'
import Footer from '../../footer'
import { LogoSvg } from './component/svg'
import background from "../../../images/website-background.png"
import BuildWorkfast from './component/build-workfast'
import AreWeSolving from './component/are-we-solving'
import BehindWorkfast from './component/behind-workfast'
import Usp from './component/usp'
import Modules from './component/modules'
import CompanyAdopt from './component/company-adopt'
import WhereAreYouNow from './component/where-are-now'
import Metrics from './component/metrics'
import FundrasingDetails from './component/fundraising-details'
import FundingDetails from './component/funding-details'
import UsesFunds from './component/use-funds'
import Milestone from './component/milestone'
import Competitor from './component/competitors'
import Investors from './component/investors'
import RightPartners from './component/right-partners'
import Note from './component/note'

export default function Confidential() {
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);
    return (
        <div className='flex flex-col justify-center items-center w-full bg-[#fff]'>
            <Header />
            <div className='mt-[26px] lg:mt-[63px] mb-[20px] sm:mb-0 relative z-[10]'>
                <LogoSvg width={position >= 645 ? "250" : "187"} height={position >= 645 ? "49" : "36"} />
            </div>
            <img src={background} alt='background' className='absolute z-[0] w-full top-0' loading='lazy'/>
            <BuildWorkfast />
            <AreWeSolving />
            <BehindWorkfast />
            <Usp />
            <Modules />
            <CompanyAdopt />
            <WhereAreYouNow />
            <Metrics />
            <FundrasingDetails />
            <FundingDetails />
            <UsesFunds />
            <Milestone />
            <Competitor />
            <Investors />
            <RightPartners />
            <Note/>
            <Footer />
        </div>
    )
}
