import React from 'react'
import Header from '../../header'
import background from "../../../images/website-background.png";
import Banner from './banner';
import Features from './features';
import LookingFor from './teamwork';
import CommunicationHub from './communication-hub';
import Mobile from './mobile';
import Feedback from './feedbacks';
import Footer from '../../footer';
import Availables from './availables';
import Trusted from './trusted';
import InvestorBanner from './investor-banner';

export default function Home() {
    const [isMobileAtTop, setIsMobileAtTop] = React.useState(false);

    React.useEffect(() => {
        const mobileSection = document.getElementById('mobile');

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsMobileAtTop(true);
                    } else {
                        setIsMobileAtTop(false);
                    }
                });
            },
            {
                root: null, // Use the viewport as the root
                rootMargin: '-50px 0px 0px 0px', // Trigger when the element is within 50px of the top
                threshold: 0, // Trigger when any part of the target is visible
            }
        );

        if (mobileSection) {
            observer.observe(mobileSection);
        }

        // Cleanup the observer when the component unmounts
        return () => {
            if (mobileSection) {
                observer.unobserve(mobileSection);
            }
        };
    }, []);
    return (
        <div className='relative overflow-hidden'>
            <Header />
            <img src={background} alt='background' className='absolute z-[0] w-full top-0' loading='lazy'/>
            <InvestorBanner/>
            <Banner />
            <Features />
            <LookingFor />
            <CommunicationHub />
            <Mobile isMobileAtTop={isMobileAtTop}/>
            <Trusted/>
            <Feedback/>
            <Availables/>
            <Footer/>
        </div>
    )
}
