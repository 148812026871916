import React from 'react'

export default function CreateReport() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Create a Report</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Report/report2.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Easily generate reports to track progress, analyze performance, and share insights. Go to the “Reports” section, select your desired parameters, and click “Create Report.” Customize your data to focus on key metrics that matter to your team. Start creating reports to keep everyone aligned and informed!</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold mt-[3px] shrink-0'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Enter a Report Title</p>
                </div>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold mt-[3px] shrink-0'>2</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Select the Report Project</p>
                </div>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold mt-[3px] shrink-0'>3</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Choose the People or Select All </p>
                </div>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold mt-[3px] shrink-0'>4</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Choose Save for Creating a Report</p>
                </div>
            </div>
        </div>
    </div>
  )
}
