import React from 'react'

export default function Billing() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Billing</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Dashboard/dashboard5.mov" autoPlay playsInline loop muted className='rounded-[6px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Stay on top of your expenses with the “Billing” feature. View your current plan, update payment methods in one place. This feature helps you track and manage your subscription effectively.</p>
        </div>
    </div>
  )
}
