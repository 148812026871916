import React from 'react'

export default function PriorityView() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Priority View</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Project/project16.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Stay focused on your most critical tasks with the “Priority View” feature. Instantly see tasks categorized by their urgency—high, medium, or low priority. This view helps you manage your time effectively and ensure top tasks get done first.</p>
        </div>
    </div>
  )
}
