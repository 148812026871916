import React from 'react'

export default function PersonalisationAndIntegration() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[18px] xl:gap-[22px]'>
            <h2 className='text-[#000000] text-[21px] md:text-[24px] lg:text-[30px] font-semibold relative z-[1] md:w-[360px] lg:w-[455px]'>Personalisation and Integration :</h2>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Both in Mobile and Web view the user can change preferences in Launch bar or Navigation bar Bottom bar. This personalisation will give freedom to the users, in how they want their customisation.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Some need their daily task as a first icon, for some its calendar as they arrange and attend meetings often, some like to have AI intelligence as their first option. The list goes on. Your work defines what you really want in your bottom bar. CEO’s and CXO’s require Goals Tracker, Intelligence and calendar whereas engineers require Tasks, Projects, channels and chat.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>One can even switch off unnecessary option that distracts them eg: CEO dashboard or Finance management etc. Top 7 ways Workfast.ai can Revolutionise your project management 3 when workflow has to be smooth, integration is the best option. </p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Teams work on different platforms. By integrating the necessary softwares it increases the visibility for the teams as well entire company. You can integrate any software tools you want with just one click. For example Asana, Github, Adobe, Figma, clickup, slack etc. This creates seamless workflow. In reality uninterrupted work is a productive work.</p>
        </div>
    )
}
