import React from 'react'

export default function GraphView() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Graph View</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Report/report5.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Transform your data into insightful visuals with the “Graph View” feature. Choose from various chart types to display progress, performance, or trends clearly and compellingly. Use this view to spot patterns, make data-driven decisions, and communicate results effectively. Switch to Graph View for powerful data visualization!</p>
        </div>
    </div>
  )
}
