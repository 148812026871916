import React from 'react'
import Signup from './signup'
import ContinueWithEmail from './continue-with-email';
import VerifyOtp from './verify-otp';
import EnterWorkSpaceName from './enter-workspace-name';
import CreateYourProfile from './create-your-profile';
import InviteTeamMembers from './invite-team-members';
import CreateFirstChannel from './create-first-channel';
import WorkspaceSetup from './workspace-setup';
import ExistingWorkspace from './existing-workspace';

export default function Mobile() {
  return (
    <div className='flex flex-col gap-[16px] relative w-full px-[20px] mt-[12px]'>
      <div className='text-[#000000] text-[15px] font-normal'>
        <p>Welcome to Workfast.ai! Set up your account, invite your team, and create your workspace to start collaborating seamlessly.</p>
      </div>
      <div id='signup-signin'><Signup /></div>
      <div id='continue-with-email'><ContinueWithEmail /></div>
      <div id='verify-otp'><VerifyOtp /></div>
      <div id='existing-workspace'><ExistingWorkspace/></div>
      <div id='enter-workspace-name'><EnterWorkSpaceName /></div>
      <div id='create-your-profile'><CreateYourProfile /></div>
      <div id='invite-team-members'><InviteTeamMembers /></div>
      <div id='create-your-first-channel'><CreateFirstChannel /></div>
      <div id='workspace-setup-successful'><WorkspaceSetup /></div>
    </div>
  )
}
