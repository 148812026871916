import React from 'react'
import invite from '../../../../../../../images/userguide/task-management.webp';
import { AssignTaskSvg, CollabratorSvg, CommentReactSvg, DescriptionSvg, DueDateSvg, FollowUpReminderSvg, HistoryLogSvg, ProjectAttachmentSvg, ProjectRecurringtaskSvg, ProjectSubTaskSvg, ProjectTagSvg } from '../../../svg';


export default function TaskManagement() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
      <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Task Management</h3>
      <img src={`${invite}`} alt='invite' className='rounded-[12px]' loading='lazy'/>
      <div className='mt-[10px]'>
        <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Create tasks, assign them to team members, prioritize them, and set due dates. Additionally, you can tag tasks, add detailed descriptions, and track progress. Features like comments, task history, and sharing help improve collaboration and ensure no task is overlooked.</p>
        <div className='mt-[22px] flex flex-col gap-[22px]'>
          <div className='flex gap-[8px] items-start'>
            <DescriptionSvg size="24" />
            <p className='text-[#000000] text-[16px] font-medium'><span className='text-[#3AC7DB] font-bold'>Description:</span> Provide details about the task.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <DueDateSvg size="24" />
            <p className='text-[#000000] text-[16px] font-medium'><span className='text-[#FF473D] font-bold'> Due Date:</span> Set deadlines for task completion.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <AssignTaskSvg size="24" />
            <p className='text-[#000000] text-[16px] font-medium'><span className='text-[#FDB700] font-bold'>Assign Task:</span> Delegate tasks to team members.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <CollabratorSvg size="24" />
            <p className='text-[#000000] text-[16px] font-medium'><span className='text-[#34C759] font-bold'>Collaborator:</span> Add a collaborator who can view and assist in task completion.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <ProjectAttachmentSvg size="24" />
            <p className='text-[#000000] text-[16px] font-medium'><span className='text-[#FF9500] font-bold'>Attachments:</span> Attach images, videos, documents, and PDFs to tasks.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <FollowUpReminderSvg size="24" />
            <p className='text-[#000000] text-[16px] font-medium'><span className='text-[#05A3FF] font-bold'>Follow-up Reminder:</span> Set reminders for important follow-ups</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <ProjectTagSvg size="24" />
            <p className='text-[#000000] text-[16px] font-medium'><span className='text-[#B961FF] font-bold'>Tags:</span> Organize tasks using tags like "Android," "iOS," "Web Module."</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <ProjectRecurringtaskSvg size="24" />
            <p className='text-[#000000] text-[16px] font-medium'><span className='text-[#32ADE6] font-bold'>Recurring Tasks:</span> Schedule tasks to recur daily, weekly, or monthly.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <CommentReactSvg size="24" />
            <p className='text-[#000000] text-[16px] font-medium'><span className='text-[#FB5976] font-bold'>Comment and React:</span> Add comments and reactions to tasks.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <HistoryLogSvg size="24" />
            <p className='text-[#000000] text-[16px] font-medium'><span className='text-[#FF9500] font-bold'>Task History Log:</span> Track all activity related to the task.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <ProjectSubTaskSvg size="24" />
            <p className='text-[#000000] text-[16px] font-medium'><span className='text-[#27AE60] font-bold'>Sub-tasks:</span> Create sub-tasks with their own priorities, due dates, and assignees.</p>
          </div>
        </div>
      </div>
    </div>
  )
}
