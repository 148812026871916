import React from 'react'
import { Link } from 'react-scroll';
import ManageMembers from './manage-members';
import ChangeAccountType from './change-account-type';
import DeactivateAccount from './deactivate-account';
import EditDeleteMessages from './edit-delete-messages';
import Billing from './billing';

export default function Desktop() {
    const [activeSection, setActiveSection] = React.useState(null);

    // Update active section on scroll
    const handleSetActive = (to) => {
        setActiveSection(to);
    };

    const tocData = [
        { title: 'Manage Members', id: 'manage-members' },
        { title: 'Change Account Type', id: 'change-account-type' },
        { title: 'Deactivate Account', id: 'deactivate-account' },
        { title: 'Edit & Delete Messages', id: 'edit-delete-messages' },
        { title: 'Billing', id: 'billing' },
    ];

    return (
        <div className='flex w-9/12 mx-auto gap-[63px] relative z-[21]'>
            <aside className='sticky top-[10px] right-0 self-start w-[35%] z-[21] rounded-[8px] block'>
                <ul className={`mt-[30px]`} data-hs-scrollspy="#scrollspy-2" data-hs-scrollspy-scrollable-parent="#scrollspy-scrollable-parent-2">
                    {tocData.map((section) => (
                        <li key={section.id}>
                            <Link
                                activeClass="" // Active link style
                                to={section.id}
                                spy={true}
                                smooth={true}
                                duration={100}
                                className={`flex gap-[10px] lg:gap-[17px] items-start py-[17px] cursor-pointer rounded-[6px] border-[#E6E9EF] border-b`}
                                onSetActive={handleSetActive}
                            >
                                <h3 className={`${activeSection === section.id ? "text-[#F6831D] text-[14px] lg:text-[18px] font-semibold" : "text-[#676879] text-[12px] lg:text-[16px] font-medium"}`}>{section.title}</h3>
                            </Link>
                        </li>
                    ))}
                </ul>
            </aside>
            <div className='flex flex-col gap-[22px] lg:gap-[32px] w-[65%] xl:w-[57%] relative top-[40px]'>
                <div className='text-[#000000] text-[15px] lg:text-[18px] font-normal'>
                    <p>The "Admin Dashboard" is the central hub for managing your Workfast workspace. As an admin, you have full control over user roles, permissions, and access levels. View detailed analytics. Customize workspace settings to align with your organization’s needs. Track billing, subscription details, and manage invoices effortlessly.</p>
                </div>
                <div id='manage-members'><ManageMembers /></div>
                <div id='change-account-type'><ChangeAccountType/></div>
                <div id='deactivate-account'><DeactivateAccount/></div>
                <div id='edit-delete-messages'><EditDeleteMessages/></div>
                <div id='billing'><Billing/></div>
            </div>
        </div>
    )
}
