import React from 'react'

export default function ManageMembers() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Manage Members</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Dashboard/dashboard1.mov" autoPlay playsInline loop muted className='rounded-[6px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Control your team’s access and roles with the “Manage Members” feature. Add or remove members, adjust their permissions, and assign roles to ensure smooth collaboration. This feature helps you maintain a well-organized and secure workspace. Start managing members effectively to enhance teamwork and project success!</p>
        </div>
    </div>
  )
}
