import React from 'react'

export default function ViewBy() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>View by</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Notification/notification2.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>QEasily manage your notifications in Workfast by marking them as read or deleting them all at once. Simply click on the options to clear your notifications, ensuring your workspace stays organized and focused on the important tasks.</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Mark as Read and Delete all</p>
                </div>
            </div>
        </div>
    </div>
  )
}
