import React from 'react'
import acceptedInvite from '../../../../../../../images/userguide/send-audio-messages.webp';

export default function SendAudioMessages() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Send Audio Messages</h3>
        <img src={`${acceptedInvite}`} alt="accept" className=' rounded-[12px]' loading='lazy'/>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Communicate quickly and clearly by sending audio messages directly in the channel. Tap the “Record” button to capture and send your voice message. Audio messages are great for sharing detailed information or adding a personal touch. Start using audio messages to make your communication more dynamic and engaging!</p>
            <div className='mt-[22px] flex flex-col gap-[15px]'>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>1</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Record and send voice messages.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
