import React from 'react'
import Header from '../../../header'
import Footer from '../../../footer'
import Banner from './banner'
import Content from './content'

export default function People() {
    return (
        <div className='relative'>
            <Header />
            <Banner/>
            <Content/>
            <Footer />
        </div>
    )
}
