import React from 'react'
import CreateBlankProject from './create-blank-project';
import CreateNewProject from './create-new-project';
import CustomizeProjectAttributes from './customize-project-attributes';
import CustomizeTaskAttributes from "./customize-task-attributes"
import InviteTeamMembers from './invite-team-members';
import CreateProjectWorkflow from './create-project-workflow';
import TaskManagement from './task-management';
import Priority from './priority';
import Status from './status';
import QuickTaskFeature from './quick-task-feature';
import SortBy from './sort-by';
import Filterby from './filter-by';
import StageView from './stage-view';
import PeopleView from './people-view';
import PriorityView from './priority-view';
import InfoTab from './info-tab';
import SettingsTab from './settings-tab';
import DateView from './date-view';
import SpacesFolderManagement from './spaces-folder-management';
import CreateProjectSpace from './create-project-space';
import EllipsisIcon from './ellipsis-icon';
import CreateFoldersWithinSpace from './create-folders-within-space';


export default function Mobile() {
    return (
        <div className='flex flex-col gap-[16px] relative w-full px-[20px] mt-[12px]'>
            <div className='text-[#000000] text-[15px] font-normal'>
                <p>A project in Workfast is a space where teams organize their work. You can create folders to store tasks, documents, and discussions related to that project. This helps you keep everything structured and easy to find. Projects allow you to assign tasks, set deadlines, and track progress, ensuring that all team members stay aligned and focused on the project's goals.</p>
            </div>
            <div id='spaces-folder-management'><SpacesFolderManagement /></div>
            <div id='create-project-space'><CreateProjectSpace /></div>
            <div id='create-folders-within-space'><CreateFoldersWithinSpace /></div>
            <div id='ellipsis-icon'><EllipsisIcon /></div>
            <div id='create-blank-project'><CreateBlankProject /></div>
            <div id='create-new-project'><CreateNewProject /></div>
            <div id='customize-project-attributes'><CustomizeProjectAttributes /></div>
            <div id='customize-task-attributes-within-the-project'><CustomizeTaskAttributes /></div>
            <div id='invite-team-members'><InviteTeamMembers /></div>
            <div id='create-project-workflow'><CreateProjectWorkflow /></div>
            <div id='task-management'><TaskManagement /></div>
            <div id='priority'><Priority /></div>
            <div id='status'><Status /></div>
            <div id='quick-task-feature'><QuickTaskFeature /></div>
            <div id='sort-by'><SortBy /></div>
            <div id='filter-by'><Filterby /></div>
            <div id='stage-view'><StageView /></div>
            <div id='people-view'><PeopleView /></div>
            <div id='date-view'><DateView /></div>
            <div id='priority-view'><PriorityView /></div>
            <div id='info-tab'><InfoTab /></div>
            <div id='settings-tab'><SettingsTab /></div>
        </div>
    )
}
