import React from 'react'
import { IoCheckboxOutline } from "react-icons/io5";

export default function Tools() {
    return (
        <div className='flex flex-col justify-center items-center w-10/12 relative z-[1] mx-auto mb-[20px] sm:mb-[60px] xl:mb-[100px]'>
            <h2 className='text-[20px] md:text-[26px] lg:text-[32px] xl:text-[40px] text-[#000000] font-bold relative z-[1] lg:w-11/12 xl:w-10/12 text-center'>What software tools does the product managers use to bring productivity in development of a product?</h2>
            <p className='text-[#252525] font-normal text-[16px] md:text-[20px] lg:text-[24px] xl:text-[32px] mt-[20px] md:mt-[40px] w-full lg:w-10/12 lg:mx-auto text-start'>Mostly <span className='text-[#F7861D]'>product managers</span> use tools for</p>
            <div className='mt-[20px] md:mt-[40px] lg:w-10/12 grid md:grid-cols-2 md:gap-[60px]'>
                <div className='w-full xl:w-[458px] rounded-[20px] sm:rounded-[34px] p-[1px] bg-[#CECEEACC]'>
                    <div className='w-full h-full rounded-[20px] sm:rounded-[34px] bg-[#fff] p-[5px] sm:p-[10px]'>
                        <div className='w-full h-full rounded-[16px] sm:rounded-[30px] bg-[#F4F4F4] p-[20px] border-[1px] border-[#CECEEA] flex flex-col gap-[20px]'>
                            <div className='flex gap-1.5 lg:gap-1 items-center'>
                                <IoCheckboxOutline className='text-[14px] md:text-[20px] lg:text-[24px] text-[#FFDD09]' />
                                <p className='text-[#000000] text-[16px] md:text-[20px] lg:text-[24px] font-normal'>Prototyping</p>
                            </div>
                            <div className='flex gap-1.5 lg:gap-1 items-center'>
                            <IoCheckboxOutline className='text-[14px] md:text-[20px] lg:text-[24px] text-[#FFDD09]' />
                                <p className='text-[#000000] text-[16px] md:text-[20px] lg:text-[24px] font-normal'>Roadmapping</p>
                            </div>
                            <div className='flex gap-1.5 lg:gap-1 items-center'>
                                <IoCheckboxOutline className='text-[14px] md:text-[20px] lg:text-[24px] text-[#FFDD09]' />
                                <p className='text-[#000000] text-[16px] md:text-[20px] lg:text-[24px] font-normal'>Task management</p>
                            </div>
                            <div className='flex gap-1.5 lg:gap-1 items-center'>
                                <IoCheckboxOutline className='text-[14px] md:text-[20px] lg:text-[24px] text-[#FFDD09]' />
                                <p className='text-[#000000] text-[16px] md:text-[20px] lg:text-[24px] font-normal'>Data management</p>
                            </div>
                            <div className='flex gap-1.5 lg:gap-1 items-center'>
                                <IoCheckboxOutline className='text-[14px] md:text-[20px] lg:text-[24px] text-[#FFDD09]' />
                                <p className='text-[#000000] text-[16px] md:text-[20px] lg:text-[24px] font-normal'>Communication</p>
                            </div>
                            <div className='flex gap-1.5 lg:gap-1 items-center'>
                                <IoCheckboxOutline className='text-[14px] md:text-[20px] lg:text-[24px] text-[#FFDD09]' />
                                <p className='text-[#000000] text-[16px] md:text-[20px] lg:text-[24px] font-normal'>User tracking and analysis</p>
                            </div>
                            <div className='flex gap-1.5 lg:gap-1 items-center'>
                                <IoCheckboxOutline className='text-[14px] md:text-[20px] lg:text-[24px] text-[#FFDD09]' />
                                <p className='text-[#000000] text-[16px] md:text-[20px] lg:text-[24px] font-normal'>Customer survey</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img src='https://dz1x1c630cl14.cloudfront.net/webassets/ilustrater.webp' alt='ilustrater' loading="lazy" className='w-[80%] xl:w-[70%] mt-[20px] md:mt-[130px] lg:mt-[150px] xl:mt-0 mx-auto md:mx-[0px]'/>
            </div>
        </div>
    )
}