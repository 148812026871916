import React from 'react'
import InvitePeople from './people-invite';
import InviteTeamMember from './invite-team-member';
import GuestCustomerInvitations from './guest-customer';
import SetExpirationDate from './set-expirations';
import PendingInviteManagement from './pending-invite';
import AcceptedInviteDetails from './accept-invite';


export default function Mobile() {
  return (
    <div className='flex flex-col gap-[16px] relative w-full px-[20px] mt-[12px]'>
      <div className='text-[#000000] text-[15px] font-normal'>
        <p>Welcome to People Module in which you can invite team members to collaborate in your workspace using email or a shared link, and assign them roles such as member, admin, guest, or customer. Additionally, you can track the status of invitations by checking for pending or accepted invites.</p>
      </div>
      <div id='invite-people'><InvitePeople /></div>
      <div id='invite-team-members-via-email'><InviteTeamMember /></div>
      <div id='pending-invite-management'><PendingInviteManagement /></div>
      <div id='accepted-invite-details'><AcceptedInviteDetails /></div>
      <div id='guest-customer-invitations'><GuestCustomerInvitations /></div>
      <div id='set-expiration-date-for-guest-customer-invites'><SetExpirationDate /></div>
    </div>
  )
}
