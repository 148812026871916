import React from 'react'
import Inbox from './inbox';
import AssignedToMe from './assigned-to-me';
import CollaboratorTasks from './collaborator-tasks';
import PersonalTasks from './personal-tasks';
import AllTasks from './all-tasks';
import CreateNewListIcon from './create-new-list-icon';
import Task from './tasks';
import TaskSorting from './task-sorting';
import TaskFiltering from './task-filtering';
import PriorityView from './priority-view';
import PeopleView from './people-view';
import DateView from './date-view';
import TaskListManagement from './task-list-management';
import TaskManagement from './task-management';
import Priority from './priority';
import Status from './status';
import QuickTaskFeature from './quick-task-feature';

export default function Content() {

    return (
        <div className='flex flex-col gap-[16px] relative w-full px-[20px] mt-[12px]'>
            <div className='text-[#000000] text-[15px] font-normal'>
                <p>In Workfast.ai, tasks are key elements used to manage work and assign responsibilities within a team or project. You can create, assign, and track tasks, set deadlines, and prioritize them. Tasks can be broken down into subtasks, making complex projects more manageable. You can also set task statuses, add comments, and upload files to ensure smooth communication. Managing tasks effectively helps ensure team alignment, accountability, and progress tracking.</p>
            </div>
            <div id='inbox'><Inbox /></div>
            <div id='assigned-to-me'><AssignedToMe /></div>
            <div id='collaborator-tasks'><CollaboratorTasks /></div>
            <div id='personal-tasks'><PersonalTasks /></div>
            <div id='all-tasks'><AllTasks /></div>
            <div id='create-new-list-icon'><CreateNewListIcon /></div>
            <div id='task'><Task /></div>
            <div id='task-management'><TaskManagement /></div>
            <div id='priority'><Priority /></div>
            <div id='status'><Status /></div>
            <div id='quick-task-feature'><QuickTaskFeature /></div>
            <div id='task-sorting'><TaskSorting /></div>
            <div id='task-filtering'><TaskFiltering /></div>
            <div id='priority-view'><PriorityView /></div>
            <div id='people-view'><PeopleView /></div>
            <div id='date-view'><DateView /></div>
            <div id='task-list-management'><TaskListManagement /></div>
        </div>
    )
}
