import React from 'react'

export default function UniqueFeatures() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[18px] xl:gap-[22px]'>
            <h2 className='text-[#070707] text-[21px] md:text-[26px] lg:text-[30px] font-semibold relative z-[1]'>Unique features that will excites the users while using Workfast.ai :</h2>
            <div className='flex flex-col gap-[10px] lg:gap-[18px] xl:gap-[18px]'>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#070707] text-[17px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>1. Mobile first :</h2>
                    <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Many tools for communication and collaboration like slack and click up has mobile options, but still the users gets their benefits from web view. Workfast.ai give wonderful user experience both in mobile and web view which will be difficult to differentiate. This gives more choice to users to handle, communicate and collaborate.</p>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#070707] text-[17px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>2. Chat to task :</h2>
                    <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>The users when messages between them can add the conversational text to a task without leaving the chat box. This will save time and reduces miscommunication.</p>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#070707] text-[17px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>3. Integration :</h2>
                    <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>When world is averse to integrating tools to their software, Workfast.ai allows users to integrate common tools and softwares just with one click. You can integrate any software tools, emails, WhatsApp, sigma, jira, slack, asana, click up, Monday.com easily.</p>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#070707] text-[17px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>4. Project :</h2>
                    <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Projects have multiple pre defined templates like Gantt, Kanban, sales, agile etc and can be viewed on different aspects like stage view, priority view, date view, people view and Group view.</p>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#070707] text-[17px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>5. Calendar :</h2>
                    <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Arrange a meeting with your team within few seconds using calendar option inside the platform itself. Generate and Share link with attendees list. When the minutes of meeting is added to the meeting room space, managers can easily assign tasks to team members there itself. This will save lots of time and increase productivity.</p>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#070707] text-[17px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>6. Ai :</h2>
                    <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Don’t scroll to find messages, datas, documents, conversations etc. its a difficult and boring task. Rather ask Workfast.ai to retrieve information from across features within the platform and even outside the platform if integrated.</p>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#070707] text-[17px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>7. Goals :</h2>
                    <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Every company will set goals every year. How to measure and keep track on it? It’s easy. Goals are divided into projects and intern divided into tasks and subtasks. All analytics are measured and updated to complete goals. Goal oriented company is usually a successful company.</p>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#070707] text-[17px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>8. Feedback :</h2>
                    <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>If you want to share your feedback of our platform, say bugs, design flaw, IT related or anything can be raised within platform. We will assimilate and give you on time rectification of your feedback.</p>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#070707] text-[17px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>9. Onboarding:</h2>
                    <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>some platforms take 3 months, some may take at least a day to get onboarded. Workfast.ai takes only 5 mins to get onboarded and start to experience the platform.</p>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#070707] text-[17px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>10. Organisation chart:</h2>
                    <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>This chart will tell who reports to who. Who are responsible for certain key areas etc. once it is clear, the AI will collect datas and give suggestion to hierarchy during project or task assignment. For example if Sebastian is to be assigned certain work by his manager who is already over burdened with work, the ai will suggest his manager that Sebastian is tight in his schedule, hence it suggest some other related man power for the task.</p>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#070707] text-[17px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>11. Daily Auto report :</h2>
                    <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Once the member clicks the checkin option the platform will recognise the user has resumed his/her work of the day. Similarly if he/she check-out it will auto generate the daily report of the employee. Once done the user can share to his/her reporting manager and close the work.</p>
                </div>
                <div className='flex flex-col gap-[15px]'>
                    <h2 className='text-[#070707] text-[17px] sm:text-[22px] lg:text-[24px] font-semibold relative z-[1]'>12. Create your own project flow :</h2>
                    <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>The project owner can create any workflow which suits the project. They have the choice to make it complex or make it simple. This helps the project owners get a clear vision and its process.</p>
                    <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Hope the above stated core and unique features would have excited people like James.</p>
                </div>
            </div>
        </div>
    )
}
