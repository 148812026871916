import React from 'react'

export default function DateView() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Date View</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Project/project15.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Stay on top of deadlines with the “Date View” feature. This view organizes tasks by due dates, helping you prioritize what needs attention today, this week, or later. Keep track of upcoming deadlines and plan your schedule effectively.</p>
        </div>
    </div>
  )
}
