import React from 'react'
import { BuildSpanSvg, NumberBgSvg } from '../svg'


export default function WhereAreYouNow() {
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    const points = [
        { number: "01", colour: "#FFDAD8", },
        { number: "02", colour: "#FFEED4", },
        { number: "03", colour: "#FFF6D4", },
        { number: "04", colour: "#E0FFE8", },
        { number: "05", colour: "#CFFFFE", },
        { number: "06", colour: "#DBEDFF", },
    ]
    return (
        <div className='flex flex-col justify-center items-center mt-[120px] px-[16px] sm:px-0 relative z-[1]'>
            <div className='relative'>
                <h3 className='text-[#180833] font-bold text-[24px] sm:text-[32px] lg:text-[42px] text-center relative z-[1]'>Where are we now?</h3>
                <div className={`absolute top-[3px] sm:top-1/2 sm:-translate-y-1/2 -left-[30px] sm:-left-[30px] z-0`}>
                    <BuildSpanSvg colour="#007AFF" width={position >= 1024 ? "262" : "182"} height={position >= 1024 ? "54" : position >= 645 ? "40" : "33"} />
                </div>
            </div>
            <div className='flex flex-col gap-[14px] sm:gap-[22px] w-full sm:w-10/12 lg:w-8/12 xl:w-7/12 mx-auto mt-[17px] sm:mt-[40px]'>
                <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start'>
                    <div className='relative size-[31px] sm:size-[51px] lg:size-[71px] flex justify-start items-center shrink-0'>
                        <div className='absolute left-0 z-[0]'>
                            <NumberBgSvg colour={points[0]?.colour} size={position >= 1024 ? "68" : position >= 645 ? "50" : "35"}/>
                        </div>
                        <div className='text-[#000000] text-[21px] sm:text-[29px] lg:text-[39px] font-medium z-[1] relative'>{points[0].number}</div>
                    </div>
                    <div>
                        <p className='text-[#181F38] font-semibold text-[22px] sm:text-[34px]'>Funding Raised</p>
                        <p className='text-[#2B3857] font-medium text-[20px] sm:text-[26px] mt-[4px] lg:mt-[8px]'>$5.5 Million</p>
                    </div>
                </div>
                <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start'>
                    <div className='relative size-[31px] sm:size-[51px] lg:size-[71px] flex justify-start items-center shrink-0'>
                        <div className='absolute left-0 z-[0]'>
                            <NumberBgSvg colour={points[1]?.colour} size={position >= 1024 ? "68" : position >= 645 ? "50" : "35"}/>
                        </div>
                        <div className='text-[#000000] text-[21px] sm:text-[29px] lg:text-[39px] font-medium z-[1] relative'>{points[1].number}</div>
                    </div>
                    <div>
                        <p className='text-[#181F38] font-semibold text-[22px] sm:text-[34px]'>Product-Market Fit</p>
                        <p className='text-[#2B3857] font-medium text-[20px] sm:text-[26px] mt-[4px] lg:mt-[8px]'>Achieved</p>
                    </div>
                </div>
                <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start'>
                    <div className='relative size-[31px] sm:size-[51px] lg:size-[71px] flex justify-start items-center shrink-0'>
                        <div className='absolute left-0 z-[0]'>
                            <NumberBgSvg colour={points[2]?.colour} size={position >= 1024 ? "68" : position >= 645 ? "50" : "35"}/>
                        </div>
                        <div className='text-[#000000] text-[21px] sm:text-[29px] lg:text-[39px] font-medium z-[1] relative'>{points[2].number}</div>
                    </div>
                    <div>
                        <p className='text-[#181F38] font-semibold text-[22px] sm:text-[34px]'>Current Customers</p>
                        <p className='text-[#2B3857] font-medium text-[20px] sm:text-[26px] mt-[4px] lg:mt-[8px]'>Paid customers in India; engaging with enterprises in the U.S., Malaysia, and the Philippines.</p>
                    </div>
                </div>
                <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start'>
                    <div className='relative size-[31px] sm:size-[51px] lg:size-[71px] flex justify-start items-center shrink-0'>
                        <div className='absolute left-0 z-[0]'>
                            <NumberBgSvg colour={points[3]?.colour} size={position >= 1024 ? "68" : position >= 645 ? "50" : "35"}/>
                        </div>
                        <div className='text-[#000000] text-[21px] sm:text-[29px] lg:text-[39px] font-medium z-[1] relative'>{points[3].number}</div>
                    </div>
                    <div>
                        <p className='text-[#181F38] font-semibold text-[22px] sm:text-[34px]'>Runway</p>
                        <p className='text-[#2B3857] font-medium text-[20px] sm:text-[26px] mt-[4px] lg:mt-[8px]'>12 months</p>
                    </div>
                </div>
                <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start'>
                    <div className='relative size-[31px] sm:size-[51px] lg:size-[71px] flex justify-start items-center shrink-0'>
                        <div className='absolute left-0 z-[0]'>
                            <NumberBgSvg colour={points[4]?.colour} size={position >= 1024 ? "68" : position >= 645 ? "50" : "35"}/>
                        </div>
                        <div className='text-[#000000] text-[21px] sm:text-[29px] lg:text-[39px] font-medium z-[1] relative'>{points[4].number}</div>
                    </div>
                    <div>
                        <p className='text-[#181F38] font-semibold text-[22px] sm:text-[34px]'>Market Traction</p>
                        <p className='text-[#2B3857] font-medium text-[20px] sm:text-[26px] mt-[4px] lg:mt-[8px]'>Non-tech companies find the platform efficient and cost-effective; tech companies are exploring AI Agent features, which is in Alpha.</p>
                    </div>
                </div>
                <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-start'>
                    <div className='relative size-[31px] sm:size-[51px] lg:size-[71px] flex justify-start items-center shrink-0'>
                        <div className='absolute left-0 z-[0]'>
                            <NumberBgSvg colour={points[5]?.colour} size={position >= 1024 ? "68" : position >= 645 ? "50" : "35"}/>
                        </div>
                        <div className='text-[#000000] text-[21px] sm:text-[29px] lg:text-[39px] font-medium z-[1] relative'>{points[5].number}</div>
                    </div>
                    <div>
                        <p className='text-[#181F38] font-semibold text-[22px] sm:text-[34px]'>Roadmap Features</p>
                        <p className='text-[#2B3857] font-medium text-[20px] sm:text-[26px] mt-[4px] lg:mt-[8px]'>AI Agents, CRM, Help Desk, CXO Dashboard, Scrum Board, Leaderboard, HR Module, and more.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
