import React from 'react'

export default function GoalProject() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[18px] xl:gap-[22px]'>
            <h2 className='text-[#000000] text-[21px] md:text-[26px] lg:text-[30px] font-semibold relative z-[1] w-[280px] md:w-[345px] lg:w-[400px]'>Goal - Project - Task linkage:</h2>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Leaders work with goals . Goals are everything for an organisation. Multiple tasks are created inside a project and assigned to respective team members. At each tasks completion the project is updated and analytics are changed graphically. once the project is completed it automatically updated to goals.</p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Breaking Barriers in Communication and Collaboration with Workfast.ai 6 since many projects comprised within a goal , it will help the leaders to see how far the organisation have moved towards the goal. This even aides in resource allocation and management , finance mobilisation for the goal, revenue forecast for next financial year etc.  </p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>The goals can be simple as well . It can even be personal goal or team goal . If we move every single step with the goal , the organisation will achieve the desired output . </p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Success is subjective . some can measure it by how many clients one got , for some its how much money they made , for some how many project signed. Any how , the thin factor which guides all measurement is how productive our work and output was. Workfast.ai conditioned to give a productive experience for its users to achieve real fruit of productivity . </p>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>Workfast.ai ensures Communication and collaboration to be smooth , seamless , hassle free and precise . It is designed to give maximum output with minimal effort for the teams who really want a wonderful experience in project management. </p>
        </div>
    )
}
