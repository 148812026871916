import React from 'react'

export default function StageView() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Stage View</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Project/project13.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Visualize your project’s progress with the “Stage View” feature. This view allows you to see tasks organized by their stages, such as “To Do,” “In Progress,” and “Completed.” Track the flow of work and identify where tasks are in your project pipeline. Switch to Stage View to get a clear, organized perspective on your project’s progress!</p>
        </div>
    </div>
  )
}
