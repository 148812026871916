import React from 'react'
import Notifications from './notifications'
import ViewBy from './view-by'


export default function Mobile() {
    return (
        <div className='flex flex-col gap-[16px] relative w-full px-[20px] mt-[12px]'>
            <div className='text-[#000000] text-[15px] font-normal'>
                <p>The Alert feature in Workfast helps you stay on top of important updates and deadlines by notifying you of changes or upcoming events. Whether it's a task deadline, a project update, or team activity, you can set up alerts for key actions or milestones.</p>
            </div>
            <div id='notifications'><Notifications /></div>
            <div id='view-by'><ViewBy /></div>
        </div>
    )
}
