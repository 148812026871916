import React from 'react'
import { FaPlus } from "react-icons/fa6";

export default function InviteTeamMembers() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Invite Team Members</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Onboard/onboard6.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Inviting team members to your workspace in Workfast allows everyone to collaborate seamlessly, share updates, and work together on projects. This will help you invite and manage team members efficiently.</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[3px]'>1</div>
                    <div className='text-[#000000] text-[14px] font-normal flex gap-[4px] items-center flex-wrap'><p>Enter Your Team Member’s Email ID and Click</p> <div className='bg-[#FFDD09] size-[14px] flex justify-center items-center'><FaPlus className='text-[8px] text-[#473D00]'/></div> <p>Icon</p></div>
                </div>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[3px]'>2</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Click 'Get sharable link instead' to easily invite team members via a link</p>
                </div>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[3px]'>3</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Click Launch for the next step.</p>
                </div>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[3px]'>4</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Click 'Skip for now' to skip this step and invite your team later.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
