import React from 'react'
import { TbCircleCheckFilled, TbCircleMinus } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { BasecampIconSvg, WorkfastSvg } from '../../../svg';



export default function Comparison() {
    const navigate = useNavigate();
    const list = [
        { title: 'Custom Task Title, status, Tags', basecamp: 'NO', workfast: 'YES' },
        { title: 'Customisable workflow', basecamp: 'YES', workfast: 'YES' },
        { title: 'Tasks and Sub Tasks', basecamp: 'NO', workfast: 'YES' },
        { title: 'Goals', basecamp: 'NO', workfast: 'YES' },
        { title: 'Comprehensive Dashboard covering Projects , Tasks , Documents', basecamp: 'NO', workfast: 'YES' },
        { title: 'In App Email', basecamp: 'NO', workfast: 'YES' },
        { title: 'Calendar and Meetings', basecamp: 'YES', workfast: 'YES' },
        { title: 'Public and Private Channel', basecamp: 'NO', workfast: 'YES' },
        { title: 'Direct  and Group messages', basecamp: 'NO', workfast: 'YES' },
        { title: 'Sticky notes', basecamp: 'NO', workfast: 'YES' },
        { title: 'Native Docs', basecamp: 'NO', workfast: 'YES' },
        { title: 'Invites and collaboration in Chats, Channel, Projects, Tasks and Docs.', basecamp: 'NO', workfast: 'YES' },
        { title: 'Add Tasks from comment', basecamp: 'NO', workfast: 'YES' },
        { title: 'Add to Tasks from chat and channel', basecamp: 'NO', workfast: 'YES' },
        { title: 'Priority and Status setting', basecamp: 'NO', workfast: 'YES' },
        { title: '7+ view', basecamp: 'NO', workfast: 'YES' },
        { title: 'Embedded file sharing in Docs', basecamp: 'NO', workfast: 'YES' },
        { title: 'Kanban Chart', basecamp: 'NO', workfast: 'YES' },
        { title: 'Gannt Chart', basecamp: 'NO', workfast: 'YES' },
        { title: '20+ Templates', basecamp: 'NO', workfast: 'YES' },
        { title: 'AI intelligence', basecamp: 'NO', workfast: 'YES' },
        { title: 'Advanced Automation', basecamp: 'NO', workfast: 'YES' },
        { title: '100+ integrations', basecamp: 'NO', workfast: 'YES' },
        { title: 'Check in option for employees and users', basecamp: 'NO', workfast: 'YES' },
        { title: 'Rich experience in mobile app and desktop', basecamp: 'NO', workfast: 'YES' },
        { title: 'Reports downloaded as CSV and Excel in one click', basecamp: 'NO', workfast: 'YES' },
        { title: 'Export Data from app anytime', basecamp: 'NO', workfast: 'YES' },
        { title: 'Enterprise grade privacy setting', basecamp: 'NO', workfast: 'YES' },
        { title: 'Graphical Analytics and Reports', basecamp: 'NO', workfast: 'YES' },
        { title: 'Task Reports as Files', basecamp: 'NO', workfast: 'YES' },
        { title: 'Employee performance monitoring', basecamp: 'NO', workfast: 'YES' },
        { title: 'People and Profile', basecamp: 'NO', workfast: 'YES' },
        { title: 'Organisation chart', basecamp: 'NO', workfast: 'YES' },
        { title: 'Recurring tasks and remainders', basecamp: 'NO', workfast: 'YES' },
        { title: 'Attachments inside Tasks and  Comment', basecamp: 'NO', workfast: 'YES' },
        { title: 'Detailed activity metrics', basecamp: 'NO', workfast: 'YES' },
        { title: 'Box View', basecamp: 'NO', workfast: 'YES' },
        { title: 'Embedded spreadsheets', basecamp: 'NO', workfast: 'YES' },
        { title: 'Real time notifications and alerts', basecamp: 'NO', workfast: 'YES' },
    ]
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);
    return (
        <div className='flex flex-col justify-center items-center gap-[32px] w-11/12 sm:w-9/12 mx-auto'>
            <div className='flex flex-col justify-center items-center'>
                <h3 className='text-[#0A0800] text-[24px] sm:text-[30px] lg:text-[40px] xl:text-[52px] font-semibold text-center  sm:w-auto'>Do more with workfast than</h3>
                <div className='text-[#0A0800] text-[24px] sm:text-[30px] lg:text-[40px] xl:text-[52px] font-semibold text-center flex flex-wrap gap-[8px] sm:gap-[12px] items-center justify-center w-11/12 sm:w-auto'>
                    <div className='w-[25px] sm:w-[35px] lg:w-[50px] xl:w-[55px]'>
                        <BasecampIconSvg size={position >= 1440 ? "55" : position >= 1024 ? "50" : position >= 645 ? "35" : "25"} />
                    </div>
                    <h3>Basecamp</h3>
                </div>
            </div>
            <div className='w-full'>
                <div className='grid grid-cols-4 border-[1px] border-b-[#CECEEA] rounded-t-[6px] sm:rounded-t-[12px] w-full'>
                    <div className='col-span-2 px-[10px] py-[14px] sm:px-[18px] sm:py-[25px]'>
                        <div className='text-[#514B81] text-[12px] sm:text-[16px] font-bold'>Compare features</div>
                    </div>
                    <div className='border-l-[1px] border-[#CECEEA] p-[2px] sm:p-[5px]'>
                        <div className='bg-[#F002500D] w-full h-full flex flex-col sm:flex-row justify-center items-center sm:gap-[6px]'>
                            <div className='w-[15px] sm:w-auto'>
                                <BasecampIconSvg size={position >= 645 ? "25" : "15"} />
                            </div>
                            <div className='text-[#24223E] text-[10px] sm:text-[14px] lg:text-[16px] font-bold'>Basecamp</div>
                        </div>
                    </div>
                    <div className='border-l-[1px] border-[#CECEEA] p-[2px] sm:p-[5px]'>
                        <div className='bg-[#4CB9390D] w-full h-full flex flex-col sm:flex-row  justify-center items-center sm:gap-[6px]'>
                            <div className='w-[15px] sm:w-auto'>
                                <WorkfastSvg size={position >= 645 ? "25" : "15"} />
                            </div>
                            <div className='text-[#24223E] text-[10px] ssm:text-[14px] lg:text-[16px] font-bold'>Workfast.ai</div>
                        </div>
                    </div>
                </div>
                {list?.map((each, index) => (
                    <div key={index} className={`grid grid-cols-4 border-b-[1px] border-x-[1px] border-[#CECEEA4D] w-full ${list.length - 1 === index ? "rounded-b-[12px]" : ""}`}>
                        <div className='col-span-2 p-[10px] sm:p-[20px]'>
                            <div className='text-[#24223E] font-semibold text-[10px] sm:text-[15px]'>{each.title}</div>
                        </div>
                        <div className='border-l-[1px] border-[#CECEEA4D] p-[2px] sm:p-[5px]'>
                            <div className={`flex justify-center items-center w-full h-full ${each.basecamp === "YES" ? "bg-[#4CB9390D]" : "bg-[#F002500D]"}`}>{each.basecamp === "YES" ? <TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#4CB939]' /> : <TbCircleMinus className='size-[14px] sm:size-[28px] text-[#F00250]' />}</div>
                        </div>
                        <div className='border-l-[1px] border-[#CECEEA4D] p-[2px] sm:p-[5px]'>
                            <div className={`flex justify-center items-center w-full h-full ${each.workfast === "YES" ? "bg-[#4CB9390D]" : "bg-[#F002500D]"}`}>{each.workfast === "YES" ? <TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#4CB939]' /> : <TbCircleMinus className='size-[14px] sm:size-[28px] text-[#F00250]' />}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='mt-[40px] sm:mt-[100px] flex flex-col justify-center items-center gap-[20px] sm:gap-[40px]'>
                <h3 className='text-[#24223E] text-[22px] sm:text-[30px] lg:text-[42px] font-semibold sm:w-10/12 text-center'>Empower your Focus, Achieve more and thrive best</h3>
                <button className='w-[170px] h-[40px] lg:w-[270px] lg:h-[64px] rounded-[11px] bg-gradient-to-br from-[#FDD01F] via-[#FDD320] to-[#F8861B] text-[#2A2200] text-[16px] lg:text-[21px] font-semibold cursor-pointer' onClick={() => { navigate('/book-for-demo') }}>Try it Free</button>
            </div>
        </div>
    )
}
