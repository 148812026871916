import React from 'react'
import { Link } from 'react-scroll';
import RecentSearches from './recent-searches';
import Search from './search';
import ChannelSearch from './channels-search';
import TaskSearch from './tasks-search';
import PeopleSearch from './people-search';
import FileSearch from './file-search';

export default function Content() {
    const [activeSection, setActiveSection] = React.useState(null);

    // Update active section on scroll
    const handleSetActive = (to) => {
        setActiveSection(to);
    };

    const tocData = [
        { title: 'Recent Searches', id: 'recent-searches' },
        { title: 'Search', id: 'search' },
        { title: 'Channels Search', id: 'channels-search' },
        { title: 'Tasks Search', id: 'tasks-search' },
        { title: 'People Search', id: 'people-search' },
        { title: 'File Search', id: 'file-search' },
    ];

    return (
        <div className='flex w-9/12 mx-auto gap-[63px] relative z-[21]'>
            <aside className='sticky top-[10px] right-0 self-start w-[35%] z-[21] rounded-[8px] block'>
                <ul className={`mt-[30px]`} data-hs-scrollspy="#scrollspy-2" data-hs-scrollspy-scrollable-parent="#scrollspy-scrollable-parent-2">
                    {tocData.map((section) => (
                        <li key={section.id}>
                            <Link
                                activeClass="" // Active link style
                                to={section.id}
                                spy={true}
                                smooth={true}
                                duration={100}
                                className={`flex gap-[10px] lg:gap-[17px] items-start py-[17px] cursor-pointer rounded-[6px] border-[#E6E9EF] border-b`}
                                onSetActive={handleSetActive}
                            >
                                <h3 className={`${activeSection === section.id ? "text-[#F6831D] text-[14px] lg:text-[18px] font-semibold" : "text-[#676879] text-[12px] lg:text-[16px] font-medium"}`}>{section.title}</h3>
                            </Link>
                        </li>
                    ))}
                </ul>
            </aside>
            <div className='flex flex-col gap-[22px] lg:gap-[32px] w-[65%] xl:w-[57%] relative top-[40px]'>
                <div className='text-[#000000] text-[15px] lg:text-[18px] font-normal'>
                    <p>The "Universal Search" feature in Workfast is your shortcut to finding any information quickly. Use the search bar at the top to locate tasks, projects, documents, or team members instantly. Filter results by type, date, or category to narrow down your search. Find relevant updates, messages, and files across all your projects in seconds. Search within tasks or comments to track specific details.</p>
                </div>
                <div id='recent-searches'><RecentSearches /></div>
                <div id='search'><Search/></div>
                <div id='channels-search'><ChannelSearch/></div>
                <div id='tasks-search'><TaskSearch/></div>
                <div id='people-search'><PeopleSearch/></div>
                <div id='file-search'><FileSearch/></div>
            </div>
        </div>
    )
}
