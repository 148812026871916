import React from 'react'

export default function QuickTaskFeature() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
        <h3 className='text-[#000000] text-[18px] font-bold'>Quick Task Feature</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Project/project10.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
        <div className='mt-[6px]'>
            <p className='text-[#000000] text-[14px] font-medium'>Add tasks swiftly and efficiently with the Quick Task feature. Simply click on the “Single Task” or “Multi Task” button, enter the task details, and assign it to team members in just a few clicks.</p>
            <div className='mt-[11px] flex flex-col gap-[7px]'>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>1</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Choose Default Assignee and Due Date</p>
                </div>
                <div className='flex gap-[4px] items-start'>
                    <div className='bg-[#018BF6] size-[14px] rounded-[3px] flex justify-center items-center text-[#FFFFFF] text-[8px] font-semibold shrink-0 mt-[2.5px]'>2</div>
                    <p className='text-[#000000] text-[14px] font-normal'>Choose whether to treat the input as single or multiple tasks</p>
                </div>
            </div>
        </div>
    </div>
  )
}
