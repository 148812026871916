import React from 'react'
import { FaPlus } from "react-icons/fa6";

export default function InviteTeamMembers() {
  return (
    <div className='flex flex-col gap-[16px] lg:gap-[22px] justify-center'>
        <h3 className='text-[#000000] text-[26px] lg:text-[32px] font-bold'>Invite Team Members</h3>
        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Onboard/onboard6.mov" autoPlay playsInline loop muted className=' xl:w-[706px] xl:h-[400px] rounded-[12px]'></video>
        <div className='mt-[10px]'>
            <p className='text-[#000000] text-[14px] lg:text-[16px] font-medium'>Inviting team members to your workspace in Workfast allows everyone to collaborate seamlessly, share updates, and work together on projects. This will help you invite and manage team members efficiently.</p>
            <div className='mt-[22px] flex flex-col gap-[15px]'>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>1</div>
                    <div className='text-[#000000] text-[14px] lg:text-[16px] font-normal flex gap-[8px] items-center flex-wrap'><p>Enter Your Team Member’s Email ID and Click</p> <div className='bg-[#FFDD09] size-[18px] flex justify-center items-center'><FaPlus className='text-[10px] text-[#473D00]'/></div> <p>Icon</p></div>
                </div>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>2</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Click 'Get sharable link instead' to easily invite team members via a link</p>
                </div>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>3</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Click Launch for the next step</p>
                </div>
                <div className='flex gap-[8px] items-center'>
                    <div className='bg-[#018BF6] size-[26px] rounded-[6px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-semibold'>4</div>
                    <p className='text-[#000000] text-[14px] lg:text-[16px] font-normal'>Click 'Skip for now' to skip this step and invite your team later.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
